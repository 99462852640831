import React from "react";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import FarmItem from "../../components/farms/FarmItem";
import {withRouter} from "react-router-dom";
import {getDevices} from "../../actions/devicesActions";
import settingsDB from "../../database/settingsDB";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {getAllBuildings} from "../../actions/farmsActions";
import {Roles} from "../../constans/roles";
import {
    checkIfUserHasMinManagement,
    checkIfUserHasUsersManagement,
    checkIfUserIsFarmAdmin,
    checkIfUserIsOwner,
    checkIfUserIsService
} from "../../utils/NewRolesUtils";
import {changeClient} from "../../actions/serviceActions";
import lokiDB from "../../database/lokiDB";
import {getNotificationsLoki} from "../../actions/notificationsActions";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";
import Button from "../../components/basics/button/Button";
import {getEmployees} from "../../actions/userActions";
import NewIOT from "../../IOT/NewIOT";
import {hasActiveSubscription} from "../../utils/BraintreeUtils";
import {logout} from "../../utils/UserUtils";
import withRoles from "../../components/withRoles";
import InfoBox from "../../components/basics/info-box/InfoBox";
import animalsDB from "../../database/animalsDB";
import VersionFooter from "../../components/basics/version-footer/VersionFooter";
import {withTranslation} from "react-i18next";
import SearchInput from "../../components/basics/search-input/SearchInput";
import {isMobile} from "../../utils/MobileUtils";
import {filterFarms, groupFarms} from "../../utils/FarmViewUtils";
import {debounce} from "lodash";
import {copyToClipboard} from "../../utils/TextUtils";
import ClientAliasModal, {ModalName as ClientAliasModalName} from "../../components/modals-new/client-alias-modal/ClientAliasModal";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";


@connect(state => ({
    farms: state.farms.farms,
    user: state.user.user,
    userAttributes: state.user.attributes,
    snsEndpoint: state.sns.endpointArn,
    endpoint: state.settings.endpointArn,
    FarmData: state.user.user.FarmData
}))
export class FarmChooserView extends React.Component {


    setFarms = debounce(async (farms, user, search) => {
       await this.setState({
            farms: filterFarms(groupFarms(farms, user), search)
        })
    }, 200);

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            farms: [],
            isService: checkIfUserIsService()
        }
    }

    componentDidMount() {
        // czyscimy mqtt bo po co na wyborze fermy?
        const {search} = this.state;
        const {user, farms, FarmData} = this.props;
        NewIOT.clearSubscriptions();
        NewIOT.clearQueue();
        this.setState({
            farms: filterFarms(groupFarms(farms, user), search)
        });
        this.props.dispatch({
            type: "HIDE_SIDE_NAVIGATION"
        });
        this.props.dispatch({
            type: "CHANGE_FARM"
        });
        this.props.dispatch({
             type: "GET_FARMS",
             payload: FarmData
        });
    }

    componentWillUnmount() {
        this.props.dispatch({
            type: "SHOW_NAVIGATION"
        })
    }

    logout = () => {
        logout();
    };

    onFarmClick = async (farm) => {
        if (hasActiveSubscription(farm)) {
            NewIOT.clearSubscriptions();
            NewIOT.clearQueue();
            if (this.state.isService) {
                this.props.dispatch(changeClient(farm.ClientID, farm.FarmID));
                if (this.props.user.ClientID !== farm.ClientID) {
                    await lokiDB.loadDatabase(`${this.props.userAttributes.sub}_${farm.ClientID}`);
                    this.props.dispatch({
                        type: "CLEAR_DICTIONARY"
                    })
                } else {
                    this.props.dispatch(getEmployees(farm.ClientID));
                }
            }
            animalsDB.getAllAnimalsForLocation.cache.clear();
            this.props.dispatch({
                type: "CHANGE_FARM",
                payload: farm.FarmID
            });
            this.props.dispatch({
                type: "SHOW_NAVIGATION"
            });
            this.props.dispatch(getDevices(farm.FarmID));
            if (this.props.user.ClientID === farm.ClientID || !this.state.isService) {
                NewIOT.subscribeAllTopics();
            }
            this.props.dispatch(getNotificationsLoki(farm.FarmID));
            this.props.dispatch({
                type: "LIST_ALL_SETTINGS",
                payload: settingsDB.getAllSettings(farm.FarmID),
                meta: {
                    user: this.props.user
                }
            });
            this.props.dispatch(getAllBuildings(farm.FarmID));
            this.props.history.push("/");
        } else {
            if (checkIfUserIsOwner()) {
                this.redirect(`/farmSettings/billing/farm/${farm.FarmID}/renew`);
            }
        }
    };

    redirect = (path = "/", forceFetch = false) => {
        const {history} = this.props;
        history.push({
            pathname: path,
            state: {
                forceFetch
            }
        })
    };

    redirectToFarmSettings = (farms) => {
        const {user, dispatch} = this.props;
        const isService = checkIfUserIsService();
        let forceFetch = false;
        if (farms.length>0 && Array.isArray(farms)) {
            const {FarmID: farmID, ClientID: clientID} = farms[0];
            if (isService && user.ClientID !== clientID) {
                forceFetch = true;
                dispatch(changeClient(clientID, farmID));
            }
        }
        const path = checkIfUserIsOwner() || isService || checkIfUserHasUsersManagement() ? "/farmSettings/users" : "/";
        return this.redirect(path, forceFetch);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {search} = this.state;
        const {farms, user} = this.props;
        if (prevState.search !== search || farms!==this.state.farms) {
            this.setFarms(farms, user, search);
        }
    }

    onEditAlias = (clientId, alias) => {
        this.props.show(ClientAliasModalName, {ClientID: clientId, Alias: alias})
    }

    render() {
        const {t} = this.props;
        const {farms} = this.state;
        const isOwner = checkIfUserIsOwner() || checkIfUserIsFarmAdmin();
        const isService = checkIfUserIsService();
        const hasMinRoles = checkIfUserHasMinManagement() || isService;
        const mobile = isMobile();
        return (
            <div>
                <Header text={t("farmChooser.header")}/>
                <ButtonGroup fixed className="farm-chooser-buttons">
                    <Button buttonColor={"secondary"} buttonStyle={"round"}
                            icon={<i className="fas fa-power-off"/>} onClick={this.logout}/>
                    {
                        isOwner && !mobile &&
                        <Button buttonColor={"success"} buttonStyle={"round"}
                                icon={<i className="fas fa-cogs"/>}
                                disabled={!hasMinRoles}
                                onClick={this.redirectToFarmSettings.bind(this, farms)}/>
                    }
                </ButtonGroup>
                <ViewContainer addPaddingForButtons>
                    {
                        farms.length === 0 &&
                        <InfoBox boxColor={"warning"}>{t("farmChooser.noFarms")}</InfoBox>
                    }
                    {
                        farms.length > 0 && !isService &&
                        <>
                            <Row className="farm-container pt-3">

                                {
                                    !isService && farms.map((farm, index) =>
                                        <FarmItem isService={false} farm={farm}
                                                  onClick={this.onFarmClick.bind(this, farm)}
                                                  key={index}
                                        />
                                    )
                                }
                            </Row>
                        </>
                    }
                    {
                        isService &&
                        <>
                            <SearchInput className={"mb-2"} value={this.state.search}
                                         onChange={value => this.setState({search: value})}/>
                            {
                                [...farms.entries()].map(([key, {name, farms: value}], index) =>
                                    <Row key={key} className="farm-container" index={index}>
                                        <Col md={12} className="farm-row mb-3 pt-1 pb-1">
                                            <Row>
                                                <Col xs={12}
                                                     className={"d-flex align-items-center justify-content-center"}>
                                                    {
                                                        !mobile && isService &&
                                                        <Button buttonStyle={"text"}
                                                                buttonColor={"success"}
                                                                className={"round mr-2"}
                                                                icon={<i className="fas fa-cogs"/>}
                                                                onClick={this.redirectToFarmSettings.bind(this, value)}
                                                        />
                                                    }
                                                    {name}
                                                    {
                                                        !!isService &&
                                                        <Button buttonStyle={"text"}
                                                                buttonColor={"success"}
                                                                className={"round ml-2"}
                                                                icon={<i className="fas fa-pen"/>}
                                                                onClick={() => this.onEditAlias(key, name)}
                                                        />
                                                    }
                                                </Col>
                                                <Col xs={12}
                                                     className={"d-flex align-items-center justify-content-center"}>
                                                    <small className={"pointer"}
                                                           onClick={() => copyToClipboard(key)}>({key})</small>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {
                                            value.map((farm, index) =>
                                                <FarmItem farm={farm}
                                                          isService={true}
                                                          search={this.state.search}
                                                          onClick={this.onFarmClick.bind(this, {
                                                              ...farm,
                                                              ClientID: key
                                                          })}
                                                          key={farm.FarmID}/>
                                            )
                                        }
                                    </Row>
                                )
                            }
                        </>
                    }
                    <ClientAliasModal/>
                </ViewContainer>
                <VersionFooter/>
            </div>
        )
    }

}

export const _FarmChooserView = FarmChooserView;
FarmChooserView = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(FarmChooserView);
FarmChooserView = withRoles({roles: [Roles._GENERAL_READ], showComponent: true})(FarmChooserView);
FarmChooserView = withRouter(FarmChooserView);
export default withTranslation()(FarmChooserView);
