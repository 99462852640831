import React from "react";
import FormForEvents from "../FormForEvents";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    animalField,
    calculateGridRowsByHeight, checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getAnimals,
    getInitialValues,
    insertData,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import {compose} from "redux";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import {Formik} from "formik";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createSelection, createSelectionNotificationSuccess} from "../../../../api/events/Selection";
import {createSellNotificationFailure} from "../../../../api/events/Sell";
import {eventChooserFormNames} from "../../../../constans/formNames";

const formName = eventChooserFormNames.SELECTION;

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        selectionReasons: state.dictionary.selectionReason.WData[state.language.lang.lang]
    }
}

export class Selection extends React.Component {

    state = {
        animals: [],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let animals = getAnimals(farm);
        this.setState({
            animals: getAnimalOptions(animals),
            initialData: getInitialValues(formName)
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("reason")) {
            const {reason} = filters;
            value = insertData(reason, "reason", value, oldFilters.reason);
        }
        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            animals: getAnimalOptions(getAnimals(farm, animalsAlreadySelected))
        })
    }

    reasonFormatter = ({column: {key}, row}) => {
        const {selectionReasons} = this.props;
        let id = row[key];
        let reason = selectionReasons.find(item => item.ID === id);
        if (reason) return reason.Value;
        return null;
    }

    reasonFilter = ({value, onChange}) => {
        const {selectionReasons, t} = this.props;
        return <Select value={value}
                       options={selectionReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={t("reason")}/>
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (!row.reason) {
                    rowErrors.reason = t("required");
                }

                return rowErrors;
            })
        }
        if (errors.data === undefined) delete errors.data;
        return errors;
    }

    submit = (values, bag) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, reason} = row;
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment,
                    Reason: reason,
                    PlcmntID: Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID
                })
            }
        }
        return createSelection(data).then(res => {
            createSelectionNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createSellNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {animals, initialData} = this.state;
        const {selectionReasons, t} = this.props;
        const columns = [
            animalField(animals),
            {
                name: t("reason"),
                key: "reason",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={selectionReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.reasonFormatter,
                filterRenderer: this.reasonFilter
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset}
                                       isSubmitting={isSubmitting} getApi={this.getApi}/>
                    )
                }
            </Formik>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Selection);