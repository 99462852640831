import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import animalsDB from "../../../../database/animalsDB";
import {connect} from "react-redux";
import {AnimalTypes} from "../../../../constans/animalTypes";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import {
    animalFormatter,
    calculateGridRowsByHeight,
    commentField,
    dateField,
    getAnimalOptions,
    getInitialValues,
    getSowsAndRenovationSows,
    insertComment,
    insertData,
    insertOperator,
    operatorField,
    shouldRemoveFilter,
    validateGrid
} from "../../../../utils/DataGridUtils";
import FormForEvents from "../FormForEvents";
import {Formik} from "formik";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {
    createInsemination,
    createInseminationNotificationFailure,
    createInseminationNotificationSuccess
} from "../../../../api/events/Insemination";
import {eventChooserFormNames} from "../../../../constans/formNames";
import i18n from "../../../../i18n";
import buildingsDB from "../../../../database/buildingsDB";
import {SectorType} from "../../../../constans/sectorTypes";
import {isEqual} from "lodash"
import {Level} from "../../../../constans/levelTypes";

const formName = eventChooserFormNames.INSEMINATION;

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

export class Insemination extends React.Component {

    state = {
        sows: [],
        boars: [],
        initialData: [],
        chambers: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getSowsAndRenovationSows(farm);
        let boars = animalsDB.getAllAnimals(farm, AnimalTypes.BOAR, false, false);
        let chambers = buildingsDB.getAllChambersForSTypeWithLocation(SectorType.MATING, this.props.farm);
        this.setState({
            sows: getAnimalOptions(sows),
            boars: getAnimalOptions(boars),
            initialData: getInitialValues(formName),
            chambers: chambers.map(chamber => ({
                name: animalsDB.getAnimalLocationsByPlcmntID(chamber.CID, {nameDeep: 3})[0].name,
                value: chamber
            }))
        });
    }

    boarSelect = ({value, onChange}) => {
        const {boars} = this.state;
        const {t} = this.props;
        return <Select value={value} options={boars} onChange={onChange} placeholder={t("boar")}/>
    }

    onFiltersChange = (filters, value, oldFilters) => {
        if (filters.hasOwnProperty("animal")) {
            const {animal} = filters;
            if (!isEqual(animal, oldFilters.animal)) {
                if (animal) {
                    let animals = animalsDB.getAllAnimalsForLocation(animal, Level.CHAMBER, {joinEvents: false})
                        .filter(item => item.AnimalKind === AnimalTypes.SOW || item.AnimalKind === AnimalTypes.RENOVATION_SOW)
                        .slice(0);
                    animals.sort((a, b) => a.AnmNo1 && a.AnmNo1.localeCompare(b.AnmNo1));
                    value = value.map(() => {
                        let animal = animals.shift();
                        return {
                            animal: animal || null
                        };
                    });
                    for (let animal of animals) {
                        value.push({
                            animal
                        });
                    }
                } else {
                    value = value.map(row => {
                        return {
                            ...row,
                            animal: null
                        };
                    });
                }
            }
        }
        if (filters.hasOwnProperty("boar")) {
            const {boar} = filters;
            value = insertData(boar, "boar", value, oldFilters.boar);
        }
        if (filters.hasOwnProperty("date")) {
            const {date} = filters;
            value = insertData(date, "date", value, oldFilters.date);
        }
        value = insertOperator(filters, value, oldFilters.operator);
        value = insertComment(filters, value, oldFilters.comment);
        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal) {
            let animals = animalsDB.getAllAnimalsForLocation(filters.animal, Level.CHAMBER, {joinEvents: false})
                .filter(item => item.AnimalKind === AnimalTypes.SOW || item.AnimalKind === AnimalTypes.RENOVATION_SOW)
                .slice(0);
            let isGoodFilter = shouldRemoveFilter(animals, newValue);
            if (!isGoodFilter) this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            sows: getAnimalOptions(getSowsAndRenovationSows(farm, animalsAlreadySelected))
        })
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;
        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};
                if (!row.boar) {
                    rowErrors.boar = t("required");
                }
                return rowErrors;
            })
        }
        if (errors.data === undefined) delete errors.data;
        return errors;
    }

    submit = (values) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, boar} = row;
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment,
                    boarID: boar.AnmID
                })
            }
        }
        return createInsemination(data).then(res => {
            createInseminationNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            console.error(e);
            createInseminationNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    matingsFormatter = ({value, onChange}) => {
        const {chambers} = this.state;
        const {t} = this.props;
        return <Select value={value} options={chambers} onChange={onChange} placeholder={t("listOfMatings")}/>
    }

    setFormRef = element => {
        this.form = element;
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        console.log(this);
        const {sows, boars, initialData} = this.state;
        const {t} = this.props;
        const columns = [
            {
                name: i18n.t("animalNumber"),
                key: "animal",
                editor: React.forwardRef((props, ref) => <SelectEditor ref={ref} {...props} options={sows}/>),
                formatter: animalFormatter,
                filterRenderer: this.matingsFormatter,
                disableAutoInsert: true,
                disableDragAndDrop: true,
                clearRowOnRemove: true,
            },
            {
                name: t("boar"),
                key: "boar",
                editor: React.forwardRef((props, ref) => <SelectEditor ref={ref} {...props} options={boars}/>),
                formatter: animalFormatter,
                filterRenderer: this.boarSelect
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} isSubmitting={isSubmitting} getApi={this.getApi}/>
                    )
                }
            </Formik>
        );
    }

}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(Insemination);
