import React from "react";
import FormForEvents from "../FormForEvents";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
    animalField,
    calculateGridRowsByHeight, checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateFormatter,
    dateRenderer,
    getAnimalOptions,
    getAnimals,
    getInitialValues,
    insertData,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import {medicineTypes} from "../../../../constans/medicine";
import {Formik} from "formik";
import moment from "moment";
import {isNil} from "lodash";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {convertWeightToBaseUnit, getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {
    createGrafting,
    createGraftingNotificationFailure,
    createGraftingNotificationSuccess
} from "../../../../api/events/Grafting";
import {eventChooserFormNames} from "../../../../constans/formNames";

const formName = eventChooserFormNames.GRAFTING;

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine,
        graftingReasons: state.dictionary.graftingReason.WData[state.language.lang.lang]
    }
}

export class Grafting extends React.Component {

    state = {
        animals: [],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getAnimals(farm);
        this.setState({
            animals: getAnimalOptions(sows),
            initialData: getInitialValues(formName)
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight);
        }
        if (filters.hasOwnProperty("reason")) {
            const {reason} = filters;
            value = insertData(reason, "reason", value, oldFilters.reason);
        }
        if (filters.hasOwnProperty("medicine")) {
            const {medicine} = filters;
            value = insertData(medicine, "medicine", value, oldFilters.medicine);
        }
        if (filters.hasOwnProperty("dateTo")) {
            const {dateTo} = filters;
            value = insertData(dateTo, "dateTo", value, oldFilters.dateTo, this.isEditableDateTo);
        }
        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            animals: getAnimalOptions(getAnimals(farm, animalsAlreadySelected))
        })
    }

    weightFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("weight")}/>
    }

    reasonFormatter = ({column: {key}, row}) => {
        const {graftingReasons} = this.props;
        let id = row[key];
        let reason = graftingReasons.find(item => item.ID === id);
        if (reason) return reason.Value;
        return null;
    }

    reasonFilter = ({value, onChange}) => {
        const {graftingReasons, t} = this.props;
        return <Select value={value}
                       options={graftingReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={t("reason")}/>
    }

    isEditableDateTo = ({medicine}) => {
        const {medicines} = this.props;
        let currentMedicine = medicines.find(item => item.WordID === medicine);
        if (currentMedicine) {
            return currentMedicine.WData.Type === medicineTypes.FORAGE || currentMedicine.WData.Type === medicineTypes.DOSATRON;
        }
        return false;
    }

    medicineFormatter = ({column: {key}, row}) => {
        const {medicines} = this.props;
        let id = row[key];
        let reason = medicines.find(item => item.WordID === id);
        if (reason) return reason.WData.Name;
        return null;
    }

    medicineFilter = ({value, onChange}) => {
        const {medicines, t} = this.props;
        return <Select value={value}
                       options={medicines.map(medicine => ({
                           name: medicine.WData.Name,
                           value: medicine.WordID
                       }))}
                       onChange={onChange} placeholder={t("medicine")}/>
    }

    onMedicineChange = updated => {
        const {medicines} = this.props;
        let medicine = medicines.find(item => item.WordID === updated.medicine);
        if (medicine && medicine.WData.Type !== medicineTypes.FORAGE && medicine.WData.Type !== medicineTypes.DOSATRON) {
            updated.dateTo = null;
        }
        return updated;
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t, medicines} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (isNil(row.weight)) {
                    rowErrors.weight = t("required");
                }

                if (!row.reason) {
                    rowErrors.reason = t("required");
                }

                if (!row.medicine) {
                    rowErrors.medicine = t("required");
                } else {
                    let medicine = medicines.find(item => item.WordID === row.medicine);
                    if (medicine && (medicine.WData.Type === medicineTypes.FORAGE || medicine.WData.Type === medicineTypes.DOSATRON)) {
                        if (!row.dateTo) {
                            rowErrors.dateTo = t("required");
                        }
                    }
                }

                return rowErrors;
            }, [], true)
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values) => {
        let data = [];
        const {medicines} = this.props;
        for (let row of values.data) {
            const {animal, date, comment, medicine, dateTo, reason, weight} = row;
            let medicineObject = medicines.find(item => item.WordID === medicine);
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment,
                    Medicine: medicine,
                    Reason: reason,
                    Weight: convertWeightToBaseUnit(+weight, {fromUnit: UnitTypes.MEDIUM}),
                    TreatmentType: medicineObject.WData.Type,
                    StartTime: +date,
                    EndTime: +dateTo || undefined,
                    AnmCnt: animal.AnmCnt
                })
            }
        }
        return createGrafting(data).then(res => {
            createGraftingNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createGraftingNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    weightFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    render() {
        const {animals, initialData} = this.state;
        const {graftingReasons, medicines, t} = this.props;
        const columns = [
            animalField(animals),
            {
                name: t("weight"),
                key: "weight",
                filterRenderer: this.weightFilter,
                formatter: this.weightFormatter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props} unit={getUnit("weight", UnitTypes.MEDIUM)}/>)
            },
            {
                name: t("reason"),
                key: "reason",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={graftingReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.reasonFormatter,
                filterRenderer: this.reasonFilter
            },
            {
                name: t("medicine"),
                key: "medicine",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={medicines.map(medicine => ({
                                      name: medicine.WData.Name,
                                      value: medicine.WordID
                                  }))}
                                  ref={ref}/>),
                formatter: this.medicineFormatter,
                filterRenderer: this.medicineFilter,
                onChange: this.onMedicineChange
            },
            {
                name: t("dateDateFrom"),
                key: "date",
                editor: React.forwardRef((props, ref) =>
                    <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer
            },
            {
                name: t("to"),
                key: "dateTo",
                editor: React.forwardRef((props, ref) =>
                    <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer,
                editable: this.isEditableDateTo
            },
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} initialFilters={{date: moment(), dateTo: moment()}}
                                       isSubmitting={isSubmitting} getApi={this.getApi}/>
                    )
                }
            </Formik>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Grafting);