import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormForEvents from "../FormForEvents";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
    animalField,
    calculateGridRowsByHeight,
    checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getInitialValues,
    getParturitionedSows,
    getSows,
    insertData,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import {medicineTypes} from "../../../../constans/medicine";
import {Formik} from "formik";
import {isNil} from "lodash";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {convertWeightToBaseUnit, getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {
    createPigletsTreatment,
    createPigletsTreatmentNotificationFailure,
    createPigletsTreatmentNotificationSuccess
} from "../../../../api/events/PigletsTreatment";
import {eventChooserFormNames} from "../../../../constans/formNames";

const formName = eventChooserFormNames.PIGLETS_TREATMENT;

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine,
        graftingReasons: state.dictionary.graftingReason.WData[state.language.lang.lang]
    }
}

class PigletsTreatment extends Component {

    state = {
        sows: [],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getParturitionedSows(farm);
        this.setState({
            sows: getAnimalOptions(sows),
            initialData: getInitialValues(formName)
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight);
        }
        if (filters.hasOwnProperty("reason")) {
            const {reason} = filters;
            value = insertData(reason, "reason", value, oldFilters.reason);
        }
        if (filters.hasOwnProperty("piglets")) {
            const {piglets} = filters;
            value = insertData(piglets, "piglets", value, oldFilters.piglets);
        }
        if (filters.hasOwnProperty("medicine")) {
            const {medicine} = filters;
            value = insertData(medicine, "medicine", value, oldFilters.medicine);
        }
        if (filters.hasOwnProperty("forageAmount")) {
            const {forageAmount} = filters;
            value = insertData(forageAmount, "forageAmount", value, oldFilters.forageAmount, this.isForageAmountEditable);
        }
        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            sows: getAnimalOptions(getSows(farm, animalsAlreadySelected))
        })
    }

    reasonFormatter = ({column: {key}, row}) => {
        const {graftingReasons} = this.props;
        let id = row[key];
        let reason = graftingReasons.find(item => item.ID === id);
        if (reason) return reason.Value;
        return null;
    }

    reasonFilter = ({value, onChange}) => {
        const {graftingReasons, t} = this.props;
        return <Select value={value}
                       options={graftingReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={t("reason")}/>
    }

    medicineFormatter = ({column: {key}, row}) => {
        const {medicines} = this.props;
        let id = row[key];
        let reason = medicines.find(item => item.WordID === id);
        if (reason) return reason.WData.Name;
        return null;
    }

    forageFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    medicineFilter = ({value, onChange}) => {
        const {medicines, t} = this.props;
        return <Select value={value}
                       options={medicines.map(medicine => ({
                           name: medicine.WData.Name,
                           value: medicine.WordID
                       }))}
                       onChange={onChange} placeholder={t("medicine")}/>
    }

    weightFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("averagePigletWeight")}/>
    }

    pigletsFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("pigletsAmount")}
                            allowFloat={false}/>
    }

    forageAmountFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("forageAmount")}/>
    }

    onMedicineChange = updated => {
        const {medicines} = this.props;
        let medicine = medicines.find(item => item.WordID === updated.medicine);
        if (medicine && medicine.WData.Type !== medicineTypes.FORAGE && medicine.WData.Type !== medicineTypes.DOSATRON) {
            updated.dateTo = null;
        }
        return updated;
    }

    isEditableDateTo = ({medicine}) => {
        const {medicines} = this.props;
        let currentMedicine = medicines.find(item => item.WordID === medicine);
        if (currentMedicine) {
            return currentMedicine.WData.Type === medicineTypes.FORAGE || currentMedicine.WData.Type === medicineTypes.DOSATRON;
        }
        return false;
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t, medicines} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (isNil(row.weight)) {
                    rowErrors.weight = t("required");
                }

                if (isNil(row.piglets)) {
                    rowErrors.piglets = t("required");
                }

                if (!row.reason) {
                    rowErrors.reason = t("required");
                }

                if (!row.medicine) {
                    rowErrors.medicine = t("required");
                } else {
                    let medicine = medicines.find(item => item.WordID === row.medicine);
                    if (medicine && medicine.WData.Type === medicineTypes.FORAGE) {
                        if (isNil(row.forageAmount)) {
                            rowErrors.forageAmount = t("required");
                        }
                    }
                }

                return rowErrors;
            })
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values) => {
        let data = [];
        const {medicines} = this.props;
        for (let row of values.data) {
            const {animal, date, comment, medicine, weight, piglets, forageAmount} = row;
            if (animal) {
                let med = medicines.find(item => item.WordID === medicine);
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment,
                    Medicine: medicine,
                    Weight: convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM}),
                    TreatmentType: med.WData.Type,
                    AnmCnt: 1,
                    PiCnt: piglets,
                    ForageAmount: med.WData.Type === medicineTypes.FORAGE ? forageAmount : undefined
                })
            }
        }
        return createPigletsTreatment(data).then(res => {
            createPigletsTreatmentNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createPigletsTreatmentNotificationFailure(e);
        })
    }

    isForageAmountEditable = ({medicine}) => {
        if (medicine) {
            const {medicines} = this.props;
            let med = medicines.find(item => item.WordID === medicine);
            return med && med.WData.Type === medicineTypes.FORAGE;
        }
        return true;
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {sows, initialData} = this.state;
        const {graftingReasons, medicines, t} = this.props;
        const columns = [
            animalField(sows),
            {
                name: t("averagePigletWeight"),
                key: "weight",
                filterRenderer: this.weightFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                formatter: this.forageFormatter
            },
            {
                name: t("pigletsAmount"),
                key: "piglets",
                filterRenderer: this.pigletsFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>)
            },
            {
                name: t("reason"),
                key: "reason",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={graftingReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.reasonFormatter,
                filterRenderer: this.reasonFilter
            },
            {
                name: t("medicine"),
                key: "medicine",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={medicines.map(medicine => ({
                                      name: medicine.WData.Name,
                                      value: medicine.WordID
                                  }))}
                                  ref={ref}/>),
                formatter: this.medicineFormatter,
                filterRenderer: this.medicineFilter,
                onChange: this.onMedicineChange
            },
            {
                name: t("forageAmount"),
                key: "forageAmount",
                filterRenderer: this.forageAmountFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                editable: this.isForageAmountEditable,
                formatter: this.forageFormatter
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} getApi={this.getApi}
                                       isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        )
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(PigletsTreatment);
