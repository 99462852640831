import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import InsertionStepper from "./InsertionStepper";
import {AnimalTypes} from "../../../constans/animalTypes";
import RenovationSowAndBoarGrid from "./grids/RenovationSowAndBoarGrid";
import SowGrid from "./grids/SowGrid";
import PorkerAndPigletRFIDGrid from "./grids/PorkerAndPigletRFIDGrid";
import PorkerAndPigletGroupGrid from "./grids/PorkerAndPigletGroupGrid";

export class Insertion extends React.Component {

    render() {
        const {match: {params: {animalKind}}} = this.props;
        return (
            <div>
                <InsertionStepper activeStep={4}/>
                {
                    (+animalKind === AnimalTypes.RENOVATION_SOW || +animalKind === AnimalTypes.BOAR) &&
                    <RenovationSowAndBoarGrid/>
                }
                {
                    +animalKind === AnimalTypes.SOW &&
                    <SowGrid/>
                }
                {
                    (+animalKind === -AnimalTypes.PORKER || +animalKind === -AnimalTypes.PIGLET) &&
                    <PorkerAndPigletRFIDGrid/>
                }
                {
                    (+animalKind === AnimalTypes.PORKER  || +animalKind === AnimalTypes.PIGLET) &&
                    <PorkerAndPigletGroupGrid/>
                }
            </div>
        );
    }

}

export default compose(
    withTranslation()
)(Insertion);