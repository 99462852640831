import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {get} from "lodash";
import Button from "../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import CircularProgressBar from "../../../components/basics/circular-progressbar/CircularProgressBar";
import "./_billing-expand-info.scss";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router-dom";
import {show} from "redux-modal";
import {getFarmPackageModules} from "../../../selectors/billingSelector";
import * as breedingModule from "validators-schema/Api/constants/breedingModules";
import {
    cancelSubscription,
    cancelSubscriptionNotificatonSuccess,
    cancelSubscriptionNotificationFailure
} from "../../../api/subscriptions/CancelSubscription";
import {ModalName as ConfirmModalName} from "../../../components/modals-new/confirm-modal/ConfirmModal";
import {gatewayCustomerFind} from "../../../actions/braintreeActions";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";
import Tooltip from "../../../components/basics/tooltip/Tooltip";

function mapStateToProps(state, props) {
    return {
        attributes: state.user.attributes,
        modules: getFarmPackageModules(state, {selectedFarm: props.farm})
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

const SubscriptionStatuses = {
    ACTIVE: "Active",
    CANCELED: "Canceled",
};

class BillingExpandInfo extends React.Component {

    onCancelSubscriptionClick = () => {
        const {farm, sub, attributes, t, dispatch, show} = this.props;
        show(ConfirmModalName, {
            title: t("farmSettings.billingFarmView.cancel"),
            text: t("farmSettings.billingFarmView.cancelText", {name: farm.FarmName}),
            onConfirmed: (props) => {
                cancelSubscription(sub.id).then((res) => {
                    cancelSubscriptionNotificatonSuccess(res);
                    dispatch(gatewayCustomerFind("", "", "", attributes.sub, true));
                    props.handleHide();
                }).catch((err) => {
                    cancelSubscriptionNotificationFailure(err);
                })
            }
        });
    };

    onRenewClick = () => {
        const {farm, history} = this.props;
        history.replace(`/farmSettings/billing/farm/${farm.FarmID}/renew`);
    };

    onEditSubscriptionFarmClick = () => {
        const {history, farm: {FarmID}} = this.props;
        history.replace(`/farmSettings/billing/farm/${FarmID}/edit`);
    };

    getButtonsBasedOnSubscriptionStatus = (props) => {
        const subStatus = get(props, "sub.status", SubscriptionStatuses.CANCELED);
        const {t} = props;
        const isService = checkIfUserIsService();
        const subStatusButton = subStatus === SubscriptionStatuses.ACTIVE ?
            <Button onClick={this.onCancelSubscriptionClick} buttonStyle={"round"} disabled={isService}
                    icon={<i className={"fas fa-trash"}/>} buttonColor={"error"}/> :
            <Button onClick={this.onRenewClick} buttonStyle={"round"} icon={<i className={"fas fa-redo"}/>}
                    buttonColor={"info"} disabled={isService}/>;
        return (
            <div className="billing-subscription-buttons">
                {
                    <Tooltip tooltipContent={this.getContent() || t(`billingExpandInfo.${subStatus === SubscriptionStatuses.ACTIVE ? "cancelSubscription" : "editSubscription"}`)} placement={"auto"}>
                        <div>
                            {subStatusButton}
                        </div>
                    </Tooltip>
                }
                <Tooltip tooltipContent={this.getContent() || t("billingExpandInfo.editSubscription")} placement={"auto"}>
                    <div>
                        <Button buttonStyle={"round"} icon={<i className={"fas fa-pencil-alt"}/>}
                                onClick={this.onEditSubscriptionFarmClick} disabled={isService}/>
                    </div>
                </Tooltip>
            </div>
        )
    };

    getPlanType = (path) => {
        const {modules, t} = this.props;
        const result = get(modules, `addOns.${path}.extended`) ? t("subscriptions.plans.extended") : t("subscriptions.plans.basic");
        return result.toUpperCase();
    };

    getAnimalsAmount = (type) => {
        const {modules} = this.props;
        const breedingSize = get(modules, `addOns.BreedingModule[${type}].size`, 0);
        switch (breedingSize) {
            case breedingModule.RangeTypes.large:
                return "∞";
            case breedingModule.RangeTypes.medium:
                return breedingModule.AnimalRanges.maxMedium;
            case breedingModule.RangeTypes.small:
                return breedingModule.AnimalRanges.maxSmall;
            case breedingModule.RangeTypes.micro:
            default:
                return breedingModule.AnimalRanges.maxMicro;
        }
    };

    getContent = () => {
        const isService = checkIfUserIsService();
        const {t} = this.props;
        if (isService) {
            return (
                <span>
                    <i className={'fas fa-ban mr-1'}/>
                    {t('noServiceAccess')}
                </span>
            )
        }
    };

    render() {
        const {modules, t} = this.props;
        if (modules) {
            const siloses = get(modules, "addOns.DevicesModule.siloses", {});
            const climates = get(modules, "addOns.DevicesModule.climates", {});
            const cages = get(modules, "addOns.DevicesModule.cages", {});
            const dispensers = get(modules, "addOns.DevicesModule.dispensers", {});
            const dispensers5G = get(modules, "addOns.DevicesModule.dispensers5G", {});
            const meters = get(modules, "addOns.DevicesModule.meters", {});
            const smallCages = get(modules, "addOns.DevicesModule.smallCages", {});
            const alarms = get(modules, "addOns.DevicesModule.alarms", {});
            const chains = get(modules, "addOns.DevicesModule.chains", {});
            const administrationModule = get(modules, "addOns.AdministrationModule");

            const sowsLeft = get(modules, "addOns.BreedingModule.sows.left");
            const sowsAmount = this.getAnimalsAmount("sows");

            const breedingData = [
                {
                    percentage: sowsAmount === "∞" ? 0 : +parseFloat((100 * (sowsAmount - sowsLeft)) / sowsAmount).toFixed(2),
                    text: `-/${sowsAmount}`,
                    title: t("animalTypes.0"),
                    planType: this.getPlanType("BreedingModule.sows"),
                    isDisabled: !get(modules, "addOns.BreedingModule.sows.size")
                },
                {
                    percentage: 0,
                    text: `-/∞`,
                    title: t("animalTypes.3"),
                    planType: this.getPlanType("BreedingModule.porkers"),
                    isDisabled: !get(modules, "addOns.BreedingModule.porkers.size")
                }
            ];
            const devicesData = [
                {
                    percentage: +parseFloat((100 * (siloses.amount - siloses.left)) / siloses.amount).toFixed(2),
                    text: `${siloses.amount - siloses.left}/${siloses.amount}`,
                    title: t("siloses").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.siloses"),
                    isDisabled: !siloses.amount
                },
                {
                    percentage: +parseFloat((100 * (climates.amount - climates.left)) / climates.amount).toFixed(2),
                    text: `${climates.amount - climates.left}/${climates.amount}`,
                    title: t("climates").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.climates"),
                    isDisabled: !climates.amount
                },
                {
                    percentage: +parseFloat((100 * (cages.amount - cages.left)) / cages.amount).toFixed(2),
                    text: `${cages.amount - cages.left}/${cages.amount}`,
                    title: t("cages").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.cages"),
                    isDisabled: !cages.amount
                },
                {
                    percentage: +parseFloat((100 * (dispensers.amount - dispensers.left)) / dispensers.amount).toFixed(2),
                    text: `${dispensers.amount - dispensers.left}/${dispensers.amount}`,
                    title: t("dispensers").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.dispensers"),
                    isDisabled: !dispensers.amount
                },
                {
                    percentage: +parseFloat((100 * (dispensers5G.amount - dispensers5G.left)) / dispensers5G.amount).toFixed(2),
                    text: `${dispensers5G.amount - dispensers5G.left}/${dispensers5G.amount}`,
                    title: t("dispensers5G").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.dispensers5G"),
                    isDisabled: !dispensers5G.amount
                },
                {
                    percentage: +parseFloat((100 * (meters.amount - meters.left)) / meters.amount).toFixed(2),
                    text: `${meters.amount - meters.left}/${meters.amount}`,
                    title: t("meters").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.meters"),
                    isDisabled: !meters.amount
                },
                {
                    percentage: +parseFloat((100 * (smallCages.amount - smallCages.left)) / smallCages.amount).toFixed(2),
                    text: `${smallCages.amount - smallCages.left}/${smallCages.amount}`,
                    title: t("weights").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.smallCages"),
                    isDisabled: !smallCages.amount
                },
                {
                    percentage: +parseFloat((100 * (alarms.amount - alarms.left)) / alarms.amount).toFixed(2),
                    text: `${alarms.amount - alarms.left}/${alarms.amount}`,
                    title: t("alarms").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.alarms"),
                    isDisabled: !alarms.amount
                },
                {
                    percentage: +parseFloat((100 * (chains.amount - chains.left)) / chains.amount).toFixed(2),
                    text: `${chains.amount - chains.left}/${chains.amount}`,
                    title: t("chains").toLowerCase(),
                    planType: this.getPlanType("DevicesModule.chains"),
                    isDisabled: !chains.amount
                },
            ];
            const administrationData = [
                {
                    percentage: +parseFloat((100 * (administrationModule.managersAmount - administrationModule.managersLeft)) / administrationModule.managersAmount).toFixed(2),
                    text: `${administrationModule.managersAmount - administrationModule.managersLeft}/${administrationModule.managersAmount}`,
                    title: t("managersAcc").toLowerCase(),
                    isDisabled: !administrationModule.managersAmount
                },
                {
                    percentage: +parseFloat((100 * (administrationModule.operatorsAmount - administrationModule.operatorsLeft)) / administrationModule.operatorsAmount).toFixed(2),
                    text: `${administrationModule.operatorsAmount - administrationModule.operatorsLeft}/${administrationModule.operatorsAmount}`,
                    title: t("operatorsAcc").toLowerCase(),
                    isDisabled: !administrationModule.operatorsAmount
                },
                {
                    percentage: +parseFloat((100 * (administrationModule.techAdviceAmount - administrationModule.techAdviceLeft)) / administrationModule.techAdviceAmount).toFixed(2),
                    text: `${administrationModule.techAdviceAmount - administrationModule.techAdviceLeft}/${administrationModule.techAdviceAmount}`,
                    title: t("techAdvice").toLowerCase(),
                    isDisabled: !administrationModule.techAdviceAmount
                },
                {
                    percentage: +parseFloat((100 * (administrationModule.techSupportAmount - administrationModule.techSupportLeft)) / administrationModule.techSupportAmount).toFixed(2),
                    text: `${administrationModule.techSupportAmount - administrationModule.techSupportLeft}/${administrationModule.techSupportAmount}`,
                    title: t("techSupport").toLowerCase(),
                    isDisabled: !administrationModule.techSupportAmount
                },
            ];

            return (
                <div className="billing-expand-info-container">
                    <Row className="w-100 justify-content-between ml-0 mr-0 mt-3">
                        <div className="plan-info">
                            <b>{t("breeding")}</b>
                        </div>
                        {
                            this.getButtonsBasedOnSubscriptionStatus(this.props)
                        }
                    </Row>
                    <Row className="w-100 justify-content-center ml-0 mr-0 mb-3">
                        {
                            breedingData.map((item, index) => (
                                <Col className="d-flex justify-content-center">
                                    <div key={index}
                                         className={`circular-item-container ${item.isDisabled ? "disabled" : ""}`}>
                                        <CircularProgressBar
                                            percentage={item.percentage}
                                            monoColor
                                            color={"success"}
                                            text={item.text}
                                        />
                                        <p>{item.title}</p>
                                        <p>{item.planType}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row className="w-100 justify-content-between ml-0 mr-0 mb-3">
                        <div className="plan-info">
                            <b>{t("devices")}</b>
                        </div>
                    </Row>
                    <Row className="w-100 justify-content-center ml-0 mr-0 mb-3">
                        {
                            devicesData.map((item, index) => (
                                <Col className="d-flex justify-content-center">
                                    <div key={index}
                                         className={`circular-item-container ${item.isDisabled ? "disabled" : ""}`}>
                                        <CircularProgressBar
                                            percentage={item.percentage}
                                            monoColor color={"success"}
                                            text={item.text}/>
                                        <p>{item.title}</p>
                                        <p>{item.planType}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row className="w-100 justify-content-between ml-0 mr-0 mb-3">
                        <div className="plan-info">
                            <b>{t("administration")}</b>
                        </div>
                    </Row>
                    <Row className="w-100 justify-content-center ml-0 mr-0 mb-3">
                        {
                            administrationData.map((item, index) => (
                                <Col className="d-flex justify-content-center">
                                    <div key={index}
                                         className={`circular-item-container ${item.isDisabled ? "disabled" : ""}`}>
                                        <CircularProgressBar
                                            percentage={item.percentage}
                                            monoColor color={"success"}
                                            text={item.text}/>
                                        <p>{item.title}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter
)(BillingExpandInfo);
