import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";

class CycleAmountHeader extends Component {
    render() {
        const {column: {name}, t} = this.props;
        return (
            <div>
                {name}
                <Tooltip tooltipContent={t("cycleAmountInfo")}>
                    <i className="fas fa-question-circle"/>
                </Tooltip>
            </div>
        );
    }
}

export default withTranslation()(CycleAmountHeader);