import React from "react";
import FormForEvents from "../FormForEvents";
import {connect} from "react-redux";
import "./_treatment.scss"
import {withTranslation} from "react-i18next";
import {
    animalField,
    calculateGridRowsByHeight, checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateFormatter,
    dateRenderer,
    getAnimalOptions,
    getAnimals,
    getInitialValues,
    insertData,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import {Formik} from "formik";
import {compose} from "redux";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import Select from "../../../../components/basics/select/Select";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import moment from "moment";
import {
    createTreatment,
    createTreatmentNotificationFailure,
    createTreatmentNotificationSuccess
} from "../../../../api/events/Treatment";
import {eventChooserFormNames} from "../../../../constans/formNames";

const formName = eventChooserFormNames.TREATMENT;

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine,
        graftingReasons: state.dictionary.graftingReason.WData[state.language.lang.lang]
    }
}

export class Treatment extends React.Component {

    state = {
        animals: [],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getAnimals(farm);
        this.setState({
            animals: getAnimalOptions(sows),
            initialData: getInitialValues(formName)
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("medicine")) {
            const {medicine} = filters;
            value = insertData(medicine, "medicine", value, oldFilters.medicine);
        }
        if (filters.hasOwnProperty("reason")) {
            const {reason} = filters;
            value = insertData(reason, "reason", value, oldFilters.reason);
        }
        if (filters.hasOwnProperty("dateTo")) {
            const {dateTo} = filters;
            value = insertData(dateTo, "dateTo", value, oldFilters.dateTo, this.isEditableDateTo);
        }
        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            animals: getAnimalOptions(getAnimals(farm, animalsAlreadySelected))
        })
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (!row.medicine) {
                    rowErrors.medicine = t("required");
                }

                if (!row.reason) {
                    rowErrors.reason = t("required");
                }

                if (!row.dateTo) {
                    rowErrors.dateTo = t("required");
                }

                return rowErrors;
            }, [], true)
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    medicineFormatter = ({column: {key}, row}) => {
        const {medicines} = this.props;
        let id = row[key];
        let reason = medicines.find(item => item.WordID === id);
        if (reason) return reason.WData.Name;
        return null;
    }

    reasonFormatter = ({column: {key}, row}) => {
        const {graftingReasons} = this.props;
        let id = row[key];
        let reason = graftingReasons.find(item => item.ID === id);
        if (reason) return reason.Value;
        return null;
    }

    medicineFilter = ({value, onChange}) => {
        const {medicines, t} = this.props;
        return <Select value={value}
                       options={medicines.map(medicine => ({
                           name: medicine.WData.Name,
                           value: medicine.WordID
                       }))}
                       onChange={onChange} placeholder={t("medicine")}/>
    }

    reasonFilter = ({value, onChange}) => {
        const {graftingReasons, t} = this.props;
        return <Select value={value}
                       options={graftingReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={t("reason")}/>
    }

    submit = (values) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, medicine, dateTo, reason} = row;
            if (animal) {
                let amountOfDays = dateTo.startOf("day").diff(date.clone().startOf("day"), "days") + 1;
                let days = [];
                for (let i = 0; i < amountOfDays; i++) {
                    days.push(+moment().startOf("day").add(i, "days"));
                }
                data.push({
                    treatment: [{
                        medicines: [medicine],
                        dates: days
                    }],
                    animals: [{
                        AnmID: animal.AnmID,
                        GrID: getGrIDByAnimal(animal, +date, false),
                        AnmCnt: animal.AnmCnt
                    }],
                    EvTime: +date,
                    Comment: comment,
                    Reason: reason
                })
            }
        }
        return createTreatment(data).then(res => {
            createTreatmentNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createTreatmentNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {animals, initialData} = this.state;
        const {medicines, graftingReasons, t} = this.props;
        const columns = [
            animalField(animals),
            {
                name: t("medicine"),
                key: "medicine",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={medicines.map(medicine => ({
                                      name: medicine.WData.Name,
                                      value: medicine.WordID
                                  }))}
                                  ref={ref}/>),
                formatter: this.medicineFormatter,
                filterRenderer: this.medicineFilter
            },
            {
                name: t("reason"),
                key: "reason",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={graftingReasons.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.reasonFormatter,
                filterRenderer: this.reasonFilter
            },
            {
                name: t("dateFrom"),
                key: "date",
                editor: React.forwardRef((props, ref) =>
                    <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer
            },
            {
                name: t("dateTo"),
                key: "dateTo",
                editor: React.forwardRef((props, ref) =>
                    <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer
            },
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} getApi={this.getApi}
                                       isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Treatment)