import React from "react";
import {connect} from "react-redux";
import Tooltip from "../../../components/basics/tooltip/Tooltip";
import {withTranslation} from "react-i18next";
import  i18next from "i18next";

export class BillingHistoryStatusComponent extends React.Component {

    getError() {
        const {value: {processorResponseCode}} = this.props;
        if (+processorResponseCode > 2100) {
            return i18next.t(`transactions.errors.${2101}`);
        }
        return i18next.t(`transactions.errors.${processorResponseCode}`);
    }

    render() {
        const {value: {status}, t} = this.props;
        return (
            <div>
                {status === "submitted_for_settlement" &&
                <Tooltip tooltipContent={t("transactions.errors.submittedForSettlement")} type={"info"}><i
                    className="fas fa-clock"/></Tooltip>}
                {status === "settled" && <i className="fas fa-check"/>}
                {status !== "settled" && status !== "submitted_for_settlement" &&
                <Tooltip tooltipContent={this.getError()} type={"error"}><i className="fas fa-times"/></Tooltip>}
            </div>
        );
    }

}

BillingHistoryStatusComponent = withTranslation()(BillingHistoryStatusComponent);

BillingHistoryStatusComponent = connect(state => ({
}))(BillingHistoryStatusComponent);

export default BillingHistoryStatusComponent;