import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types";
import {
    animalField,
    calculateGridRowsByHeight,
    checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getAnimals,
    getInitialValues,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import {Formik} from "formik";
import FormForEvents from "../FormForEvents";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import {isNil} from "lodash";
import formDataDB from "../../../../database/formDataDB";
import {withRouter} from "react-router-dom";
import {eventChooserFormNames} from "../../../../constans/formNames";
import {transferSubmit} from "./TransferSubmit";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class TransferChamber extends Component {
    state = {
        animals: [],
        initialData: {
            data: []
        }
    }
    formName = eventChooserFormNames.TRANSFER_CHAMBER + "_" + this.props.match.params.locationID;

    componentDidMount() {
        const {farm, initialData} = this.props;
        let animals = getAnimals(farm);
        let init = initialData;
        if (!init) {
            let savedData = formDataDB.getSavedData(this.formName);
            if (savedData) {
                init = savedData.data;
            } else {
                init = {
                    data: getInitialValues(this.formName)
                }
            }
        } else {
            let initialData = getInitialValues(this.formName);
            for (let i = init.data.length; i < initialData.length; i++) {
                init.data.push({});
            }
        }
        this.setState({
            animals: getAnimalOptions(animals),
            initialData: init
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);

        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            animals: getAnimalOptions(getAnimals(farm, animalsAlreadySelected))
        })
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};
                if (row.animal) {
                    if (row.animal.AnmCnt > 1 && isNil(row.amount)) {
                        rowErrors.amount = t("required");
                    }
                    if (row.amount && row.amount > row.animal.AnmCnt) {
                        rowErrors.amount = t("errors.maxAnimalsValue", {amount: row.animal.AnmCnt});
                    }
                    if (row.amount < row.animal.AnmCnt && !row.newGrpNumber) {
                        rowErrors.newGrpNumber = t("required");
                    }
                }
                return rowErrors;
            })
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values, bag) => {
        if (this.props.hasFeeding) {
            let data = values.data.map(row => {
                if (row.animal) {
                    return row;
                }
                return null;
            });
            data = data.filter(item => !!item);
            this.props.onSubmit({data});
        } else {
            return transferSubmit(values, {...this.props, showBoxes: false}, this.api);
        }
    }

    amountFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("animalCount")}
                            allowFloat={false}/>
    }

    newGrpNumberFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("newSubgroupNumber")}/>
    }

    isAmountEditable = ({animal}) => {
        if (animal) return animal.AnmCnt !== 1;
        return true;
    }

    isNewGrpNumberEditable = (data) => {
        const {animal, amount} = data;
        return this.isAmountEditable(data) && animal && animal.AnmCnt > amount;
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {animals, initialData} = this.state;
        const {t, hasFeeding} = this.props;
        const columns = [
            animalField(animals),
            {
                name: t("animalCount"),
                key: "amount",
                filterRenderer: this.amountFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>),
                editable: this.isAmountEditable
            },
            {
                name: t("newSubgroupNumber"),
                key: "newGrpNumber",
                filterRenderer: this.newGrpNumberFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}/>),
                editable: this.isNewGrpNumberEditable
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={initialData}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={this.formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} hasNext={hasFeeding} getApi={this.getApi}
                                       isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }
}

TransferChamber.propTypes = {
    placement: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialData: PropTypes.shape({
        data: PropTypes.array,
    }),
    hasFeeding: PropTypes.bool,
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter
)(TransferChamber);