import React from "react";
import Header from "../../../components/basics/header/Header";
import ViewContainer from "../../../components/basics/view-container/ViewContainer";
import Card from "../../../components/basics/card/Card";
import {connect} from "react-redux";
import {gatewayTransactionHistory} from "../../../actions/braintreeActions";
import "./_billing.scss";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import TransactionHistory from "../../../components/transaction-history/TransactionHistory";
import {isMobile} from "../../../utils/MobileUtils";
import {withTranslation} from "react-i18next";
import {get} from "lodash";

export class BillingHistoryView extends React.Component {

    constructor(props) {
        super(props);
        const {attributes, location: {state}} = this.props;
        const forceFetch = get(state, "forceFetch", false);
        this.state = {
            loading: true
        };

        this.props.dispatch(gatewayTransactionHistory(attributes.sub, forceFetch));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fetching} = this.props;
        if (prevProps.fetching !== fetching) {
            this.setState({
                loading: fetching
            });
        }
    }

    onBackClick = () => {
        const {history} = this.props;
        history.replace("/farmSettings/billing");
    };

    render() {
        const {t} = this.props;
        const {loading} = this.state;
        return (
            <div className="billing">
                <LoadingComponent isLoading={loading} style={isMobile() ? {position: "fixed"} : null}/>
                <Header text={<><span><i className="fas fa-arrow-left pointer"
                                         onClick={this.onBackClick}/></span>&nbsp;{t("farmSettings.billingHistoryView.title")}</>}/>
                <ViewContainer>
                    <Card>
                        <TransactionHistory/>
                    </Card>
                </ViewContainer>
            </div>
        );
    }

}

BillingHistoryView = connect(state => ({
    attributes: state.user.attributes,
    fetching: state.braintree.fetching,
}))(BillingHistoryView);

export default withTranslation()(BillingHistoryView);
