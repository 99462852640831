import React, {Component} from "react";
import {connect} from "react-redux"
import PropTypes from "prop-types";
import {get} from "lodash";
import "./_choose-user-item.scss"
import ListItem from "../../../components/basics/list/list-item/ListItem";
import {withTranslation} from "react-i18next";
import HighlightedText from "../../../components/basics/highlighted-text/HighlightedText";
import {Collapse} from "react-bootstrap";
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {getRolesForCurrentUser} from "../../../utils/NewRolesUtils";
import {getFarmsForChoosenUser} from "../../../selectors/farmSelector";

class ChooseUserItem extends Component {

    constructor(props) {
        super(props);
        const {farms, user: {roles}} = this.props;
        this.state = {
            show: false,
            privileges: farms.map(farm => ({
                farm,
                userRoles: getRolesForCurrentUser(roles, farm.FarmID),
            }))
        }
    }

    getValue({field, valueFormatter}) {
        const {user, searchText} = this.props;
        let value = get(user, field);
        if (valueFormatter) {
            return <HighlightedText text={value} searchText={searchText}/>;
        }
        return <HighlightedText text={value} searchText={searchText}/>;
    }

    renderItem = (header) => (
        <span>
            <div>
                {this.getValue(header)}
            </div>
            <div>
                <small>{header.name}</small>
            </div>
         </span>
    );

    onClick = () => {
        this.setState(state => ({
            show: !state.show
        }))
    };

    rolesFormatter = (value) => {
        return (
            <div className={"role-box"}>
                <div className="role-names">
                    {
                        value.slice(0, 5).map((r) => <p className={`${r.hasRole ? 'success' : 'error'}`}>{r.text}</p>)
                    }
                </div>
                <div className="role-names">
                    {
                        value.slice(5, 10).map((r) => <p className={`${r.hasRole ? 'success' : 'error'}`}>{r.text}</p>)
                    }
                </div>
                <div className="role-names">
                    {
                        value.slice(10, 15).map((r) => <p className={`${r.hasRole ? 'success' : 'error'}`}>{r.text}</p>)
                    }
                </div>
            </div>
        )
    };

    render() {
        const {user: {userName, userType}, headers, buttons, t, searchText} = this.props;
        const {show, privileges} = this.state;
        const tableHeaders = [
            {
                name: t("newSettings.users.changeRolesView.farm"),
                field: "farm.FarmName"
            },
            {
                name: t("newSettings.users.changeRolesView.roles"),
                field: "userRoles",
                component: ({value}) => this.rolesFormatter(value),
                colWidth: 4
            }
        ];
        return (
            <div className={"user-item"}>
                <ListItem onClick={this.onClick}>
                    <span>
                        <div>
                            <strong>
                                <HighlightedText text={userName} searchText={searchText}/>
                            </strong>
                        </div>
                        <div>
                            <small>{t(`newSettings.users.changeRolesView.userTypes.${userType}`)}</small>
                        </div>
                    </span>
                    {
                        headers.map(header => (
                            this.renderItem(header)
                        ))
                    }
                    {
                        buttons &&
                        <div className={"user-buttons"}>
                            {buttons(this.props)}
                        </div>
                    }
                </ListItem>
                <Collapse in={show}>
                    <div>
                        <TableGrid data={privileges} headers={tableHeaders} shouldIndex/>
                    </div>
                </Collapse>
            </div>
        )
    }
}

ChooseUserItem.propTypes = {
    user: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    buttons: PropTypes.func
};


ChooseUserItem = connect((state, props) => ({
    farms: getFarmsForChoosenUser(state, {selectedUser: props.user})
}))(ChooseUserItem);

export default withTranslation()(ChooseUserItem);
