import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
    calculateGridRowsByHeight,
    dateFormatter,
    dateRenderer,
    getInitialValues,
    insertData,
    isEmptyRow
} from "../../../../utils/DataGridUtils";
import {Formik} from "formik";
import {submit} from "../InsertionSubmit";
import FormForEvents from "../../events/FormForEvents";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import buildingsDB from "../../../../database/buildingsDB";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import {eventChooserFormNames} from "../../../../constans/formNames";
import {withRouter} from "react-router-dom";
import {isEmpty} from "lodash";
import animalsDB from "../../../../database/animalsDB";
import i18n from "../../../../i18n";
import formDataDB from "../../../../database/formDataDB";
import TooltipCell from "../../../../components/basics/table-input/cell/TooltipCell";
import {addZerosToRfid} from "../../../../utils/AnimalsUtils";
import {getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        races: state.dictionary.race.WData[state.language.lang.lang],
        suppliers: state.dictionary.suppliers.WData[state.language.lang.lang]
    };
}

class RenovationSowAndBoarGrid extends Component {

    state = {
        initialData: [],
        buildings: buildingsDB.getObjectForTreeSelect(this.props.farm),
        hasBoxes: false
    };
    formName = eventChooserFormNames.RENOVATION_SOW_AND_BOAR_INSERTION + "_" + this.props.match.params.locationID + "_" + this.props.match.params.animalKind;

    componentDidMount() {
        this.setState({
            ...this.getInitialData()
        })
    }

    getInitialData(returnSavedData = true) {
        const {match: {params: {locationID}}} = this.props;
        let location = buildingsDB.getLocationByID(locationID);
        if (location.Boxes) {
            let savedData = formDataDB.getSavedData(this.formName);
            let initialData = location.Boxes.map(box => {
                let animal = animalsDB.getAllAnimalsByPlcmntID(box.BID, {joinEvents: false})[0];
                if (animal) {
                    return {
                        box,
                        occupied: true,
                        AnmNo1: animal.AnmNo1,
                        AnmNo2: animal.AnmNo2,
                        RFID: animal.RFID,
                        birthDate: animal.DtaBrthTime,
                        weight: animal.Weight,
                        price: animal.Price,
                        race: animal.Race,
                        provider: animal.SupplierID
                    }
                } else {
                    let savedRow = savedData ? savedData.data.data.find(item => item.box.BID === box.BID) : {};
                    return {
                        box,
                        ...(savedRow || {})
                    }
                }
            });
            return {
                initialData,
                hasBoxes: true
            }
        } else {
            if (returnSavedData) {
                return {
                    initialData: getInitialValues(this.formName),
                    hasBoxes: false
                }
            } else {
                return {
                    initialData: new Array(calculateGridRowsByHeight()).fill({}),
                    hasBoxes: false
                }
            }
        }
    }

    onFiltersChange = (filters, value, oldFilters) => {
        if (filters.hasOwnProperty("AnmNo1")) {
            const {AnmNo1} = filters;
            value = insertData(AnmNo1, "AnmNo1", value, oldFilters.AnmNo1, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("AnmNo2")) {
            const {AnmNo2} = filters;
            value = insertData(AnmNo2, "AnmNo2", value, oldFilters.AnmNo2, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("RFID")) {
            const {RFID} = filters;
            value = insertData(RFID, "RFID", value, oldFilters.RFID, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("birthDate")) {
            const {birthDate} = filters;
            value = insertData(birthDate, "birthDate", value, oldFilters.birthDate, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("location")) {
            const {location} = filters;
            value = insertData(location, "location", value, oldFilters.location, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("provider")) {
            const {provider} = filters;
            value = insertData(provider, "provider", value, oldFilters.provider, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("price")) {
            const {price} = filters;
            value = insertData(price, "price", value, oldFilters.price, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("race")) {
            const {race} = filters;
            value = insertData(race, "race", value, oldFilters.race, this.insertDataPredicate);
        }
        if (filters.hasOwnProperty("date")) {
            const {date} = filters;
            value = insertData(date, "date", value, oldFilters.date, this.insertDataPredicate);
        }
        return value;
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t, farm} = this.props;
        let hasAnyErrors = false;
        if (data) {
            errors.data = data.map((row, index) => {
                const rowErrors = {};
                if (!row.occupied && !isEmptyRow(row, ["box"])) {
                    if (!row.AnmNo1) {
                        rowErrors.AnmNo1 = t("required");
                    } else if (animalsDB.checkIfAnimalExistOnFarm(row.AnmNo1, farm)) {
                        rowErrors.AnmNo1 = t("errors.exist");
                    } else if (data.find((d, idx) => d.AnmNo1 === row.AnmNo1 && index !== idx)) {
                        rowErrors.AnmNo1 = t("errors.duplicate");
                    }
                    if (row.RFID) {
                        const rfid = addZerosToRfid(row.RFID + "");
                        if (animalsDB.checkIfHaveRFID(rfid, farm)) {
                            rowErrors.RFID = t("errors.exist");
                        } else if (data.find((d, idx) => d.RFID === row.RFID && index !== idx)) {
                            rowErrors.RFID = t("errors.duplicate");
                        }
                    }
                    if (!row.date) {
                        rowErrors.date = t("required");
                    }
                }

                if (!isEmpty(rowErrors)) {
                    hasAnyErrors = true;
                }
                return rowErrors;
            })
        }
        if (!hasAnyErrors) delete errors.data;

        return errors;
    }

    anmNo1Filter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Nr zwierzęcia"}/>
    }

    anmNo2Filter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Alter. nr zwierz."}/>
    }

    RFIDFilter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"RFID"}/>
    }

    weightFilter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Waga"}/>
    }

    priceFilter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Cena"}/>
    }

    supplierFilter = ({value, onChange}) => {
        const {suppliers} = this.props;
        return <Select value={value}
                       options={suppliers.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={"Dostawca"}/>
    }

    raceFilter = ({value, onChange}) => {
        const {races} = this.props;
        return <Select value={value}
                       options={races.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={"Rasa"}/>
    }

    supplierFormatter = ({column: {key}, row}) => {
        const {suppliers} = this.props;
        let id = row[key];
        let supplier = suppliers.find(item => item.ID === id);
        if (supplier) return supplier.Value;
        return null;
    }

    raceFormatter = ({column: {key}, row}) => {
        const {races} = this.props;
        let id = row[key];
        let race = races.find(item => item.ID === id);
        if (race) return race.Value;
        return null;
    }

    boxFormatter = ({column: {key}, row}) => {
        let value = row[key];
        if (value) return <TooltipCell value={value.BoxesName}/>;
        return null;
    }

    submit = (values) => {
        return submit(values, this.api, this.props);
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: this.getInitialData(false).initialData
        })
    }

    isRowEditable = ({occupied}) => !occupied;

    insertDataPredicate = (data) => this.isRowEditable(data) && !isEmptyRow(data, ["box"]);

    getApi = api => {
        this.api = api;
    }

    customIsEmpty = row => {
        if (!row.occupied) {
            return isEmptyRow(row, ["box"]);
        }
        return true;
    }

    weightFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    render() {
        const {initialData, hasBoxes} = this.state;
        const {suppliers, races, t} = this.props;
        const columns = [];
        if (hasBoxes) {
            columns.push({
                name: t("standing"),
                key: "box",
                editable: false,
                formatter: this.boxFormatter
            })
        }
        columns.push(...[
            {
                name: t("animalNumberShort"),
                key: "AnmNo1",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"text"}/>),
                filterRenderer: this.anmNo1Filter,
                editable: this.isRowEditable
            },
            {
                name: t("alternativeAnimalNumberShort"),
                key: "AnmNo2",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"text"}/>),
                filterRenderer: this.anmNo2Filter,
                editable: this.isRowEditable
            },
            {
                name: t("rfid"),
                key: "RFID",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}/>),
                filterRenderer: this.RFIDFilter,
                editable: this.isRowEditable
            },
            {
                name: t("birthDate"),
                key: "birthDate",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer,
                editable: this.isRowEditable,
            },
            {
                name: t("weight"),
                key: "weight",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                filterRenderer: this.weightFilter,
                editable: this.isRowEditable,
                formatter: this.weightFormatter
            },
            {
                name: t("supplier"),
                key: "provider",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={suppliers.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.supplierFormatter,
                filterRenderer: this.supplierFilter,
                editable: this.isRowEditable
            },
            {
                name: t("price"),
                key: "price",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}/>),
                filterRenderer: this.priceFilter,
                editable: this.isRowEditable
            },
            {
                name: t("race"),
                key: "race",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={races.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.raceFormatter,
                filterRenderer: this.raceFilter,
                editable: this.isRowEditable
            },
            {
                name: i18n.t("date"),
                key: "date",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer,
                editable: this.isRowEditable
            }
        ])
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange} formName={this.formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} ignoreFields={hasBoxes ? ["box", "occupied"] : []}
                                       isSubmitting={isSubmitting} disableAddingNewRow={hasBoxes} getApi={this.getApi}
                                       customIsEmpty={this.customIsEmpty}/>
                    )
                }
            </Formik>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter
)(RenovationSowAndBoarGrid);
