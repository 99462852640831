import {convertWeightToBaseUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {addZerosToRfid} from "../../../utils/AnimalsUtils";
import devicesDB from "../../../database/devicesDB";
import {
    createInsertion,
    createInsertionNotificationFailure,
    createInsertionNotificationSuccess
} from "../../../api/events/Insertion";

const devices = {};

function getDevices(locationID) {
    if (devices[locationID]) return devices[locationID];
    let devs = devicesDB.getDevicesInPlcmntID(locationID, {showDevicesInChildren: false}).map(device => ({
        DevID: device.DevID,
        GwID: device.GatewayID
    }));
    devices[locationID] = devs;
    return devs;
}

export function submit(values, api, props) {
    const data = [];
    const {match: {params: {locationID, animalKind}}, farm} = props;
    for (let row of values.data) {
        const {AnmNo1, AnmNo2, RFID, weight, provider, price, race, cycles, lastSeparation, birthDate, group, box, occupied} = row;
        if (AnmNo1 && !occupied) {
            let additionalData = {
                AnmNo2,
                RFID: RFID ? addZerosToRfid(RFID + "") : undefined,
                Weight: weight ? convertWeightToBaseUnit(+weight, {fromUnit: UnitTypes.MEDIUM}) : undefined,
                Provider: provider,
                Price: price ? +price : undefined,
                Race: race,
                DtaBrthTime: birthDate ? +birthDate : undefined
            }
            let d = {
                AnmNo1,
                AnmCnt: 1,
                PlcmntID: box ? box.BID : locationID,
                AnimalKind: +animalKind,
                Cycle: cycles ? +cycles : undefined,
                LastSeparation: lastSeparation ? +lastSeparation.startOf("day") : undefined,
                additionalData,
                devices: getDevices(box ? box.BID : locationID),
            };
            if (group) {
                if (typeof group === "object") {
                    d.addToGroup = group.AnmGrp;
                } else {
                    d.newGroupNumber = group;
                }
            }
            data.push(d);
        }
    }
    return createInsertion(data).then(res => {
        createInsertionNotificationSuccess(res);
        api.resetForm();
        props.history.push(`/${farm}/eventChooser/animalKind/insertion`);
    }).catch(e => {
        createInsertionNotificationFailure(e);
    })
}