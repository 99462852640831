import React from "react";
import moment from "moment";
import {withTranslation} from "react-i18next";

export class MobileLastScannedRFIDRow extends React.Component {

    render() {
        console.log(this.props);
        const {object: {date, animal: {AnmNo1}, RFID, type}, getType, getData, object, t} = this.props;
        return (
            <div className="mobile-rfid-row">
                <div className="font-weight-bold">
                    {t("lastScannedRFID.animalNo")}: {AnmNo1}
                </div>
                <div>
                    {t("date")}: {moment(date).format("DD.MM.YYYY HH:mm")}
                </div>
                <div>
                    {t("rfidNumber")}: {RFID}
                </div>
                <div>
                    {t("lastScannedRFID.eventData")}: {getType(type)}
                </div>
                {getData({value: object})}
            </div>
        );
    }

}

export default withTranslation()(MobileLastScannedRFIDRow);