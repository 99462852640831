import React, {Component} from 'react';
import {connect} from 'react-redux';
import List from "../../components/basics/list/List";
import LogItem from "../../components/logs-view-components/LogItem";
import Header from "../../components/basics/header/Header";
import BetaSign from "../../components/basics/BetaSign";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import Card from "../../components/basics/card/Card";
import {getParsedLogs} from "../../selectors/logSelector";
import {clearLogs, listLogsDynamoDB} from "../../actions/logsActions";
import LogSearch from "../../components/logs-view-components/LogSearch";
import LogRefresh from "../../components/logs-view-components/LogRefresh";
import LogLoader from "../../components/logs-view-components/LogLoader";
import {CellMeasurer, CellMeasurerCache, WindowScroller} from "react-virtualized";
import {List as VirtualizedList} from "react-virtualized/dist/commonjs/List";
import {getRemToPx} from "../../utils/DOMUtils";
import {Row} from "react-bootstrap";
import {get} from "lodash";
import {withTranslation} from "react-i18next";
import LogTutorial from "../../components/logs-view-components/log-tutorial/LogTutorial";

function mapStateToProps(state) {
    return {
        logs: getParsedLogs(state),
        fetching: state.logs.fetching
    };
}

class LogsView extends Component {

    cache = new CellMeasurerCache({
        defaultHeight: getRemToPx(3),
        fixedWidth: true,
        keyMapper: (rowIndex) => get(this, `props.logs[${rowIndex}].log.LogID`, rowIndex)
    });

    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(listLogsDynamoDB());
    }

    clearCache = () => {
        console.log("clear cache");
        this.cache.clearAll();
    };

    rowRenderer = ({index, style, key, parent}) => {
        const {logs} = this.props;
        const log = logs[index] || {};
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({measure}) => (
                    <LogItem style={style} log={log} key={index} index={index} measure={measure}/>
                )}
            </CellMeasurer>
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.logs.length < prevProps.logs.length) {
            this.clearCache();
        }
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(clearLogs())
    }

    noRowsRenderer = () => {
        const {fetching, t} = this.props;
        return fetching ? <div className={"mh-5rem pb-4 pt-4 d-flex justify-content-center w-100 align-items-center"}>
            <i className={"fas fa-fw fa-spin fa-circle-notch fa-2x"}/>
        </div> : <div className={"mh-5rem pb-4 pt-4 d-flex justify-content-center opacity-50 w-100 align-items-center"}>
            <h4><span>{t("logsNotFound")}</span></h4>
        </div>
    }

    render() {
        const {logs, fetching, t} = this.props;
        return (
            <div>
                <Header text={<>{t("logsView.title")}{' '}<BetaSign/></>}/>
                <ViewContainer>
                    <LogTutorial/>
                    <Card className={"min-vh-25"}>

                        <LogSearch clearCache={this.clearCache}/>
                        <Row className={"position-relative background-inherit"}>
                            <WindowScroller onResize={this.clearCache}>
                                {
                                    ({height, isScrolling, scrollTop, onChildScroll, registerChild}) =>
                                        <List itemRef={registerChild} useFlexLayout={false}
                                              striped={true}>
                                            <LogRefresh index={-1}/>
                                            <VirtualizedList
                                                rowCount={logs.length}
                                                width={window.innerWidth}
                                                rowRenderer={this.rowRenderer}
                                                scrollTop={scrollTop}
                                                data={logs}
                                                autoHeight
                                                fetching={fetching}
                                                height={height}
                                                deferredMeasurementCache={this.cache}
                                                rowHeight={this.cache.rowHeight}
                                                isScrolling={isScrolling}
                                                onScroll={onChildScroll}
                                                className={"w-100 overflow-auto"}
                                                noRowsRenderer={this.noRowsRenderer}
                                            />
                                            {
                                                !!logs.length && <LogLoader index={logs.length}/>
                                            }

                                        </List>
                                }
                            </WindowScroller>
                        </Row>
                    </Card>
                </ViewContainer>
            </div>
        );
    }
}

LogsView = connect(
    mapStateToProps,
)(LogsView);
export default withTranslation()(LogsView)
