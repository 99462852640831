import React from "react";
import PropTypes from "prop-types";
import {AnimalTypes} from "../../../constans/animalTypes";
import ItemPicker from "../../../components/basics/item-picker/ItemPicker";
import sow from "../../../resources/images/animal-kind-chooser/sow.svg";
import boar from "../../../resources/images/animal-kind-chooser/boar.svg";
import porker from "../../../resources/images/animal-kind-chooser/porker.svg";
import piglet from "../../../resources/images/animal-kind-chooser/piglet.svg";
import renovationSow from "../../../resources/images/animal-kind-chooser/new-sow.svg"
import porkerSingle from "../../../resources/images/animal-kind-chooser/porkerSingle.svg"
import pigletSingle from "../../../resources/images/animal-kind-chooser/pigletSingle.svg"
import {withTranslation} from "react-i18next";
import InsertionStepper from "./InsertionStepper";
import {compose} from "redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class AnimalKindChooser extends React.Component {

    onClick = (type) => {
        const {farm, match: {params: {nextEvent}}} = this.props;
        let sectorChooser = [AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(type);
        this.props.history.push(`/${farm}/eventChooser/locationChooser/${nextEvent}?sectors=${sectorChooser}&redirect=/${type}`);
    };

    render() {
        return (
            <div>
                <InsertionStepper activeStep={2}/>
                <ItemPicker value={null} options={[
                    {
                        label: this.props.t("events.animalKindChooser.renovationsow"),
                        value: AnimalTypes.RENOVATION_SOW,
                        svgPath: renovationSow
                    },
                    {
                        label: this.props.t("events.animalKindChooser.sow"),
                        value: AnimalTypes.SOW,
                        svgPath: sow
                    },
                    {
                        label: this.props.t("events.animalKindChooser.porker"),
                        value: -AnimalTypes.PORKER,
                        svgPath: porkerSingle
                    },
                    {
                        label: this.props.t("events.animalKindChooser.porkerGroup"),
                        value: AnimalTypes.PORKER,
                        svgPath: porker
                    },
                    {
                        label: this.props.t("events.animalKindChooser.boar"),
                        value: AnimalTypes.BOAR,
                        svgPath: boar
                    },
                    {
                        label: this.props.t("events.animalKindChooser.piglet"),
                        value: -AnimalTypes.PIGLET,
                        svgPath: pigletSingle
                    },
                    {
                        label: this.props.t("events.animalKindChooser.pigletGroup"),
                        value: AnimalTypes.PIGLET,
                        svgPath: piglet
                    }
                ]} onChange={(val) => this.onClick(val)} showLabels itemsInRow={4}/>
            </div>
        );
    }

}

AnimalKindChooser.propTypes = {
    onNextStep: PropTypes.func
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(AnimalKindChooser);