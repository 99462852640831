import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {show} from "redux-modal";
import {connect} from 'react-redux'
import ReduxLabeledSelect from "../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {submit, validate} from "./AddUserSubmit"
import ReduxLabeledInput from "../../../components/basics/input/labeled-input/ReduxLabeledInput";
import Card from "../../../components/basics/card/Card";
import withRoles from "../../../components/withRoles";
import {Roles} from "../../../constans/roles";
import Header from "../../../components/basics/header/Header";
import ViewContainer from "../../../components/basics/view-container/ViewContainer";
import {getDevices} from "../../../actions/devicesActions";
import Button from "../../../components/basics/button/Button";
import {isMobile} from "../../../utils/MobileUtils";
import "./_add-user-view.scss";
import {withTranslation} from "react-i18next";
import ReduxRolePicker from "../../../components/users/role-picker/ReduxRolePicker";
import {fetchBuildingsByFarmID} from "../../../actions/farmsActions";
import {get, isEmpty} from "lodash";
import SelectDefaultFarmModal from "../../../components/modals-new/select-default-farm-modal/SelectDefaultFarmModal";
import {bindActionCreators} from "redux";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Tooltip from "../../../components/basics/tooltip/Tooltip";

const FormName = 'settings-users-add';

export class AddUserView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.getOptions(this.props)
        };
        const {dispatch, buildings} = this.props;
        dispatch(initialize(FormName, {
            userType: "U",
            roles: [],
            farm: props.farms[0] ? props.farms[0].FarmID : ""
        }));
        if (props.farms.length > 0 && props.farms[0].FarmID && isEmpty(buildings)) {
            dispatch(fetchBuildingsByFarmID(props.farms[0].FarmID));
        }
    }

    componentDidMount(){
        const {FarmData} = this.props;
        this.props.dispatch({
            type: "GET_FARMS",
            payload: FarmData
        });
    }

    getOptions = (props) => {
        const {t} = props;
        return {
            userTypeOptions: [
                {name: t("operator"), value: "U"},
                {name: t("newSettings.users.addUserView.farmAdminUser"), value: "F"},
            ]
        }
    };

    onChange = (event, newValue) => {
        this.props.dispatch(getDevices(newValue));
    };

    onButtonClick(farmID) {
        const {dispatch, change} = this.props;
        change("farm", farmID);
        dispatch(fetchBuildingsByFarmID(farmID));
    }

    render() {
        const {handleSubmit, t, farms, selectedFarm, selectedUserType, submitFailed, submitting} = this.props;
        const {userTypeOptions} = this.state;
        const _farm = farms.find(item => item.FarmID === selectedFarm);
        return (
            <div>
                <Header text={t("newSettings.users.addUserView.header")}/>
                <ViewContainer addPaddingForButtons>
                    <Card className={FormName}>
                        <form onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Field
                                        name="userLogin"
                                        id="userLogin"
                                        required
                                        type="text"
                                        component={ReduxLabeledInput}
                                        label={t("login.login")}
                                    />
                                    <Field
                                        name="email"
                                        id="email"
                                        required
                                        type="email"
                                        component={ReduxLabeledInput}
                                        label={t("email2")}
                                    />
                                    <Field
                                        name="address"
                                        id="address"
                                        type="text"
                                        component={ReduxLabeledInput}
                                        label={t("newSettings.users.addUserView.address")}
                                    />
                                    <Field
                                        name="userName"
                                        id="userName"
                                        required
                                        type="text"
                                        component={ReduxLabeledInput}
                                        label={t("nameAndSurrname")}
                                    />
                                    <Field
                                        id="userType"
                                        name="userType"
                                        required
                                        component={ReduxLabeledSelect}
                                        label={t("newSettings.users.addUserView.userType")}
                                        options={userTypeOptions}
                                    />
                                    <Field
                                        name="phone"
                                        id="phone"
                                        type="tel"
                                        component={ReduxLabeledInput}
                                        label={t("newSettings.users.addUserView.phone")}
                                    />
                                </Col>
                            </Row>
                            <hr/>
                            <h4 className="mb-2">{t("newSettings.users.addUserView.farmPrivilege", {name: get(_farm, "FarmName", "")})}</h4>
                            { farms.length > 0 &&
                            <Row className={!isMobile() ? "permissions" : "permissions-mobile"}>
                                <Col xl={4} id="Col1" className="d-flex flex-column align-items-xl-end ">
                                    {
                                        farms.map((farm, index) =>
                                            <Button buttonStyle={"text"}
                                                    buttonColor={selectedFarm === farm.FarmID ? "success" : ""}
                                                    key={index} type="button"
                                                    onClick={() => this.onButtonClick(farm.FarmID)}>{farm.FarmName}</Button>
                                        )
                                    }
                                </Col>
                                <Col xl={6} className="d-flex  justify-content-lg-start">
                                    <Field
                                        name="roles"
                                        id="roles"
                                        required
                                        component={ReduxRolePicker}
                                        label={t("roles")}
                                        farm={selectedFarm}
                                        selectedUserType={selectedUserType}
                                    />
                                </Col>
                            </Row>}
                            { farms.length <= 0 &&
                                <label className="d-flex  justify-content-center">-- {t("farms.farmItem.notfound")} --</label>
                            }
                            <ButtonGroup fixed renderInPortal={false}>
                                {
                                    submitFailed || farms.length <= 0 &&
                                    <Tooltip
                                        tooltipContent={"Proszę uzupełnić wymagane pola oraz nadać uprawnienie przynajmniej dla jednej fermy!"}
                                        type={"error"}>
                                        <i className="fas fa-exclamation-circle error"/>
                                    </Tooltip>
                                }
                                <Button buttonColor={"success"} buttonStyle={"round"} disabled={farms.length <= 0}
                                        icon={<i className="fas fa-save"/>} isLoading={!!submitting}/>
                            </ButtonGroup>
                        </form>
                    </Card>
                </ViewContainer>
                <SelectDefaultFarmModal/>
            </div>
        );
    }
}

AddUserView = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(AddUserView);

const selector = formValueSelector(FormName);
export let _AddUserView = connect(state => ({
        farm: state.location.farm,
        farms: state.farms.farms,
        selectedFarm: selector(state, "farm"),
        selectedUserType: selector(state, "userType"),
        buildings: state.farms.buildings,
        FarmData: state.user.user.FarmData
    }),
    dispatch => bindActionCreators({show}, dispatch))(AddUserView);
AddUserView = withRoles({roles: [Roles._USER_MANAGE], showComponent: true})(_AddUserView);

export default withTranslation()(AddUserView);

