import React from "react";
import FormForEvents from "../FormForEvents";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {
    animalField,
    calculateGridRowsByHeight,
    checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getInitialValues,
    getSows,
    insertComment,
    insertData,
    insertOperator,
    operatorField,
    technologyGroupInsert,
    validateGrid
} from "../../../../utils/DataGridUtils";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import {Formik} from "formik";
import {isEqual, isNil} from "lodash";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {convertWeightToBaseUnit, getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {
    createParturition,
    createParturitionNotificationFailure,
    createParturitionNotificationSuccess
} from "../../../../api/events/Parturition";
import {eventChooserFormNames} from "../../../../constans/formNames";
import eventsDB from "../../../../database/eventsDB";
import {EventTypes, PARTURITION_DIFFICULTY} from "../../../../constans/eventTypes";
import moment from "moment";
import {getTimeFromInseminationToPartuition} from "../../../../utils/SettingsUtils";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";

const formName = eventChooserFormNames.PARTURITION;

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

export class Parturition extends React.Component {

    state = {
        sows: [],
        parturitionDifficulties: [
            {name: this.props.t("low"), value: PARTURITION_DIFFICULTY.LOW},
            {name: this.props.t("medium"), value: PARTURITION_DIFFICULTY.MEDIUM},
            {name: this.props.t("hard"), value: PARTURITION_DIFFICULTY.HARD}
        ],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getSows(farm);
        this.setState({
            sows: getAnimalOptions(sows),
            initialData: getInitialValues(formName)
        });
    }

    onRowChange = animal => {
        if (animal) {
            let tmp = this.getParturitionDate(animal);
            if (tmp) {
                return {
                    date: tmp
                }
            }
        }
        return {};
    }

    onFiltersChange = (filters, value, oldFilters) => {
        if (filters.hasOwnProperty("animal")) {
            const {animal: group} = filters;
            const {animal: oldGroup} = oldFilters;
            if (!isEqual(group, oldGroup)) {
                value = technologyGroupInsert(group, value, {onRowChange: this.onRowChange});
            }
        }
        if (filters.hasOwnProperty("date")) {
            const {date} = filters;
            value = insertData(date, "date", value, oldFilters.date);
        }
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight);
        }
        if (filters.hasOwnProperty("piglets")) {
            const {piglets} = filters;
            value = insertData(piglets, "piglets", value, oldFilters.piglets);
        }
        if (filters.hasOwnProperty("dead")) {
            const {dead} = filters;
            value = insertData(dead, "dead", value, oldFilters.dead);
        }
        if (filters.hasOwnProperty("mummy")) {
            const {mummy} = filters;
            value = insertData(mummy, "mummy", value, oldFilters.mummy);
        }
        if (filters.hasOwnProperty("temperature")) {
            const {temperature} = filters;
            value = insertData(temperature, "temperature", value, oldFilters.temperature);
        }
        if (filters.hasOwnProperty("falls")) {
            const {falls} = filters;
            value = insertData(falls, "falls", value, oldFilters.falls);
        }
        if (filters.hasOwnProperty("difficulty")) {
            const {difficulty} = filters;
            value = insertData(difficulty, "difficulty", value, oldFilters.difficulty);
        }
        value = insertOperator(filters, value, oldFilters.operator);
        value = insertComment(filters, value, oldFilters.comment);
        return value;
    }

    difficultyFormatter = ({column: {key}, row}) => {
        const {t} = this.props;
        switch (row[key]) {
            case PARTURITION_DIFFICULTY.LOW:
                return t("low");
            case PARTURITION_DIFFICULTY.MEDIUM:
                return t("medium");
            case PARTURITION_DIFFICULTY.HARD:
                return t("hard");
            default:
                return "";
        }
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            sows: getAnimalOptions(getSows(farm, animalsAlreadySelected))
        })
    }

    // dla kazdego filtra musi byc osobno renderowane, bo inacze przy kazdej zmianie renderuje sie na nowo co wprowadza bledy
    weightFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"}
                            placeholder={t("averagePigletWeightShort")}/>
    }

    pigletsFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("alive")}
                            allowFloat={false}/>
    }

    deadFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("dead")}
                            allowFloat={false}/>
    }

    mummyFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("mummies")}
                            allowFloat={false}/>
    }

    temperatureFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"}
                            placeholder={t("newSettings.dispenserNRF.temperature.title")}/>
    }

    fallsFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("menu.fall")}
                            allowFloat={false}/>
    }

    difficultyFilter = ({value, onChange}) => {
        const {parturitionDifficulties} = this.state;
        const {t} = this.props;
        return <Select value={value} options={parturitionDifficulties} onChange={onChange}
                       placeholder={t("difficulty")}/>
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (isNil(row.weight)) {
                    rowErrors.weight = t("required");
                }

                if (isNil(row.piglets)) {
                    rowErrors.piglets = t("required");
                } else if (+row.piglets < 0) {
                    rowErrors.piglets = t("errors.noLessOrEq0");
                }

                if (isNil(row.dead)) {
                    rowErrors.dead = t("required");
                } else if (+row.dead < 0) {
                    rowErrors.dead = t("errors.lessVal", {count: 0});
                }

                if (isNil(row.mummy)) {
                    rowErrors.mummy = t("required");
                } else if (+row.mummy < 0) {
                    rowErrors.mummy = t("errors.lessVal", {count: 0});
                }

                if (+row.piglets === 0 && +row.dead === 0 && +row.mummy === 0) {
                    rowErrors.piglets = t("errors.atLeastMusBeGreaterThan0");
                    rowErrors.dead = t("errors.atLeastMusBeGreaterThan0");
                    rowErrors.mummy = t("errors.atLeastMusBeGreaterThan0");
                }

                return rowErrors;
            })
        }
        if (errors.data === undefined) delete errors.data;
        return errors;
    }

    submit = (values) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, dead, piglets, mummy, weight, temperature, falls, difficulty} = row;
            console.log(weight);
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment,
                    DeadCnt: +dead,
                    HealthyCnt: +piglets,
                    MummyCnt: +mummy,
                    Weight: convertWeightToBaseUnit(parseFloat(weight), {fromUnit: UnitTypes.MEDIUM}) * +piglets,
                    Temperature: temperature,
                    Falls: falls,
                    Type: difficulty
                })
            }
        }
        return createParturition(data).then(res => {
            createParturitionNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createParturitionNotificationFailure(e);
        })
    }

    getParturitionDate(animal) {
        let insemination = eventsDB.getAllEvents4Animal(animal.AnmID).filter(item => item.EvCode === EventTypes.INSEMINATION).sort((a, b) => b.EvTime - a.EvTime)[0];
        if (insemination) {
            let time = getTimeFromInseminationToPartuition();
            return moment(insemination.EvTime).add(time, "days");
        }
        return null;
    }

    onAnimalChange = updated => {
        if (updated.animal) {
            let tmp = this.getParturitionDate(updated.animal);
            if (tmp) {
                updated.date = tmp;
            }
        }
        return updated;
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    weightFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    render() {
        const {sows, initialData, parturitionDifficulties} = this.state;
        const {t} = this.props;
        const columns = [
            animalField(sows, this.onAnimalChange),
            {
                name: t("averagePigletWeightShort"),
                key: "weight",
                filterRenderer: this.weightFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                formatter: this.weightFormatter
            },
            {
                name: t("alive"),
                key: "piglets",
                filterRenderer: this.pigletsFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>)
            },
            {
                name: t("dead"),
                key: "dead",
                filterRenderer: this.deadFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>)
            },
            {
                name: t("mummies"),
                key: "mummy",
                filterRenderer: this.mummyFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>)
            },
            {
                name: t("newSettings.dispenserNRF.temperature.title"),
                key: "temperature",
                filterRenderer: this.temperatureFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}/>)
            },
            {
                name: t("menu.fall"),
                key: "falls",
                filterRenderer: this.fallsFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>)
            },
            {
                name: t("difficulty"),
                key: "difficulty",
                filterRenderer: this.difficultyFilter,
                editor: React.forwardRef((props, ref) => <SelectEditor {...props} options={parturitionDifficulties}
                                                                       ref={ref}/>),
                formatter: this.difficultyFormatter
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} getApi={this.getApi}
                                       isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Parturition);
