import React, {Component} from 'react';
import Tooltip from "../../../../components/basics/tooltip/Tooltip";
import "./_animal-state-header.scss";
import {withTranslation} from "react-i18next";

class AnimalStateHeader extends Component {

    render() {
        const {column: {name}, t} = this.props;
        return (
            <div className="animal-state-header">
                {name}
                <Tooltip tooltipContent={t("sowStatus")}>
                    <i className="fas fa-question-circle"/>
                </Tooltip>
            </div>
        );
    }
}

export default withTranslation()(AnimalStateHeader);