import React from "react";
import "./_farm-settings.scss";
import {Route, Switch} from "react-router-dom";
import {Roles} from "../../constans/roles";
import SideBar from "../../components/basics/side-bar/SideBar";
import BillingView from "./billing/BillingView";
import BillingHistoryView from "./billing/BillingHistoryView";
import {isMobile} from "../../utils/MobileUtils";
import SubProcess from "../new-settings-view/settings/license/subscriptions/subscribe/SubProcess";
import AddUserView from "./users/AddUserView";
import ChangeRolesView from "./users/ChangeRolesView";
import {checkIfUserIsFarmAdmin, checkIfUserIsOwner, checkIfUserIsService} from "../../utils/NewRolesUtils";
import NoRoleComponent from "../../components/NoRoleComponent";
import AdminSubProcess from "../new-settings-view/settings/license/subscriptions/subscribe/AdminSubProcess";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {withTranslation} from "react-i18next";
import BillingEditFarm from "./billing/BillingEditFarm";

class FarmSettings extends React.Component {

    render() {
        if (!checkIfUserIsOwner() && !checkIfUserIsFarmAdmin() && !checkIfUserIsService()) return <NoRoleComponent/>;
        const {t, location} = this.props;
        const menuItems = [
            {
                icon: "fas fa-user-cog",
                locations: ["users"],
                href: '/farmSettings/users',
                name: t("users"),
                roles: [Roles._USER_MANAGE]
            },
            {
                icon: "fas fa-dollar-sign",
                locations: ["billing"],
                href: '/farmSettings/billing',
                name: t("billing"),
                roles: [Roles._BILLING],
                hide: process.env.REACT_APP_STAGE === "production"
            },
        ];

        return (
            <div className={isMobile() ? "farm-settings mobile" : "farm-settings"}>
                <SideBar menuItems={menuItems} showPing={false}/>
                <div className="farm-settings-main">
                    <Switch location={location}>
                        <Route path="/farmSettings/billing" render={props => <BillingView {...props}/>} exact/>
                        <Route path="/farmSettings/billing/farm/:FarmID/edit"
                               render={props => <BillingEditFarm {...props}/>} exact/>
                        <Route path="/farmSettings/billing/farm/:FarmID/renew"
                               render={props => <SubProcess {...props}/>} exact/>
                        <Route path="/farmSettings/billing/history" render={props => <BillingHistoryView {...props}/>}
                               exact/>
                        <Route path="/farmSettings/subscription/add" render={props => <SubProcess {...props}/>} exact/>
                        <Route path={`/farmSettings/users`} render={(props) => <ChangeRolesView {...props}/>} exact/>
                        <Route path={`/farmSettings/users/add`} render={() => <AddUserView/>} exact/>
                        <Route path={`/farmSettings/admin/renew`} render={() => (
                            <ViewContainer> {/*Musialem tak zrobic, zeby marginesy sie zgadzaly*/}
                                <AdminSubProcess/>
                            </ViewContainer>
                        )}/>
                    </Switch>
                </div>
            </div>
        );
    }
}
export default withTranslation()(FarmSettings);
