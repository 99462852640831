import React from "react";
import Header from "../../../components/basics/header/Header";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getSubscriptionBasedOnFarmID} from "../../../selectors/billingSelector";
import ViewContainer from "../../../components/basics/view-container/ViewContainer";
import Card from "../../../components/basics/card/Card";
import {change, Field, FormSection, formValueSelector, initialize, isDirty, reduxForm} from "redux-form";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import ReduxSwitch from "../../../components/basics/switch/ReduxSwitch";
import "./_billing-edit-farm.scss";
import ReduxInput from "../../../components/basics/input/ReduxInput";
import {get} from "lodash";
import ReduxTextSwitch from "../../../components/basics/text-switch/ReduxTextSwitch";
import Radio from "../../../components/basics/radio/Radio";
import {gatewayCustomerFind} from "../../../actions/braintreeActions";
import * as BreedingModule from "validators-schema/Api/constants/breedingModules";
import {submit, validate} from "./BillingEditFarmSubmit";
import Tooltip from "../../../components/basics/tooltip/Tooltip";

const FormName = "billing-edit-farm";
const selector = formValueSelector(FormName);

function mapStateToProps(state, props) {
    const {match: {params: {FarmID}}} = props;
    return {
        user: state.user.user,
        dirty: isDirty(FormName)(state),
        customer: state.braintree.customer,
        userData: state.user.attributes,
        subscriptionData: getSubscriptionBasedOnFarmID(state, {farmID: FarmID}),
        showBreeding: selector(state, "showBreeding"),
        showDevices: selector(state, "showDevices"),
        showAdministration: selector(state, "showAdministration"),
        sowsSize: selector(state, "BreedingModule.sows.size"),
        sowsEnabled: selector(state, "BreedingModule.sowsEnabled"),
        porkersEnabled: selector(state, "BreedingModule.porkersEnabled"),
        silosesEnabled: selector(state, "DevicesModule.silosesEnabled"),
        climatesEnabled: selector(state, "DevicesModule.climatesEnabled"),
        cagesEnabled: selector(state, "DevicesModule.cagesEnabled"),
        dispensersEnabled: selector(state, "DevicesModule.dispensersEnabled"),
        dispensers5GEnabled: selector(state, "DevicesModule.dispensers5GEnabled"),
        metersEnabled: selector(state, "DevicesModule.metersEnabled"),
        smallCagesEnabled: selector(state, "DevicesModule.smallCagesEnabled"),
        chainsEnabled: selector(state, "DevicesModule.chainsEnabled"),
        alarmsEnabled: selector(state, "DevicesModule.alarmsEnabled"),
    }
}

class BillingEditFarm extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, customer, userData: {email, name, phone, sub}, subscriptionData: {addOns}} = props;
        if (!customer) {
            dispatch(gatewayCustomerFind(email, name, phone, sub, true));
        }

        const sowsSize = get(addOns, "BreedingModule.sows.size");
        const porkersSize = get(addOns, "BreedingModule.porkers.size");

        const silosesAmount = get(addOns, "DevicesModule.siloses.amount");
        const climatesAmount = get(addOns, "DevicesModule.climates.amount");
        const cagesAmount = get(addOns, "DevicesModule.cages.amount");
        const dispensersAmount = get(addOns, "DevicesModule.dispensers.amount");
        const dispensers5GAmount = get(addOns, "DevicesModule.dispensers5G.amount");
        const metersAmount = get(addOns, "DevicesModule.meters.amount");
        const smallCagesAmount = get(addOns, "DevicesModule.smallCages.amount");
        const alarmsAmount = get(addOns, "DevicesModule.alarms.amount");
        const chainsAmount = get(addOns, "DevicesModule.chains.amount");
        const managersAmount = get(addOns, "AdministrationModule.managersAmount");
        const operatorsAmount = get(addOns, "AdministrationModule.operatorsAmount");
        const techAdviceAmount = get(addOns, "AdministrationModule.techAdviceAmount");
        const techSupportAmount = get(addOns, "AdministrationModule.techSupportAmount");

        const shouldShowBreeding = () => {
            const breedingModule = get(addOns, "BreedingModule");
            for (const property in breedingModule) {
                if (get(breedingModule, `[${property}].size`, 0) > 0) return true;
            }
            return false;
        };

        const shouldShowDevices = () => {
            const devicesModule = get(addOns, "DevicesModule");
            for (const property in devicesModule) {
                if (get(devicesModule, `[${property}].amount`, 0) > 0) return true;
            }
            return false;
        };

        const shouldShowAdministration = () => {
            const administrationModule = get(addOns, "AdministrationModule");
            for (const property in administrationModule) {
                if (property.endsWith("Amount") && get(administrationModule, `[${property}]`, 0) > 0) return true;
            }
            return false;
        };


        const initialValue = {
            showBreeding: shouldShowBreeding(),
            showDevices: shouldShowDevices(),
            showAdministration: shouldShowAdministration(),
            BreedingModule: {
                sowsEnabled: !!sowsSize,
                porkersEnabled: !!porkersSize,
                sows: {
                    extended: get(addOns, "BreedingModule.sows.extended"),
                    size: sowsSize
                },
                porkers: {
                    extended: get(addOns, "BreedingModule.porkers.extended"),
                    size: porkersSize
                }
            },
            DevicesModule: {
                silosesEnabled: !!silosesAmount,
                climatesEnabled: !!climatesAmount,
                cagesEnabled: !!cagesAmount,
                dispensersEnabled: !!dispensersAmount,
                dispensers5GEnabled: !!dispensers5GAmount,
                metersEnabled: !!metersAmount,
                smallCagesEnabled: !!smallCagesAmount,
                chainsEnabled: !!chainsAmount,
                alarmsEnabled: !!alarmsAmount,
                siloses: {
                    extended: get(addOns, "DevicesModule.siloses.extended", false),
                    amount: silosesAmount,
                },
                climates: {
                    extended: get(addOns, "DevicesModule.climates.extended", false),
                    amount: climatesAmount,
                },
                cages: {
                    extended: get(addOns, "DevicesModule.cages.extended", false),
                    amount: cagesAmount,
                },
                dispensers: {
                    extended: get(addOns, "DevicesModule.dispensers.extended", false),
                    amount: dispensersAmount,
                },
                dispensers5G: {
                    amount: dispensers5GAmount,
                },
                meters: {
                    extended: get(addOns, "DevicesModule.meters.extended", false),
                    amount: metersAmount,
                },
                smallCages: {
                    extended: get(addOns, "DevicesModule.smallCages.extended", false),
                    amount: smallCagesAmount,
                },
                alarms: {
                    extended: get(addOns, "DevicesModule.alarms.extended", false),
                    amount: alarmsAmount,
                },
                chains: {
                    extended: get(addOns, "DevicesModule.chains.extended", false),
                    amount: chainsAmount,
                }
            },
            AdministrationModule: {
                managersAmount: managersAmount,
                operatorsAmount: operatorsAmount,
                techAdviceAmount: techAdviceAmount,
                techSupportAmount: techSupportAmount,
            }
        };
        dispatch(initialize(FormName, initialValue));

        this.state = {
            breedingFailed: false,
            breedingMessage: ""
        }
    }

    getFarmName = (props) => {
        return get(props, "subscriptionData.subscription.farmName");
    };

    amountValueParser = value => parseInt(value);

    onSowSizeChange = (value, props, e) => {
        const {dispatch, subscriptionData: {addOns}} = this.props;
        const {breedingFailed} = this.state;
        dispatch(change(FormName, "BreedingModule.sows.size", +e.target.value));
        const getAnimalsAmountBasedOnSize = (size) => {
            let amount;
            switch (size) {
                case BreedingModule.RangeTypes.small:
                    amount = BreedingModule.AnimalRanges.maxSmall;
                    break;
                case BreedingModule.RangeTypes.medium:
                    amount = BreedingModule.AnimalRanges.maxMedium;
                    break;
                case BreedingModule.RangeTypes.large:
                    amount = BreedingModule.AnimalRanges.maxLarge;
                    break;
                default:
                    amount = BreedingModule.AnimalRanges.maxMicro;
                    break;
            }
            return amount;
        };

        const usedAnimals = getAnimalsAmountBasedOnSize(get(addOns, "BreedingModule.sows.size")) - get(addOns, "BreedingModule.sows.left");
        if (usedAnimals > getAnimalsAmountBasedOnSize(+e.target.value)) {
            this.setState({
                breedingFailed: true,
                breedingMessage: usedAnimals
            })
        } else if (breedingFailed === true) {
            this.setState({
                breedingFailed: false,
            })
        }
    };

    onSwitchChange = value => {
        const propertiesModule = get(event, "target.id", "").split(/(?=[A-Z])/).map(s => s.toLowerCase());
        const {dispatch} = this.props;
        if (propertiesModule.length > 0) {
            if (propertiesModule[0] === "show") {
                if (propertiesModule[1] === "breeding") {
                    dispatch(change(FormName, "BreedingModule", {
                        sowsEnabled: value,
                        porkersEnabled: value,
                        sows: {
                            size: +value,
                            extended: false,
                        },
                        porkers: {
                            size: +value,
                            extended: false,
                        }
                    }))
                } else if (propertiesModule[1] === "devices") {
                    dispatch(change(FormName, "DevicesModule", {
                        silosesEnabled: value,
                        climatesEnabled: value,
                        cagesEnabled: value,
                        dispensersEnabled: value,
                        dispensers5GEnabled: value,
                        metersEnabled: value,
                        smallCagesEnabled: value,
                        chainsEnabled: value,
                        alarmsEnabled: value,
                        siloses: {
                            extended: false,
                            amount: +value,
                        },
                        climates: {
                            extended: false,
                            amount: +value,
                        },
                        cages: {
                            extended: false,
                            amount: +value,
                        },
                        dispensers: {
                            extended: false,
                            amount: +value,
                        },
                        dispensers5G: {
                            amount: +value,
                        },
                        meters: {
                            extended: false,
                            amount: +value,
                        },
                        smallCages: {
                            extended: false,
                            amount: +value,
                        },
                        alarms: {
                            extended: false,
                            amount: +value,
                        },
                        chains: {
                            extended: false,
                            amount: +value,
                        }
                    }));
                } else if (propertiesModule[1] === "administration") {
                    dispatch(change(FormName, "AdministrationModule", {
                        managersAmount: +value,
                        operatorsAmount: +value,
                        techAdviceAmount: +value,
                        techSupportAmount: +value,
                    }));
                }
            } else {
                if (propertiesModule[0] === "sows" || propertiesModule[0] === "porkers") {
                    const initial = {size: +value, extended: false};
                    dispatch(change(FormName, `BreedingModule[${propertiesModule[0]}]`, initial));
                } else {
                    const initial = {amount: +value, extended: false};
                    if (propertiesModule[0] === "dispensers5G") delete initial.extended;
                    dispatch(change(FormName, `DevicesModule[${propertiesModule[0]}]`, initial));
                }
            }
        }
    };

    getSwitchOptions = () => {
        const {t} = this.props;
        return [
            {
                name: t("basic"),
                value: false,
            },
            {
                name: t("advanced"),
                value: true
            }
        ];
    };

    render() {
        const {
            handleSubmit, showBreeding, showDevices, showAdministration, sowsSize, silosesEnabled,
            climatesEnabled, cagesEnabled, dispensersEnabled, dispensers5GEnabled, metersEnabled,
            smallCagesEnabled, chainsEnabled, alarmsEnabled, t, dirty, submitting, sowsEnabled,
            porkersEnabled
        } = this.props;
        const {breedingFailed, breedingMessage} = this.state;
        const textSwitchOptions = this.getSwitchOptions();
        return (
            <div className="billing-edit-farm-container">
                <Header text={t("billingEditFarm.title", {name: this.getFarmName(this.props)})}/>
                <ViewContainer addPaddingForButtons>
                    <Card>
                        <form onSubmit={handleSubmit}>
                            <Row className="mb-4">
                                <Col md={2}>
                                    <Field
                                        name="showBreeding"
                                        id="showBreeding"
                                        init={true}
                                        onChange={this.onSwitchChange}
                                        component={ReduxSwitch}
                                        label={<h5>{t("breeding")}</h5>}
                                    />
                                </Col>
                            </Row>
                            {
                                showBreeding &&
                                <FormSection name={"BreedingModule"} className={"breeding-section"}>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="sowsEnabled"
                                                id="sowsEnabled"
                                                component={ReduxSwitch}
                                                label={t("animalTypes.0")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            sowsEnabled && <>
                                                <Col md={2} className="align-items-center">
                                                    <Radio onChange={this.onSowSizeChange} checked={sowsSize === 1}
                                                           label={t("addons.animalsRanges", {range: `${BreedingModule.AnimalRanges.minSmall}-${BreedingModule.AnimalRanges.maxSmall}`})}
                                                           name={"sows.size"} value={1}/>
                                                </Col>
                                                <Col md={2} className="align-items-center">
                                                    <Radio onChange={this.onSowSizeChange} checked={sowsSize === 2}
                                                           label={t("addons.animalsRanges", {range: `${BreedingModule.AnimalRanges.minMedium}-${BreedingModule.AnimalRanges.maxMedium}`})}
                                                           name={"sows.size"} value={2}/>
                                                </Col>
                                                <Col md={2} className="align-items-center">
                                                    <Radio onChange={this.onSowSizeChange} checked={sowsSize === 3}
                                                           label={t("addons.animalsRanges", {range: `> ${BreedingModule.AnimalRanges.minLarge - 1}`})}
                                                           name={"sows.size"} value={3}/>
                                                </Col>
                                                <Col>
                                                    <Field
                                                        name="sows.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="porkersEnabled"
                                                id="porkersEnabled"
                                                component={ReduxSwitch}
                                                label={t("animalTypes.3")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            porkersEnabled && <>
                                                <Col>
                                                    <Field
                                                        name="porkers.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </FormSection>
                            }
                            <hr/>
                            <Row className="mb-4">
                                <Col md={2}>
                                    <Field
                                        name="showDevices"
                                        id="showDevices"
                                        component={ReduxSwitch}
                                        onChange={this.onSwitchChange}
                                        init={true}
                                        label={<h5>{t("devices")}</h5>}
                                    />
                                </Col>
                            </Row>
                            {
                                showDevices &&
                                <FormSection name={"DevicesModule"} className={"devices-section"}>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="silosesEnabled"
                                                id="silosesEnabled"
                                                component={ReduxSwitch}
                                                label={t("siloses")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            silosesEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="siloses.amount"
                                                        component={ReduxInput}
                                                        type="number"
                                                        placeholder={t("amount")}
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="siloses.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="climatesEnabled"
                                                id="climatesEnabled"
                                                component={ReduxSwitch}
                                                label={t("climates")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            climatesEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="climates.amount"
                                                        component={ReduxInput}
                                                        placeholder={t("amount")}
                                                        type="number"
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="climates.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="cagesEnabled"
                                                id="cagesEnabled"
                                                component={ReduxSwitch}
                                                label={t("cages")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            cagesEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="cages.amount"
                                                        component={ReduxInput}
                                                        placeholder={t("amount")}
                                                        type="number"
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="cages.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="dispensersEnabled"
                                                id="dispensersEnabled"
                                                component={ReduxSwitch}
                                                label={t("dispensers")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            dispensersEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="dispensers.amount"
                                                        component={ReduxInput}
                                                        type="number"
                                                        placeholder={t("amount")}
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="dispensers.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="dispensers5GEnabled"
                                                id="dispensers5GEnabled"
                                                component={ReduxSwitch}
                                                label={t("dispensers5G")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            dispensers5GEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="dispensers5G.amount"
                                                        component={ReduxInput}
                                                        type="number"
                                                        placeholder={t("amount")}
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="metersEnabled"
                                                id="metersEnabled"
                                                component={ReduxSwitch}
                                                label={t("meters")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            metersEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="meters.amount"
                                                        component={ReduxInput}
                                                        type="number"
                                                        placeholder={t("amount")}
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="meters.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="smallCagesEnabled"
                                                id="smallCagesEnabled"
                                                component={ReduxSwitch}
                                                label={t("weights")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            smallCagesEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="smallCages.amount"
                                                        component={ReduxInput}
                                                        type="number"
                                                        placeholder={t("amount")}
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="smallCages.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="chainsEnabled"
                                                id="chainsEnabled"
                                                component={ReduxSwitch}
                                                label={t("chains")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            chainsEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="chains.amount"
                                                        component={ReduxInput}
                                                        type="number"
                                                        placeholder={t("amount")}
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="chains.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Field
                                                name="alarmsEnabled"
                                                id="alarmsEnabled"
                                                component={ReduxSwitch}
                                                label={t("alarms")}
                                                onChange={this.onSwitchChange}
                                            />
                                        </Col>
                                        {
                                            alarmsEnabled && <>
                                                <Col md={2}>
                                                    <Field
                                                        name="alarms.amount"
                                                        component={ReduxInput}
                                                        type="number"
                                                        placeholder={t("amount")}
                                                        parse={this.amountValueParser}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Field
                                                        name="alarms.extended"
                                                        options={textSwitchOptions}
                                                        component={ReduxTextSwitch}
                                                    />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </FormSection>
                            }
                            <hr/>
                            <Row className="mb-4">
                                <Col md={2}>
                                    <Field
                                        name="showAdministration"
                                        id="showAdministration"
                                        init={true}
                                        onChange={this.onSwitchChange}
                                        component={ReduxSwitch}
                                        label={<h5>{t("administration")}</h5>}
                                    />
                                </Col>
                            </Row>
                            {
                                showAdministration &&
                                <FormSection name={"AdministrationModule"} className={"administration-section"}>
                                    <Row>
                                        <Col md={2}>
                                            <label>{t("managersAcc")}</label>
                                        </Col>
                                        <Col md={2}>
                                            <Field
                                                name="managersAmount"
                                                component={ReduxInput}
                                                type="number"
                                                placeholder={t("amount")}
                                                parse={this.amountValueParser}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label>{t("operatorsAcc")}</label>
                                        </Col>
                                        <Col md={2}>
                                            <Field
                                                name="operatorsAmount"
                                                component={ReduxInput}
                                                type="number"
                                                placeholder={t("amount")}
                                                parse={this.amountValueParser}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label>{t("techAdvice")}</label>
                                        </Col>
                                        <Col md={2}>
                                            <Field
                                                name="techAdviceAmount"
                                                component={ReduxInput}
                                                type="number"
                                                placeholder={t("amount")}
                                                parse={this.amountValueParser}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <label>{t("techSupport")}</label>
                                        </Col>
                                        <Col md={2}>
                                            <Field
                                                name="techSupportAmount"
                                                component={ReduxInput}
                                                type="number"
                                                placeholder={t("amount")}
                                                parse={this.amountValueParser}
                                            />
                                        </Col>
                                    </Row>
                                </FormSection>
                            }
                            <ButtonGroup fixed renderInPortal={false}>
                                {
                                    breedingFailed &&
                                    <Tooltip tooltipContent={t("errors.tooMuchInUseBreeding", {used: breedingMessage})}
                                             type={"error"}>
                                        <i className="fas fa-exclamation-circle error"/>
                                    </Tooltip>
                                }
                                <Button buttonStyle={"round"} buttonColor={"success"}
                                        icon={<i className="fas fa-save"/>} disabled={!dirty || breedingFailed}
                                        isLoading={submitting}
                                />
                            </ButtonGroup>
                        </form>
                    </Card>
                </ViewContainer>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    reduxForm({
        form: FormName,
        onSubmit: submit,
        validate
    })
)(BillingEditFarm);
