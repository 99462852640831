import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import settingsDB from "../../../database/settingsDB";
import FarmMap from "../../../components/new-farm-map/FarmMap";
import {parseFarmMapFromSVG} from "../../../utils/FarmMapUtils";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import Button from "../../../components/basics/button/Button";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import "./_location-chooser.scss"
import TransferStepper from "../events/transfer/TransferStepper";
import InsertionStepper from "../insertion/InsertionStepper";
import queryString from "query-string"
import {Level} from "../../../constans/levelTypes";
import SeparationToPorkhouseStepper from "../events/separation-to-porkhouse/SeparationToPorkhouseStepper";
import {Col, Row} from "react-bootstrap";
import LabeledSelect from "../../../components/basics/select/labeled-select/LabeledSelect";
import buildingsDB from "../../../database/buildingsDB";
import animalsDB from "../../../database/animalsDB";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class LocationChooser extends Component {

    state = {
        farmMap: settingsDB.getFarmMap(this.props.farm),
        level: 0,
        chambers: buildingsDB.getAllChambers(this.props.farm).map(chamber => ({
            name: animalsDB.getAnimalLocationsByPlcmntID(chamber.CID, {nameDeep: 3})[0].name,
            value: chamber
        })),
        sectors: buildingsDB.getAllSectorsForFarm(this.props.farm).map(sector => ({
            name: animalsDB.getAnimalLocationsByPlcmntID(sector.SID, {nameDeep: 2})[0].name,
            value: sector
        })),
        selectValue: null
    }

    hasManyLevels() {
        const {farmMap} = this.state;
        return Object.keys(farmMap.SetData.Levels).length > 1;
    }

    getMinLevel() {
        const {farmMap} = this.state;
        let levels = Object.keys(farmMap.SetData.Levels).map(key => +key);
        return Math.min(...levels);
    }

    getMaxLevel() {
        const {farmMap} = this.state;
        let levels = Object.keys(farmMap.SetData.Levels).map(key => +key);
        return Math.max(...levels);
    }

    goUp = () => {
        this.setState(state => ({
            level: state.level + 1
        }));
    }

    goDown = () => {
        this.setState(state => ({
            level: state.level - 1
        }));
    }

    onLocationClick = data => {
        console.log(data);
        if (data.placement) {
            const {location: {search}, match: {params: {nextEvent}}, farm} = this.props;
            let parsed = queryString.parse(search);
            parsed.sectors = parsed.sectors === "true";
            let path = `/${farm}/eventChooser/${nextEvent}/${data.placement.id}`;
            if (parsed.redirect) {
                path += parsed.redirect;
            }
            if ((parsed.sectors && data.placement.level === Level.SECTOR) || data.placement.level === Level.CHAMBER) {
                this.props.history.push(path);
            }
        }
    }

    onChange = value => {
        this.setState({
            selectValue: value
        })
    }

    onAcceptClick = () => {
        const {selectValue} = this.state;
        const {location: {search}, match: {params: {nextEvent}}, farm} = this.props;
        let parsed = queryString.parse(search);
        parsed.sectors = parsed.sectors === "true";
        let path = `/${farm}/eventChooser/${nextEvent}/${parsed.sectors ? selectValue.SID : selectValue.CID}`;
        if (parsed.redirect) {
            path += parsed.redirect;
        }
        this.props.history.push(path);
    }

    render() {
        const {farmMap, level, chambers, sectors, selectValue} = this.state;
        const {t, match: {params: {nextEvent}}, location: {search}} = this.props;
        let parsed = queryString.parse(search);
        parsed.sectors = parsed.sectors === "true";
        return (
            <div className="location-chooser">
                {
                    nextEvent === "transfer" &&
                    <TransferStepper activeStep={2}/>
                }
                {
                    nextEvent === "insertion" &&
                    <InsertionStepper activeStep={3}/>
                }
                {
                    nextEvent === "separationToPigletHouse" &&
                    <SeparationToPorkhouseStepper activeStep={2}/>
                }
                {
                    farmMap &&
                    <div className="farm-map-container">
                        <FarmMap farmMap={parseFarmMapFromSVG(farmMap.SetData.Levels[level])} key={level}
                                 onElementClick={this.onLocationClick} showOnly={parsed.sectors ? "sectors" : null}
                                 calculateHeight={false}/>
                        {
                            this.hasManyLevels() &&
                            <ButtonGroup fixed renderInPortal={false}>
                                <InfoBox>
                                    {t("farms.tabs.mapTab.level")} {level}
                                </InfoBox>
                                <Button type={"button"} icon={<i className="fas fa-arrow-up"/>}
                                        disabled={level === this.getMaxLevel()} buttonColor={"info"}
                                        buttonStyle={"round"} onClick={this.goUp}/>
                                < Button type={"button"} icon={<i className="fas fa-arrow-down"/>}
                                         disabled={level === this.getMinLevel()} buttonColor={"info"}
                                         buttonStyle={"round"} onClick={this.goDown}/>

                            </ButtonGroup>
                        }
                    </div>
                }
                {
                    !farmMap &&
                    <>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <LabeledSelect
                                    label={parsed.sectors ? t("newSettings.buildings.sector") : t("newSettings.buildings.chamber")}
                                    options={parsed.sectors ? sectors : chambers} onChange={this.onChange}
                                    value={selectValue}/>
                            </Col>
                        </Row>
                        <ButtonGroup fixed renderInPortal={false}>
                            <Button icon={<i className="fas fa-check"/>} disabled={!selectValue} buttonColor={"success"}
                                    buttonStyle={"round"} onClick={this.onAcceptClick}/>
                        </ButtonGroup>
                    </>
                }
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(LocationChooser);
