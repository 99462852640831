import React from "react";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {connect} from "react-redux";
import TableGrid from "../../components/basics/table-grid/TableGrid";
import Card from "../../components/basics/card/Card";
import {EventTypes} from "../../constans/eventTypes";
import "./_last-scanned-rifd.scss"
import moment from "moment";
import {getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {TreatmentTypes} from "../../constans/treatmentTypes";
import _ from "lodash";
import {FeedingCurveType} from "../../constans/feedingTypes";
import MobileLastScannedRFIDRow from "./MobileLastScannedRFIDRow";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class LastScannedRFIDView extends React.Component {

    dateValueConverter = value => {
        return moment(value).format("DD.MM.YYYY HH:mm");
    };

    typeValueFormatter = value => {
        const {t} = this.props;
        switch (value) {
            case "documents":
                return t("lastScannedRFID.documents");
            case "feeding":
                return t("lastScannedRFID.feeding");
            case EventTypes.ACTIVE_NIPPLES:
                return t("lastScannedRFID.activeNipples");
            case EventTypes.INSEMINATION:
                return t("lastScannedRFID.insemination");
            case EventTypes.TRANSFER:
                return t("lastScannedRFID.transfer");
            case EventTypes.WEIGHTING:
                return t("lastScannedRFID.weighting");
            case EventTypes.SELL:
                return t("lastScannedRFID.sell");
            case EventTypes.FALL:
                return t("lastScannedRFID.fall");
            case EventTypes.FALL_PIGLETS:
                return t("lastScannedRFID.fallPiglets");
            case EventTypes.GRAFTING:
                return t("lastScannedRFID.grafting");
            case EventTypes.MOMMY:
                return t("lastScannedRFID.mommy");
            case EventTypes.NO_PREGNANCY:
                return t("lastScannedRFID.noPregnancy");
            case EventTypes.PARTURITION:
                return t("lastScannedRFID.parturition");
            case EventTypes.SELECTION:
                return t("lastScannedRFID.selection");
            case EventTypes.SEPARATION:
                return t("lastScannedRFID.separation1");
            case EventTypes.SEPARATION_TO_MOMMY:
                return t("lastScannedRFID.separation2");
            case EventTypes.TREATMENT:
                return t("scheduleOfTreatment");
            case EventTypes.USG:
                return t("lastScannedRFID.usg");
            default:
                return value;
        }
    };

    getFallReason(value) {
        const {fallReasons: {WData}, lang} = this.props;
        let reason = WData[lang].find(item => item.ID === value);
        if (reason) return reason.Value;
    }

    getMedicine(value) {
        const {medicines} = this.props;
        let medicine = medicines.find(item => item.WordID === value);
        if (medicine) return medicine.Value;
    }

    getNoPregnancyReason(value) {
        const {noPregnancyReason: {WData}, lang} = this.props;
        let reason = WData[lang].find(item => item.ID === value);
        if (reason) return reason.Value;
    }

    getSelectionReason(value) {
        const {selectionReasons: {WData}, lang} = this.props;
        let reason = WData[lang].find(item => item.ID === value);
        if (reason) return reason.Value;
    }

    getClient(value) {
        const {clients: {WData}, lang} = this.props;
        let client = WData[lang].find(item => item.ID === value);
        if (client) return client.Value;
    }

    getWeightingReason(value) {
        const {weighting: {WData}, lang} = this.props;
        let reason = WData[lang].find(item => item.ID === value);
        if (reason) return reason.Value;
    }

    getDataComponent = props => {
        const {t} = this.props;
        console.log(props);
        switch (props.value.type) {
            case EventTypes.ACTIVE_NIPPLES:
                return (
                    <div>
                        {t("activeNipplesAmount")}: {props.value.data.activeNipples}
                    </div>
                );
            case EventTypes.FALL:
                return (
                    <div>
                        <div>
                            {t("reason")}: {this.getFallReason(props.value.data.reason)}
                        </div>
                        <div>
                            {t("weight")}: {+props.value.data.weight} {getUnit("weight", UnitTypes.MEDIUM)}
                        </div>
                    </div>
                );
            case EventTypes.FALL_PIGLETS:
                return (
                    <div>
                        <div>
                            {t("reason")}: {this.getFallReason(props.value.data.reason)}
                        </div>
                        <div>
                            {t("weight")}: {+props.value.data.weight} {getUnit("weight", UnitTypes.MEDIUM)}
                        </div>
                        <div>
                            {t("pigletsAmount")}: {+props.value.data.pigletsCount}
                        </div>
                    </div>
                );
            case EventTypes.GRAFTING:
                return (
                    <div>
                        <div>
                            {t("medicine")}: {this.getMedicine(props.value.data.medicine)}
                        </div>
                        <div>
                            {t("reason")}: {props.value.data.reason}
                        </div>
                        <div>
                            {t("weight")}: {props.value.data.weight} {getUnit("weight", UnitTypes.MEDIUM)}
                        </div>
                        <div>
                            {t("graftingType")}: {props.value.data.graftingType}
                        </div>
                        {
                            props.value.data.graftingType !== TreatmentTypes.GRAFTING &&
                            <>
                                <div>
                                    {t("lastScannedRFID.startDay")}: {props.value.data.startDate}
                                </div>
                                <div>
                                    {t("lastScannedRFID.endDay")}: {props.value.data.endDate}
                                </div>
                            </>
                        }
                    </div>
                );
            case EventTypes.INSEMINATION:
                return (
                    <div>
                        {t("boar")}: {props.value.data.boar.AnmNo1}
                    </div>
                );
            case EventTypes.MOMMY:
                return (
                    <div>
                        {t("pigletsAmount")}: {props.value.data.pigletsCount}
                    </div>
                );
            case EventTypes.NO_PREGNANCY:
                return (
                    <div>
                        {t("reason")}: {this.getNoPregnancyReason(props.value.data.reason)}
                    </div>
                );
            case EventTypes.PARTURITION:
                return (
                    <div>
                        <div>
                            {t("alive")}: {props.value.data.alive}
                        </div>
                        <div>
                            {t("dead")}: {props.value.data.dead}
                        </div>
                        <div>
                            {t("mummies")}: {props.value.data.mummy}
                        </div>
                    </div>
                );
            case EventTypes.SELECTION:
                return (
                    <div>
                        {t("reason")}: {this.getSelectionReason(props.value.data.reason)}
                    </div>
                );
            case EventTypes.SELL:
                return (
                    <div>
                        <div>
                            {t("client")}: {this.getClient(props.value.data.client)}
                        </div>
                        <div>
                            {i18next.t("lastScannedRFID.priceFor", {unit: getUnit("weight", UnitTypes.MEDIUM)})}: {props.value.data.price || "-"}
                        </div>
                        <div>
                            {t("weight")}: {props.value.data.weight} {getUnit("weight", UnitTypes.MEDIUM)}
                        </div>
                    </div>
                );
            case EventTypes.SEPARATION_TO_MOMMY:
                return (
                    <div>
                        <div>
                            {t("weight")}: {props.value.data.weight} {getUnit("weight", UnitTypes.MEDIUM)}
                        </div>
                        <div>
                            {t("pigletsAmount")}: {props.value.data.pigletsCount}
                        </div>
                    </div>
                );
            case EventTypes.SEPARATION:
                return (
                    <div>
                        {
                            props.value.data.addToExistingGroup &&
                            <>
                                {t("lastScannedRFID.assignToExistingGroup")}: {props.value.data.existingGroup.AnmNo1}
                            </>
                        }
                        {
                            !props.value.data.addToExistingGroup &&
                            <>
                                {
                                    props.value.data.createNewGroup &&
                                    <>
                                        <div>
                                            {t("newGroupNumber")}: {props.value.data.newGroupNumber}
                                        </div>
                                    </>
                                }
                                {
                                    !props.value.data.createNewGroup &&
                                    <>
                                        <div>
                                            {t("lastScannedRFID.group")}: {props.value.data.selectedGroup.AnmNo1}
                                        </div>
                                    </>
                                }
                                <div>
                                    {t("separationTo")}: {props.value.data.separationTo.CName}
                                </div>
                            </>
                        }
                    </div>
                );
            case EventTypes.TRANSFER:
                return (
                    <div>
                        <div>
                            {t("transferTo")}: {_.get(props.value.data.location.object, props.value.data.location.name)}
                        </div>
                        {
                            props.value.data.stand &&
                            <div>
                                {t("standing")}: {props.value.data.stand.BoxesName}
                            </div>
                        }
                        {
                            props.value.data.curve &&
                            <>
                                <div>
                                    {t("feedingCurve")}: {props.value.data.curve.SetData.Name}
                                </div>
                                <div>
                                    {[FeedingCurveType.MIXED_PARTURITION, FeedingCurveType.BEFORE_PARTURITION].includes(props.value.data.curve.SetData.Type) ? t("plannedParturitionDay") : t("lastScannedRFID.curveStart")}: {moment(props.value.data.curveDate).format("DD.MM.YYYY")}
                                </div>
                            </>
                        }
                    </div>
                );
            case EventTypes.TREATMENT:
                return (
                    <div>
                        {
                            props.value.data.treatment.map((tr, index) =>
                                <div>
                                    <div>
                                        {index + 1}. {t("medicines")}: {tr.medicines.map(med => med.WData.Name).join(", ")}
                                    </div>
                                    <div>
                                        {t("lastScannedRFID.days")}: {tr.days.map(day => day.format("DD.MM.YYYY")).join(", ")}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                );
            case EventTypes.USG:
                return (
                    <div>
                        {t("lastScannedRFID.pregnancy")}: <i
                        className={props.value.data.pregnant ? "fas fa-check" : "fas fa-times"}/>
                    </div>
                );
            case EventTypes.WEIGHTING:
                return (
                    <div>
                        <div>
                            {t("reason")}: {this.getWeightingReason(props.value.data.reason)}
                        </div>
                        <div>
                            {t("weight")}: {props.value.data.weight} {getUnit("weight", UnitTypes.MEDIUM)}
                        </div>
                    </div>
                )
            default:
                return null;
        }
    }

    render() {
        const {rfidScanned, t} = this.props;
        const headers = [
            {
                name: t("date"),
                field: "date",
                valueFormatter: this.dateValueConverter
            },
            {
                name: t("lastScannedRFID.animalNo"),
                field: "animal.AnmNo1"
            },
            {
                name: t("rfidNumber"),
                field: "RFID"
            },
            {
                name: t("lastScannedRFID.type"),
                field: "type",
                valueFormatter: this.typeValueFormatter
            },
            {
                name: t("lastScannedRFID.eventData"),
                component: this.getDataComponent
            }
        ];
        return (
            <div className="last-scanned-rfid">
                <Header text={t("lastScannedRFID.title")}/>
                <ViewContainer>
                    <Card>
                        <TableGrid data={rfidScanned} headers={headers}
                                   mobileRow={<MobileLastScannedRFIDRow getData={this.getDataComponent}
                                                                        getType={this.typeValueFormatter}/>}/>
                    </Card>
                </ViewContainer>
            </div>
        );
    }

}

LastScannedRFIDView = connect(state => ({
    rfidScanned: state.rfid.rfidScanned,
    fallReasons: state.dictionary.fallReasons,
    lang: state.language.lang.lang,
    medicines: state.dictionary.medicine,
    noPregnancyReason: state.dictionary.noPreganancy,
    selectionReasons: state.dictionary.selectionReason,
    clients: state.dictionary.clients,
    weighting: state.dictionary.weighting,
}))(LastScannedRFIDView);

export default withTranslation()(LastScannedRFIDView);