import React from "react";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";
import {AnimalTypes} from "../../constans/animalTypes";
import Button from "../../components/basics/button/Button";
import _ from "lodash";
import ScreenSize from "../../components/basics/screen-size/ScreenSize";
import {BreakPoint} from "../../constans/breakpointTypes";
import {getEventsList} from "../../utils/EventUtils";
import EventsPicker from "./EventsPicker";
import {withTranslation} from "react-i18next";


export default class Events extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            animalType: null
        }
    }

    onChangeAnimalType = type => {
        this.setState({
            animalType: type
        });
    };

    renderAnimalButton = (animalType) => {
        const {t} = this.props;
        const anim = Object.keys(AnimalTypes).filter(o => AnimalTypes[o] === animalType)[0].toLowerCase().replace("_", "");
        return (
            <Button onClick={() => this.onChangeAnimalType(animalType)} selected={this.state.animalType === animalType}
                    type={"button"}>{t(`events.animalKindChooser.${anim}`)}</Button>
        )
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(nextProps, this.props)) {
            return true
        }
        return !_.isEqual(nextState, this.state);
    }

    render() {
        const {animalType} = this.state;
        const {t} = this.props;
        const options = getEventsList(t).filter(o => _.isNil(animalType) || o.eligibleAnimal.includes(animalType));
        const buttons = <>
            <Button onClick={() => this.onChangeAnimalType(null)} selected={_.isNil(this.state.animalType)}
                    type={"button"}>{t("all")}</Button>

            {
                Object.values(AnimalTypes).map(o => ![AnimalTypes.PIG].includes(o) && this.renderAnimalButton(o))
            }
        </>;
        return (
            <div className="events">
                <ScreenSize>
                    {
                        ({windowWidth}) => (
                            windowWidth >= BreakPoint.SM ?
                                <ButtonGroup className={"w-100 d-flex justify-content-center"}>
                                    {buttons}
                                </ButtonGroup> : buttons
                        )
                    }
                </ScreenSize>
                <EventsPicker options={options}/>
            </div>
        );
    }

}

Events = withTranslation()(Events);
