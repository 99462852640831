import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Stepper from "../../../../components/basics/stepper/Stepper";
import Step from "../../../../components/basics/stepper/Step";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class SeparationToPorkhouseStepper extends Component {

    onClickStep = step => {
        const {farm} = this.props;
        switch (step) {
            case 1:
                this.props.history.push(`/${farm}/eventChooser`);
                break;
            case 2:
                this.props.history.push(`/${farm}/eventChooser/locationChooser/separationToPigletHouse`);
                break;
            default:
                return;
        }
    };

    render() {
        const {activeStep, t} = this.props;
        return (
            <Stepper activeStep={activeStep} onClickStep={this.onClickStep}>
                <Step label={t("events.stepper.selectEventType")}/>
                <Step label={t("events.stepper.chooseLocation")}/>
                <Step label={t("events.stepper.insertEventData")}/>
            </Stepper>
        );
    }
}

SeparationToPorkhouseStepper.propTypes = {
    activeStep: PropTypes.number.isRequired
};

export default compose(
    withTranslation(),
    withRouter,
    connect(mapStateToProps)
)(SeparationToPorkhouseStepper);
