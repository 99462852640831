import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types";
import {
    animalField, checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getAnimals,
    insertComment,
    insertData,
    insertOperator,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import {Formik} from "formik";
import FormForEvents from "../FormForEvents";
import {isEqual} from "lodash";
import animalsDB from "../../../../database/animalsDB";
import devicesDB from "../../../../database/devicesDB";
import {DevType} from "../../../../constans/devices";
import formDataDB from "../../../../database/formDataDB";
import {withRouter} from "react-router-dom";
import {eventChooserFormNames} from "../../../../constans/formNames";
import TransferStandComponent from "./transfer-stand-component/TransferStandComponent";
import {transferSubmit} from "./TransferSubmit";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class TransferStand extends Component {
    state = {
        animals: [],
        initialData: {
            data: []
        }
    }

    formName = eventChooserFormNames.TRANSFER_STAND + "_" + this.props.match.params.locationID;

    componentDidMount() {
        const {farm} = this.props;
        let sows = getAnimals(farm);

        this.setState({
            animals: getAnimalOptions(sows),
            initialData: this.getInitialData()
        });
    }

    getInitialData() {
        const {placement, initialData} = this.props;
        let init = initialData;
        if (!init) {
            let savedData = formDataDB.getSavedData(this.formName);
            if (savedData) {
                init = savedData.data;
                // wstawianie pustych stanowisk
                let tmp = placement.Boxes.map(box => {
                    let animalInStand = animalsDB.getAllAnimalsByPlcmntID(box.BID, {joinEvents: false})[0];
                    return {
                        stand: box,
                        animalInStand
                    }
                });
                init.data = tmp.map(row => {
                    let foundRow = init.data.find(item => item.stand.BID === row.stand.BID);
                    if (foundRow) return {...foundRow, animalInStand: row.animalInStand};
                    return row;
                })
            } else {
                init = {
                    data: placement.Boxes.map(box => {
                        let animalInStand = animalsDB.getAllAnimalsByPlcmntID(box.BID, {joinEvents: false})[0];
                        return {
                            stand: box,
                            animalInStand
                        }
                    })
                }
            }
        } else {
            // wstawianie pustych stanowisk
            let tmp = placement.Boxes.map(box => {
                let animalInStand = animalsDB.getAllAnimalsByPlcmntID(box.BID, {joinEvents: false})[0];
                return {
                    stand: box,
                    animalInStand
                }
            });
            init.data = tmp.map(row => {
                let foundRow = init.data.find(item => item.stand.BID === row.stand.BID);
                if (foundRow) return {...foundRow, animalInStand: row.animalInStand};
                return row;
            })
        }
        return init;
    }

    checkIfOnlyStand = row => {
        let keys = Object.keys(row).filter(key => row[key]);
        if (row.animalInStand) return keys.length !== 2;
        return keys.length !== 1;
    }

    onFiltersChange = (filters, value, oldFilters) => {
        if (filters.hasOwnProperty("animal")) {
            const {animal: group} = filters;
            const {animal: oldGroup} = oldFilters;
            if (!isEqual(group, oldGroup)) {
                if (group) {
                    let animals = group.AnmList
                        .filter(AnmID => !group.Rmvd.includes(AnmID))
                        .map(AnmID => animalsDB.getAnimalById(AnmID, {joinEvents: false}))
                        .filter(item => item);
                    animals.sort((a, b) => a.AnmNo1 && a.AnmNo1.localeCompare(b.AnmNo1));
                    value = value.map(row => {
                        let animal = animals.shift();
                        return {
                            stand: row.stand,
                            animal: animal || null,
                            animalInStand: row.animalInStand
                        };
                    });
                } else {
                    value = value.map(row => {
                        return {
                            ...row,
                            animal: null
                        };
                    });
                }
            }
        }
        if (filters.hasOwnProperty("date")) {
            const {date} = filters;
            value = insertData(date, "date", value, oldFilters.date, this.checkIfOnlyStand);
        }
        value = insertOperator(filters, value, oldFilters.operator, this.checkIfOnlyStand);
        value = insertComment(filters, value, oldFilters.comment, this.checkIfOnlyStand);

        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            animals: getAnimalOptions(getAnimals(farm, animalsAlreadySelected))
        })
    }

    standFormatter = (props) => <TransferStandComponent {...props}/>;

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const errors = {};
                if (row.animalInStand && row.animal && row.animalInStand.AnmID === row.animal.AnmID) {
                    errors.animal = t("errors.alreadyInLocation");
                }
                return errors;
            }, ["stand", "device", "animalInStand"])
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values, bag) => {
        if (this.props.hasFeeding) {
            let data = values.data.map(row => {
                if (row.animal) {
                    let dispenser = devicesDB.getDevicesInPlcmntID(row.stand.BID).filter(item => [DevType.DISPENSER, DevType.DISPENSER_NRF].includes(item.DevType))[0];
                    return {
                        ...row,
                        dispenser
                    }
                }
                return null;
            });
            data = data.filter(item => !!item);
            this.props.onSubmit({data});
        } else {
            return transferSubmit(values, {...this.props, showBoxes: true}, this.api)
        }
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: this.getInitialData()
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {animals, initialData} = this.state;
        const {t, hasFeeding} = this.props;
        const columns = [
            {
                name: t("standing"),
                key: "stand",
                editable: false,
                formatter: this.standFormatter,
                noClearing: true
            },
            animalField(animals),
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={initialData}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange} disableAddingNewRow
                                       onDataChange={this.onDataChange} formName={this.formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} hasNext={hasFeeding} getApi={this.getApi}
                                       ignoreFields={["stand", "device", "animalInStand"]} isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }
}

TransferStand.propTypes = {
    placement: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialData: PropTypes.shape({
        data: PropTypes.array,
    }),
    hasFeeding: PropTypes.bool,
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter
)(TransferStand);