import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormForEvents from "../FormForEvents";
import {
    animalField,
    calculateGridRowsByHeight, checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getInitialValues,
    getSowsAndRenovationSows,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Formik} from "formik";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {createHeat, createHeatNotificationFailure, createHeatNotificationSuccess} from "../../../../api/events/Heat";
import {eventChooserFormNames} from "../../../../constans/formNames";

const formName = eventChooserFormNames.HEAT;

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

class Heat extends Component {

    state = {
        sows: [],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getSowsAndRenovationSows(farm);
        this.setState({
            sows: getAnimalOptions(sows),
            initialData: getInitialValues(formName)
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);

        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            sows: getAnimalOptions(getSowsAndRenovationSows(farm, animalsAlreadySelected))
        })
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;

        if (data) {
            errors.data = validateGrid(data, row => {
                return {};
            })
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment} = row;
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment
                })
            }
        }
        return createHeat(data).then(res => {
            createHeatNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createHeatNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {sows, initialData} = this.state;
        const columns = [
            animalField(sows),
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} isSubmitting={isSubmitting} getApi={this.getApi}/>
                    )
                }
            </Formik>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Heat);