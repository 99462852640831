import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";
import "./_animal-state-header.scss";

class PigletsAddedHeader extends Component {
    render() {
        const {column: {name}, separatedToMommy, t} = this.props;
        return (
            <div className="animal-state-header">
                {name}
                <Tooltip tooltipContent={t("pigletsSeparatedToMommy", {count: separatedToMommy})}>
                    <i className="fas fa-question-circle"/>
                </Tooltip>
            </div>
        );
    }
}

export default withTranslation()(PigletsAddedHeader);