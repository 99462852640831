import React from 'react';
import PropTypes from "prop-types";
import {change} from 'redux-form'
import {connect} from "react-redux";
import Input from "../../../components/basics/input/Input";
import _ from "lodash";
import moment from "moment";
import {withTranslation} from "react-i18next";


export class MultiValuesComponent extends React.Component {
    constructor(props) {
        super(props);
        const {form, type} = this.props;
        this.state = {
            fields: _.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.multiValues`, []),
        };
        const {fields} = this.state;
        if (["date", "datetime-local"].includes(type)) {
            fields.startDate = (type === "date" ? moment().format("YYYY-MM-DD") : moment().format("YYYY-MM-DDTHH:mm"))
        }
        this.props.dispatch(change(`${Object.keys(form)[Object.keys(form).length - 1]}`, `multiValues`, fields));

    };

    onChangeAll() {
        const {form} = this.props;
        const {fields} = this.state;
        this.calculateDate();
        for (let x = 0; x < _.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.animals`, []).length; x++) {
            this.props.dispatch(change(`${Object.keys(form)[Object.keys(form).length - 1]}`, `animals[${x}]`, {..._.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.animals`, [])[x], ...fields}));
        }
    };

    calculateDate = () => {
        const {fields} = this.state;
        const {form, field} = this.props;
        if (fields.startDate && fields.endDate && field === 'endDate' || field === 'startDate') {
            let startDate = moment(_.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.multiValues`, []).startDate);
            let endDate = moment(_.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.multiValues`, []).endDate);
            fields.numberOfDays = endDate.diff(startDate, "days") + 1;
        }
        if (fields.numberOfDays && fields.startDate && field === 'numberOfDays' || field === 'startDate') {
            let startDate = moment(_.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.multiValues`, []).startDate);
            let endDate = startDate.clone().add(+_.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.multiValues`, []).numberOfDays - 1, "days");
            fields.endDate = endDate.format("YYYY-MM-DD");
        }
    };

    onChange = (e)=>{
        const {field, form} = this.props;
        const {fields} = this.state;
        fields[field] = e;
        this.props.dispatch(change(`${Object.keys(form)[Object.keys(form).length - 1]}`, `multiValues`, fields));
    };

    render() {
        const {t, unit, type, field, form} = this.props;

        return (
            <div className="table-item" style={{display: "flow-root"}}>
                <div className="fetura-input"><Input type={type} placeholder={t("events.multiValue.addToEveryone")}
                                                     onBlur={this.onChangeAll.bind(this)}
                                                     onChange={this.onChange}
                                                     value={_.get(form, `${Object.keys(form)[Object.keys(form).length - 1]}.values.multiValues`, [])[field]}/>
                                                          {unit ?
                    <span>{unit}</span> : null}</div>
            </div>
        );

    }
}

MultiValuesComponent = connect(state => ({
    form: state.form,
}))(MultiValuesComponent);


MultiValuesComponent.propTypes = {
    unit: PropTypes.string,
    field: PropTypes.string.isRequired
};

MultiValuesComponent.defaultProps = {
    unit: null
};

export default withTranslation()(MultiValuesComponent);



