import {parsePhoneNumberFromString} from "libphonenumber-js";
import {isEmpty} from "lodash";
import {createUser, createUserNotificationFailure, createUserNotificationSuccess} from "../../../api/user/create";
import {ModalName as SelectDefaultFarmModalName} from "../../../components/modals-new/select-default-farm-modal/SelectDefaultFarmModal";
import {redirect} from "../../../actions/historyActions";

export function submit(values, dispatch, props) {
    const {show, farms} = props;
    const {roles} = values;
    if (roles[0].LocalRights.length > 1) {
        const filteredFarms = farms.filter((farm) => roles[0].LocalRights.find((lr) => lr.FarmID === farm.FarmID));
        show(SelectDefaultFarmModalName, {farms: filteredFarms, userValues: values});
    } else {
        return createUser({...values, defaultFarm: roles[0].LocalRights[0].FarmID}).then((res) => {
            createUserNotificationSuccess(res);
            dispatch(redirect("/farmSettings/users"))
        }).catch((err) => {
            createUserNotificationFailure(err);
        });
    }
}

export function validate(values, props) {
    const errors = {};
    const {email, farm, userName, userLogin, userType, roles} = values;
    const {t} = props;
    if (isEmpty(roles)) {
        errors.roles = t("required");
    }
    if (!email) {
        errors.email = t("required");
    }
    if (!farm) {
        errors.farm = t("required");
    }
    if (!userName) {
        errors.userName = t("required");
    }
    if (!userLogin) {
        errors.userLogin = t("required");
    }
    if (!userType) {
        errors.userType = t("required");
    }

    let phoneNumber = parsePhoneNumberFromString(values.phone || "");

    if (values.phone) {
        if (values.phone && values.phone.charAt(0) !== "+") {
            errors.phone = t("errors.phoneNumberError");
        } else {
            if (!phoneNumber || !phoneNumber.isValid()) {
                errors.phone = t("errors.phoneError");
            }
        }
    }

    return errors;
}
