import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {animalFormatter, dateFormatter, dateRenderer, insertData, validateGrid} from "../../../../utils/DataGridUtils";
import {Formik} from "formik";
import FormForEvents from "../FormForEvents";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import {getFeedingCurves} from "../../../../selectors/feedingSelector";
import buildingsDB from "../../../../database/buildingsDB";
import {DevType} from "../../../../constans/devices";
import {getFirstInseminationInCycle, getPlannedParturition} from "../../../../utils/EventUtils";
import {withRouter} from "react-router-dom";
import devicesDB from "../../../../database/devicesDB";
import {findLastIndex, isNil} from "lodash";
import {eventChooserFormNames} from "../../../../constans/formNames";
import {SectorType} from "../../../../constans/sectorTypes";
import {getCurveDayShowingType} from "../../../../utils/FeedingUtils";
import moment from "moment";
import {CurveDayShowingType, CurveType} from "../../../../constans/feedingTypes";
import {getLocationID} from "../../../../utils/BuildingUtils";
import {getDefaultCurve} from "../../../../utils/SettingsUtils";
import eventsDB from "../../../../database/eventsDB";
import {EventTypes} from "../../../../constans/eventTypes";
import {transferSubmit} from "./TransferSubmit";
import TooltipCell from "../../../../components/basics/table-input/cell/TooltipCell";

function mapStateToProps(state, props) {
    let tree = buildingsDB.getTreeByLocationID(getLocationID(props.location));
    if (tree.sector.SType === SectorType.MATING) {
        return {
            curves: getFeedingCurves(state)
                .filter(curve => (curve.SetData.Type === CurveType.MATING && getCurveDayShowingType(curve) === CurveDayShowingType.BOTH) || curve.SetData.Type === CurveType.INDIVIDUAL)
        }
    } else if (tree.sector.SType === SectorType.SOWS) {
        return {
            curves: getFeedingCurves(state)
                .filter(curve => curve.SetData.Type === CurveType.MATING && (getCurveDayShowingType(curve) === CurveDayShowingType.BOTH || getCurveDayShowingType(curve) === CurveDayShowingType.AFTER))
        }
    } else if (tree.sector.SType === SectorType.DELIVERY) {
        return {
            curves: getFeedingCurves(state)
                .filter(curve => curve.SetData.Type === CurveType.PARTURITION || curve.SetData.Type === CurveType.INDIVIDUAL)
        }
    }
    return {
        curves: getFeedingCurves(state).filter(curve => curve.SetData.Type === CurveType.INDIVIDUAL)
    };
}

class TransferFeeding extends Component {
    state = {
        initialData: {data: []},
        sectorType: null
    }
    formName = (this.props.showBoxes ? eventChooserFormNames.TRANSFER_STAND : eventChooserFormNames.TRANSFER_CHAMBER) + "_" + this.props.match.params.locationID;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...this.getInitialData()
        }
    }

    getInitialData(initializeFeeding = true) {
        const {match: {params: {locationID}}, transferData, curves, showBoxes} = this.props;
        let tree = buildingsDB.getTreeByLocationID(locationID);
        let newSectorType = tree.sector.SType;
        let tmp = transferData.data.slice(0);
        if (initializeFeeding) {
            let hasFeedingInChamber = devicesDB.getDevicesInPlcmntID(locationID, {showDevicesInChildren: false}).filter(item => [DevType.DISPENSER, DevType.DISPENSER_NRF].includes(item.DevType));
            for (let row of transferData.data.slice(0)) {
                const {dispenser, animal} = row;
                let hasDevice = showBoxes ? !!dispenser : hasFeedingInChamber.length > 0;
                if (hasDevice) {
                    let currentTree = buildingsDB.getTreeByLocationID(Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID)
                    let currentAnimalSectorType = currentTree.sector ? currentTree.sector.SType : null;
                    if (currentAnimalSectorType !== newSectorType) {
                        let defaultCurveIDForSector = null;
                        if (newSectorType === SectorType.MATING) {
                            row.day = 1;
                            defaultCurveIDForSector = getDefaultCurve("Mating");
                        } else if (newSectorType === SectorType.SOWS) {
                            let firstInsemination = this.getInseminationTime(animal);
                            if (firstInsemination) {
                                row.inseminationDay = moment(firstInsemination.EvTime);
                            }
                            defaultCurveIDForSector = getDefaultCurve("Sows");
                        } else if (newSectorType === SectorType.DELIVERY) {
                            let parturitionTime = this.getPlannedParturitionTime(animal);
                            if (parturitionTime) {
                                row.plannedBirth = moment(parturitionTime);
                            }
                            defaultCurveIDForSector = getDefaultCurve("Delivery");
                        } else {
                            row.day = 1;
                            defaultCurveIDForSector = getDefaultCurve("Other");
                        }
                        row.curve = curves.find(item => item.SetID === defaultCurveIDForSector);
                        if (animal.feedParam) {
                            row.doseCorrection = animal.feedParam.percentCorrection;
                        }
                    } else if (animal.feedParam) {
                        row.curve = curves.find(item => item.SetData.Index === animal.feedParam.curveNr);
                        row.doseCorrection = animal.feedParam.percentCorrection;
                        if (tree.sector.SType === SectorType.DELIVERY) {
                            let parturitionTime = this.getPlannedParturitionTime(animal);
                            if (parturitionTime) {
                                row.plannedBirth = moment(parturitionTime);
                            }
                        } else if (tree.sector.SType === SectorType.SOWS) {
                            let firstInsemination = this.getInseminationTime(animal);
                            if (firstInsemination) {
                                row.inseminationDay = moment(firstInsemination.EvTime);
                            }
                        } else {
                            if (animal.feedParam.startTime) {
                                row.day = moment().startOf("day").diff(animal.feedParam.startTime, "days") + 1;
                            }
                        }
                    }
                }
            }
        }
        return {
            sectorType: newSectorType,
            initialData: {
                data: tmp
            }
        }
    }

    getInseminationTime(animal) {
        let events = eventsDB.getAllEvents4Animal(animal.AnmID);
        let lastParturitionIndex = findLastIndex(events, o => o.EvCode === EventTypes.PARTURITION);
        events = events.slice(lastParturitionIndex);
        return getFirstInseminationInCycle(events);
    }

    getPlannedParturitionTime(animal) {
        let events = eventsDB.getAllEvents4Animal(animal.AnmID);
        let lastParturitionIndex = findLastIndex(events, o => o.EvCode === EventTypes.PARTURITION);
        events = events.slice(lastParturitionIndex);
        return getPlannedParturition(events);
    }

    onFiltersChange = (filters, value, oldFilters) => {
        if (filters.hasOwnProperty("curve")) {
            const {curve} = filters;
            value = insertData(curve, "curve", value, oldFilters.curve, this.isFieldEditable);
        }
        if (filters.hasOwnProperty("day")) {
            const {day} = filters;
            value = insertData(day, "day", value, oldFilters.day, this.isCurveDayEditable);
        }
        if (filters.hasOwnProperty("doseCorrection")) {
            const {doseCorrection} = filters;
            value = insertData(doseCorrection, "doseCorrection", value, oldFilters.doseCorrection, this.isFieldEditable);
        }
        if (filters.hasOwnProperty("plannedBirth")) {
            const {plannedBirth} = filters;
            value = insertData(plannedBirth, "plannedBirth", value, oldFilters.plannedBirth, this.isPlannedBirthEditable);
        }
        if (filters.hasOwnProperty("inseminationDay")) {
            const {inseminationDay} = filters;
            value = insertData(inseminationDay, "inseminationDay", value, oldFilters.inseminationDay, this.isPlannedBirthEditable);
        }
        return value;
    }

    standFormatter = ({column: {key}, row}) => {
        if (row[key]) return <TooltipCell value={row[key].BoxesName}/>;
        return null;
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;
        const {sectorType} = this.state;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (this.isFieldEditable(row)) {
                    if (!row.curve) {
                        rowErrors.curve = t("required");
                    } else {
                        if (row.curve.SetData.Type === CurveType.INDIVIDUAL) {
                            if (isNil(row.day)) {
                                rowErrors.day = t("required");
                            }
                        } else {
                            if (sectorType === SectorType.DELIVERY) {
                                if (isNil(row.plannedBirth)) {
                                    rowErrors.plannedBirth = t("required");
                                }
                            } else if (sectorType === SectorType.SOWS) {
                                if (isNil(row.inseminationDay)) {
                                    rowErrors.inseminationDay = t("required");
                                }
                            }
                        }
                    }
                    if (isNil(row.doseCorrection)) {
                        rowErrors.doseCorrection = t("required");
                    }
                }

                return rowErrors;
            }, ["stand", "device", "animalInStand"])
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    curveFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key].SetData.Name;
        return null;
    }

    doseCorrectionFormatter = ({column: {key}, row}) => {
        let value = row[key];
        if (!isNil(value)) return value + "%";
        return null;
    }

    curveFilter = ({value, onChange}) => {
        const {curves, t} = this.props;
        return <Select value={value}
                       options={curves.map(curve => ({
                           name: curve.SetData.Name,
                           value: curve
                       }))}
                       onChange={onChange} placeholder={t("feedingCurve")}/>
    }

    dayFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("curveDay")}/>
    }

    doseCorrectionFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <Select value={value}
                       options={[-25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25].map(value => ({
                           name: value + "%",
                           value
                       }))}
                       onChange={onChange} placeholder={t("doseCorrection")}/>
    }

    submit = (values) => {
        return transferSubmit(values, this.props, this.api);
    }

    isFieldEditable = ({stand}) => {
        const {showBoxes, match: {params: {locationID}}} = this.props;
        let id = locationID;
        if (showBoxes) {
            id = stand.BID;
        }
        let devices = devicesDB.getDevicesInPlcmntID(id, {showDevicesInChildren: false}).filter(item => item.DevType === DevType.DISPENSER || item.DevType === DevType.DISPENSER_NRF);
        return devices.length > 0;
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: this.getInitialData(false).initialData
        })
    }

    isCurveDayEditable = (data) => {
        return this.isFieldEditable(data) && data.curve && data.curve.SetData.Type === CurveType.INDIVIDUAL;
    }

    isPlannedBirthEditable = data => {
        return this.isFieldEditable(data) && data.curve && data.curve.SetData.Type !== CurveType.INDIVIDUAL;
    }

    getDaysField() {
        const {t} = this.props;
        const {sectorType} = this.state;
        if (sectorType === SectorType.DELIVERY) {
            return [
                {
                    name: t("curveDay"),
                    key: "day",
                    filterRenderer: this.dayFilter,
                    editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}/>),
                    editable: this.isCurveDayEditable
                },
                {
                    name: t("plannedBirth"),
                    key: "plannedBirth",
                    editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
                    formatter: dateFormatter,
                    filterRenderer: dateRenderer,
                    editable: this.isPlannedBirthEditable
                }
            ]
        }
        if (sectorType === SectorType.SOWS) {
            return [
                {
                    name: t("curveDay"),
                    key: "day",
                    filterRenderer: this.dayFilter,
                    editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}/>),
                    editable: this.isCurveDayEditable
                },
                {
                    name: t("insemination"),
                    key: "inseminationDay",
                    editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
                    formatter: dateFormatter,
                    filterRenderer: dateRenderer,
                    editable: this.isPlannedBirthEditable
                }
            ]
        }
        return [{
            name: t("curveDay"),
            key: "day",
            filterRenderer: this.dayFilter,
            editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}/>),
            editable: this.isFieldEditable
        }]
    }

    getApi = api => {
        this.api = api;
    }

    customIsEmpty = row => {

    }

    render() {
        const {initialData} = this.state;
        const {curves, showBoxes, t} = this.props;
        let columns = [];
        if (showBoxes) {
            columns.push(
                {
                    name: t("standing"),
                    key: "stand",
                    editable: false,
                    formatter: this.standFormatter
                }
            )
        }
        columns.push(...[
            {
                name: t("animalNumber"),
                key: "animal",
                formatter: animalFormatter,
                editable: false
            },
            {
                name: t("feedingCurve"),
                key: "curve",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={curves.map(curve => ({
                                      name: curve.SetData.Name,
                                      value: curve
                                  }))}
                                  ref={ref}/>),
                formatter: this.curveFormatter,
                filterRenderer: this.curveFilter,
                editable: this.isFieldEditable
            },
            ...this.getDaysField(),
            {
                name: t("doseCorrection"),
                key: "doseCorrection",
                filterRenderer: this.doseCorrectionFilter,
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={[-25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25].map(value => ({
                                      name: value + "%",
                                      value
                                  }))}
                                  ref={ref}/>),
                editable: this.isFieldEditable,
                formatter: this.doseCorrectionFormatter
            },
        ])
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={initialData}
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange} formName={this.formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} ignoreFields={["stand", "device", "animalInStand"]}
                                       initialFilters={{}} disableAddingNewRow isSubmitting={isSubmitting}
                                       getApi={this.getApi}/>
                    )
                }
            </Formik>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter
)(TransferFeeding);
