import React from "react";
import "./_usg.scss"
import FormForEvents from "../FormForEvents";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import {
    animalField,
    calculateGridRowsByHeight,
    checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getInitialValues,
    getSows,
    insertData,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import Select from "../../../../components/basics/select/Select";
import {connect} from "react-redux";
import {USG_STATE} from "../../../../constans/eventTypes";
import {Formik} from "formik";
import {isNil} from "lodash";
import {createUSG, createUSGNotificationFailure, createUSGNotificationSuccess} from "../../../../api/events/USG";
import {eventChooserFormNames} from "../../../../constans/formNames";

const formName = eventChooserFormNames.USG;

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

export class USG extends React.Component {

    state = {
        sows: [],
        usgStates: [
            {name: this.props.t("events.usgEvent.negative"), value: USG_STATE.NEGATIVE},
            {name: this.props.t("events.usgEvent.positive"), value: USG_STATE.POSITIVE},
            {name: this.props.t("events.usgEvent.toRepeat"), value: USG_STATE.REPEAT}
        ],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getSows(farm);
        this.setState({
            sows: getAnimalOptions(sows),
            initialData: getInitialValues(formName)
        });
    }

    resultSelect = ({value, onChange}) => {
        const {usgStates} = this.state;
        const {t} = this.props;
        return <Select value={value} options={usgStates} onChange={onChange} placeholder={t("testResult")}/>
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("result")) {
            const {result} = filters;
            value = insertData(result, "result", value, oldFilters.result);
        }
        return value;
    }

    usgResultFormatter = ({column: {key}, row}) => {
        const {t} = this.props;
        switch (row[key]) {
            case USG_STATE.NEGATIVE:
                return <span className="usg-result">{t("events.usgEvent.negative")} <i className="fas fa-times fa-fw"/></span>;
            case USG_STATE.POSITIVE:
                return <span className="usg-result">{t("events.usgEvent.positive")} <i className="fas fa-check fa-fw"/></span>;
            case USG_STATE.REPEAT:
                return <span className="usg-result">{t("events.usgEvent.toRepeat")} <i
                    className="fas fa-redo fa-fw"/></span>;
            default:
                return "";
        }
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            sows: getAnimalOptions(getSows(farm, animalsAlreadySelected))
        })
    }

    validate = (values) => {
        const errors = {};

        const {data} = values;
        const {t} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};
                if (isNil(row.result)) {
                    rowErrors.result = t("required");
                }
                return rowErrors;
            })
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, result} = row;
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    Comment: comment,
                    Pregnant: result
                })
            }
        }
        return createUSG(data).then(res => {
            createUSGNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createUSGNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {sows, usgStates, initialData} = this.state;
        const {t} = this.props;
        const columns = [
            animalField(sows),
            {
                name: t("testResult"),
                key: "result",
                editor: React.forwardRef((props, ref) => <SelectEditor {...props} options={usgStates} ref={ref}/>),
                formatter: this.usgResultFormatter,
                filterRenderer: this.resultSelect,
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} getApi={this.getApi}
                                       isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(USG);