import React from "react";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import "./_event-chooser.scss"
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import Events from "./Events";
import Card from "../../components/basics/card/Card";
import Insemination from "./events/insemination/Insemination";
import USG from "./events/usg/USG";
import NoPregnancy from "./events/no-pregnancy/NoPregnancy";
import Parturition from "./events/parturition/Parturition";
import SeparationToPiglethouse from "./events/separation-to-porkhouse/SeparationToPiglethouse";
import SeparationToMommy from "./events/separation-to-mommy/SeparationToMommy";
import Grafting from "./events/grafting/Grafting";
import Treatment from "./events/treatment/Treatment";
import FallPiglets from "./events/fall-piglets/FallPiglets";
import Transfer from "./events/transfer/Transfer";
import Fall from "./events/fall/Fall";
import Sell from "./events/sell/Sell";
import Selection from "./events/selection/Selection";
import AnimalKindChooser from "./insertion/AnimalKindChooser";
import Insertion from "./insertion/Insertion";
import withRoles from "../../components/withRoles";
import {Roles} from "../../constans/roles";
import Weighting from "./events/weighting/Weighting";
import ActiveNipples from "./events/active-nipples/ActiveNipples";
import Reclassify from "./events/reclassify/Reclassify";
import PigletsTreatment from "./events/piglets-treatment/PigletsTreatment";
import Heat from "./events/heat/Heat";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import LocationChooser from "./location-chooser/LocationChooser";

@connect(state => ({
    farm: state.location.farm,
    animalKinds: state.language.langDynamic.animalKind
}))
export class EventChooserView extends React.Component {

    getTitle() {
        const {t, location: {pathname}} = this.props;
        let title = t("setEvent");
        let split = pathname.split("/");
        if (split[3]) {
            let text = split[3];
            if (text === "locationChooser" || text === "animalKind") {
                text = split[4];
            }
            if (text === "insertion" && split[5]) {
                title += " - " + t(text) + `(${t("animalKind." + Math.abs(+split[5]))})`;
            } else {
                title += " - " + t(text);
            }
        }
        return title;
    }

    render() {
        return (
            <div className="event-chooser">
                <Header text={this.getTitle()}/>
                <ViewContainer addPaddingForButtons>
                    <Card>
                        <Switch>
                            <Route path={`/${this.props.farm}/eventChooser/animalKind/:nextEvent`}
                                   render={(props) => <AnimalKindChooser {...props}/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/insertion/:locationID/:animalKind`}
                                   render={(props) => <Insertion {...props}/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/insemination`}
                                   render={() => <Insemination/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/usg`}
                                   render={() => <USG/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/noPregnancy`}
                                   render={() => <NoPregnancy/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/parturition`}
                                   render={() => <Parturition/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/separationToPigletHouse/:locationID`}
                                   render={props => <SeparationToPiglethouse {...props}/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/separationToMommy`}
                                   render={() => <SeparationToMommy/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/grafting`}
                                   render={() => <Grafting/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/scheduleOfTreatment`}
                                   render={() => <Treatment/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/fallPiglets`}
                                   render={() => <FallPiglets/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/transfer/:locationID`}
                                   render={(props) => <Transfer {...props}/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/fall`}
                                   render={() => <Fall/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/sale`}
                                   render={() => <Sell/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/selection`}
                                   render={() => <Selection/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/weighting`}
                                   render={() => <Weighting/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/activeNipples`}
                                   render={() => <ActiveNipples/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/reclassify`}
                                   render={() => <Reclassify/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/pigletsTreatment`}
                                   render={() => <PigletsTreatment/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/heat`}
                                   render={() => <Heat/>}/>
                            <Route path={`/${this.props.farm}/eventChooser/locationChooser/:nextEvent`}
                                   render={(props) => <LocationChooser {...props}/>}/>
                            <Route render={() => <Events/>}/>
                        </Switch>
                    </Card>
                </ViewContainer>
            </div>
        );
    }

}

export default compose(
    withRouter,
    withRoles({roles: [Roles._BREEDING], showComponent: true}),
    withTranslation()
)(EventChooserView);