import React from "react";
import {connect} from 'react-redux'
import ChooseUsers from "./ChooseUsers";
import Button from "../../../components/basics/button/Button";
import UserEditRoleModal, {ModalName as UserEditRoleModalName} from "../../../components/modals-new/user-edit-role-modal/UserEditRoleModal";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import Header from "../../../components/basics/header/Header";
import ViewContainer from "../../../components/basics/view-container/ViewContainer";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import {deleteUserAccount, enableDisableUser, getEmployees, getUser} from "../../../actions/userActions";
import Switch from "../../../components/basics/switch/Switch";
import {myID} from "../../../libs/generateID";
import {checkIfUserIsFarmAdmin, checkIfUserIsOwner, checkIfUserIsService} from "../../../utils/NewRolesUtils";
import NoRoleComponent from "../../../components/NoRoleComponent";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import * as UserTypes from "validators-schema/Api/constants/userTypes";

export class ChangeRolesView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            employee: null
        };
        this.props.dispatch(getUser(props.attributes.sub, false));
    }

    openModal = (employee, event) => {
        console.log(event);
        event.stopPropagation();
        const {show} = this.props;
        show(UserEditRoleModalName, {employee: employee})
    };

    onUnblockUserClick = (user) => {
        const {show, t} = this.props;
        show("confirm-modal", {
            title: t("newSettings.users.changeRolesView.confirmOperation"),
            text: i18next.t("newSettings.users.changeRolesView.confirmUnlock", {name: user.userName}),
            confirmText: t('yes'),
            onConfirmed: (props) => {
                this.props.dispatch(enableDisableUser(this.props.ClientID, true, user.sub, user.login, () => {
                    this.props.dispatch(getUser(this.props.attributes.sub, false));
                    this.props.dispatch(getEmployees(this.props.ClientID));
                }));
                props.handleHide();
            }
        })
    };

    onBlockUserClick = (user) => {
        const {show, t} = this.props;
        show("confirm-modal", {
            title: t("newSettings.users.changeRolesView.confirmOperation"),
            text: i18next.t("newSettings.users.changeRolesView.confirmLock", {name: user.userName}),
            confirmText: t("yes"),
            onConfirmed: (props) => {
                this.props.dispatch(enableDisableUser(this.props.ClientID, false, user.sub, user.login, () => {
                    this.props.dispatch(getUser(this.props.attributes.sub, false));
                    this.props.dispatch(getEmployees(this.props.ClientID));
                }));
                props.handleHide();
            }
        })
    };

    onDeleteUserClick = (e, user) => {
        e.stopPropagation();
        const {show, dispatch} = this.props;
        show("confirm-modal", {
            title: i18next.t("newSettings.users.changeRolesView.confirmOperation"),
            text: i18next.t("newSettings.users.changeRolesView.confirmDeletion", {name: user.userName}),
            confirmText: i18next.t("yes"),
            onConfirmed: (props) => {
                dispatch(deleteUserAccount(user.LocalUserID));
                props.handleHide();
            }
        });
    };

    onAddUserClick = () => {
        this.props.history.push("/farmSettings/users/add");
    };

    onSwitchClick = (e, props) => {
        e.stopPropagation();
        if (props.user.enable) {
            this.onBlockUserClick(props.user);
        } else {
            this.onUnblockUserClick(props.user)
        }
    };

    shouldDisableSwitch(props) {
        const {user: {userType, enable}} = props;
        if (!enable) {
            const {user: {LicPackages}} = this.props;
            if (LicPackages) {
                let adminSub = LicPackages.sort((a, b) => b.DtaModTime - a.DtaModTime).find(item => item.Type === "ADMIN");
                if (adminSub) {
                    if (userType === UserTypes.MANAGER) {
                        return adminSub.AccManagersLeft === 0;
                    } else if (userType === UserTypes.USER) {
                        return adminSub.AccOperatorsLeft === 0;
                    }
                }
            }
        }
        return false;
    }

    render() {
        if (!checkIfUserIsOwner() && !checkIfUserIsFarmAdmin() && !checkIfUserIsService()) return <NoRoleComponent/>;
        const {t} = this.props;
        const addtionalHeaders = [
            {
                name: t("phoneNumber"),
                field: "phoneNumber",
                valueFormatter: (value) => {
                    return value
                }
            },
            {
                name: t("email"),
                field: "email",
                valueFormatter: (value) => {
                    return value
                }
            },
            {
                name: t("adres"),
                field: "address",
                valueFormatter: (value) => {
                    return value
                }
            },
        ];
        return (
            <div>
                <Header text={t("users")}/>
                <ViewContainer addPaddingForButtons>
                    <ChooseUsers addtionalHeaders={addtionalHeaders}
                                 buttons={(props) => [
                                     <div>
                                         <Button type="button"
                                                 onClick={e => this.onDeleteUserClick(e, props.user)}
                                                 buttonStyle={"round"}
                                                 buttonColor={"error"}
                                                 title={t("newSettings.users.changeRolesView.deleteUser")}
                                                 icon={<i className={"fas fa-trash"}/>}
                                         />
                                     </div>,
                                     <div>
                                         <Button type="button"
                                                 id={"editButton"}
                                                 onClick={e => this.openModal(props.user, e)}
                                                 buttonStyle={"round"}
                                                 title={t("newSettings.users.changeRolesView.editUser")}
                                                 icon={<i className={"fas fa-user-edit"}/>}/>
                                     </div>,
                                     <div>
                                         <Switch
                                             additionalClass={"user-switch"}
                                             label={t("newSettings.users.changeRolesView.disableAccount")}
                                             value={props.user.enable}
                                             onDivClick={e => this.onSwitchClick(e, props)}
                                             id={myID()}
                                             title={t("newSettings.users.changeRolesView.disableDescription")}
                                             disabled={this.shouldDisableSwitch(props)}
                                         />
                                     </div>
                                 ]}/>

                    <UserEditRoleModal/>
                    {
                        checkIfUserIsOwner() &&
                        <ButtonGroup fixed>
                            <Button buttonStyle={"round"} buttonColor={"success"}
                                    icon={<i className="fas fa-user-plus"/>}
                                    onClick={this.onAddUserClick}/>
                        </ButtonGroup>
                    }
                </ViewContainer>
            </div>
        );

    }
}

ChangeRolesView = connect(state => ({
    farm: state.location.farm,
    attributes: state.user.attributes,
    user: state.user.user
}))(ChangeRolesView);

export let _ChangeRolesView = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(ChangeRolesView);
export default withTranslation()(_ChangeRolesView);

