import React from "react";
import FormForEvents from "../FormForEvents";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {
    animalField,
    calculateGridRowsByHeight,
    checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateField,
    getAnimalOptions,
    getAnimals,
    getInitialValues,
    insertData,
    insertFilters,
    operatorField,
    validateGrid
} from "../../../../utils/DataGridUtils";
import {compose} from "redux";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import {Formik} from "formik";
import {isNil} from "lodash";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {convertWeightToBaseUnit, getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import devicesDB from "../../../../database/devicesDB";
import {createSell, createSellNotificationFailure, createSellNotificationSuccess} from "../../../../api/events/Sell";
import {eventChooserFormNames} from "../../../../constans/formNames";

const formName = eventChooserFormNames.SELL;

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine,
        clients: state.dictionary.clients.WData[state.language.lang.lang]
    }
}

export class Sell extends React.Component {

    state = {
        animals: [],
        initialData: []
    }

    componentDidMount() {
        const {farm} = this.props;
        let animals = getAnimals(farm);
        this.setState({
            animals: getAnimalOptions(animals),
            initialData: getInitialValues(formName)
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight);
        }
        if (filters.hasOwnProperty("client")) {
            const {client} = filters;
            value = insertData(client, "client", value, oldFilters.client);
        }
        if (filters.hasOwnProperty("price")) {
            const {price} = filters;
            value = insertData(price, "price", value, oldFilters.price);
        }
        if (filters.hasOwnProperty("hcw")) {
            const {hcw} = filters;
            value = insertData(hcw, "hcw", value, oldFilters.hcw);
        }
        if (filters.hasOwnProperty("amount")) {
            const {amount} = filters;
            value = insertData(amount, "amount", value, oldFilters.amount, this.isAmountEditable);
        }
        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            animals: getAnimalOptions(getAnimals(farm, animalsAlreadySelected))
        })
    }

    weightFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("avgPieceWeight")}/>
    }

    clientFormatter = ({column: {key}, row}) => {
        const {clients} = this.props;
        let id = row[key];
        let reason = clients.find(item => item.ID === id);
        if (reason) return reason.Value;
        return null;
    }

    clientFilter = ({value, onChange}) => {
        const {clients, t} = this.props;
        return <Select value={value}
                       options={clients.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={t("buyer")}/>
    }

    priceFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"}
                            placeholder={t("priceFor", {unit: getUnit("weight", UnitTypes.MEDIUM)})}/>
    }

    hcwFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("hotWeight")}/>
    }

    amountFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("animalCount")}
                            allowFloat={false}/>
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;

        if (data) {
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (isNil(row.weight)) {
                    rowErrors.weight = t("required");
                }

                if (!row.client) {
                    rowErrors.client = t("required");
                }

                if (isNil(row.price)) {
                    rowErrors.price = t("required");
                }

                if (isNil(row.hcw)) {
                    rowErrors.hcw = t("required");
                }

                if (row.animal && row.animal.AnmCnt !== 1 && isNil(row.amount)) {
                    rowErrors.amount = t("required");
                }

                return rowErrors;
            })
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, weight, client, hcw, price, amount} = row;
            if (animal) {
                let devices = devicesDB.getDevicesInPlcmntID(animal.PlcmntID, {showDevicesInChildren: false});
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment,
                    amount: amount || 1,
                    devices: devices.map(device => ({
                        DevID: device.DevID,
                        GwID: device.GatewayID
                    })),
                    clearFeeding: true,
                    Weight: convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM}),
                    Buyer: client,
                    Price: price,
                    Hcw: hcw,
                })
            }
        }
        return createSell(data).then(res => {
            createSellNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createSellNotificationFailure(e);
        })
    }

    isAmountEditable = ({animal}) => {
        if (animal) return animal.AnmCnt !== 1;
        return true;
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    weightFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    render() {
        const {animals, initialData} = this.state;
        const {clients, t} = this.props;
        const columns = [
            animalField(animals),
            {
                name: t("animalCount"),
                key: "amount",
                filterRenderer: this.amountFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>),
                editable: this.isAmountEditable
            },
            {
                name: t("avgPieceWeight"),
                key: "weight",
                filterRenderer: this.weightFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                formatter: this.weightFormatter
            },
            {
                name: t("buyer"),
                key: "client",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={clients.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.clientFormatter,
                filterRenderer: this.clientFilter
            },
            {
                name: t("priceFor", {unit: getUnit("weight", UnitTypes.MEDIUM)}),
                key: "price",
                filterRenderer: this.priceFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}/>)
            },
            {
                name: t("hotWeight"),
                key: "hcw",
                filterRenderer: this.hcwFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                formatter: this.weightFormatter
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} getApi={this.getApi}
                                       isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Sell);