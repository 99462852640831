import React from "react";
import FormForEvents from "../FormForEvents";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
    animalField,
    calculateGridRowsByHeight,
    checkIfHaveOnlyTechnologyGroup,
    commentField,
    dateFormatter,
    dateRenderer,
    getAnimalOptions,
    getInitialValues,
    getSows,
    insertComment,
    insertData,
    insertOperator,
    operatorField,
    technologyGroupInsert,
    validateGrid
} from "../../../../utils/DataGridUtils";
import AnimalStateHeader from "./AnimalStateHeader";
import eventsDB from "../../../../database/eventsDB";
import {isEqual, isNil} from "lodash";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import {Formik} from "formik";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {convertWeightToBaseUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {
    createSeparationToMommy,
    createSeparationToMommyNotificationFailure,
    createSeparationToMommyNotificationSuccess
} from "../../../../api/events/SeparationToMommy";
import {eventChooserFormNames} from "../../../../constans/formNames";
import PigletsAddedHeader from "./PigletsAddedHeader";
import formDataDB from "../../../../database/formDataDB";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";

const formName = eventChooserFormNames.SEPARATION_TO_MOMMY;

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

export class SeparationToMommy extends React.Component {

    state = {
        sows: [],
        initialData: [],
        separatedToMommy: eventsDB.getNumberOfSeparatedPigsToMommy()
    }

    componentDidMount() {
        const {farm} = this.props;
        let sows = getSows(farm);
        this.setState({
            sows: getAnimalOptions(sows),
            initialData: getInitialValues(formName)
        });
    }

    initializeValues() {
        let savedData = formDataDB.getSavedData(formName);
        if (savedData) {
            for (let row of savedData.data.data) {
                if (row.animal) {
                    let piglets = eventsDB.getPigBalanceForASowInCurrentCycle(row.animal);
                    let activeNipples = eventsDB.getActiveNipplesInCurrentCycle(row.animal);
                    row.animalState = {
                        piglets,
                        activeNipples
                    }
                }
            }
            return savedData.data.data;
        }
        return new Array(calculateGridRowsByHeight()).fill({});
    }

    onFiltersChange = (filters, value, oldFilters) => {
        if (filters.hasOwnProperty("animal")) {
            const {animal: group} = filters;
            const {animal: oldGroup} = oldFilters;
            if (!isEqual(group, oldGroup)) {
                value = technologyGroupInsert(group, value, {
                    onRowChange: animal => {
                        if (animal) {
                            let piglets = eventsDB.getPigBalanceForASowInCurrentCycle(animal);
                            let activeNipples = eventsDB.getActiveNipplesInCurrentCycle(animal);
                            return {
                                animalState: {
                                    piglets,
                                    activeNipples
                                }
                            }
                        }
                        return {};
                    }
                });
            }
        }
        if (filters.hasOwnProperty("date")) {
            const {date} = filters;
            value = insertData(date, "date", value, oldFilters.date);
        }
        value = insertOperator(filters, value, oldFilters.operator);
        value = insertComment(filters, value, oldFilters.comment);
        if (filters.hasOwnProperty("get")) {
            const {get} = filters;
            value = insertData(get, "get", value, oldFilters.get);
        }
        if (filters.hasOwnProperty("add")) {
            const {add} = filters;
            value = insertData(add, "add", value, oldFilters.add);
        }
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight);
        }
        return value;
    }

    onDataChange = (newValue, filters) => {
        const {farm} = this.props;
        let animalsAlreadySelected = [];
        for (let row of newValue) {
            if (row.animal) animalsAlreadySelected.push(row.animal);
        }
        if (filters.animal && !checkIfHaveOnlyTechnologyGroup(filters.animal, newValue)) {
            this.api.tableInput.removeFilter("animal");
        }
        this.setState({
            sows: getAnimalOptions(getSows(farm, animalsAlreadySelected))
        })
    }

    onAnimalChange = (data, oldValues) => {
        let {animal} = data;
        if (animal === undefined) {
            animal = oldValues.animal;
        }
        if (animal) {
            let piglets = eventsDB.getPigBalanceForASowInCurrentCycle(animal);
            let activeNipples = eventsDB.getActiveNipplesInCurrentCycle(animal);
            data.animalState = {
                piglets,
                activeNipples
            };
        }
        return data;
    }

    animalStateValueFormatter = ({column: {key}, row}) => {
        const {t} = this.props;
        let data = row[key];
        if (data) {
            let text = "- / -";
            if (data.piglets !== undefined) {
                text = text.replace("-", data.piglets);
            }
            if (typeof data.activeNipples === "number") {
                text = text.replace(new RegExp("-$"), data.activeNipples);
            }
            return (
                <div className="animal-state-header">
                    {text}
                    {
                        !isNil(row.add) && data.piglets !== undefined && typeof data.activeNipples === "number" && data.piglets + +row.add > data.activeNipples &&
                        <Tooltip tooltipContent={t("activeNipplesExceeded", {
                            current: data.piglets + +row.add,
                            activeNipples: data.activeNipples
                        })} type={"warning"}>
                            <i className="fas fa-exclamation-triangle warning"/>
                        </Tooltip>
                    }
                </div>
            )
        }
        return null;
    }

    getFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("taken")}
                            allowFloat={false}/>
    }

    addFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("added")}
                            allowFloat={false}/>
    }

    weightFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"}
                            placeholder={t("averagePigletWeightShort")}/>
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;

        if (data) {
            let maxPiglets = this.state.separatedToMommy;
            let addingPiglets = 0;
            for (let row of data) {
                if (!isNil(row.get)) {
                    maxPiglets += +row.get;
                }
                if (!isNil(row.add)) {
                    addingPiglets += +row.add;
                }
            }
            errors.data = validateGrid(data, row => {
                const rowErrors = {};

                if (isNil(row.weight)) {
                    rowErrors.weight = t("required");
                }

                if (isNil(row.get) && isNil(row.add)) {
                    rowErrors.get = t("required");
                    rowErrors.add = t("required");
                }

                if (!isNil(row.get) && +row.get <= 0) {
                    rowErrors.get = t("errors.lessVal", {count: 1})
                } else if (row.animalState && row.animalState.piglets < +row.get) {
                    rowErrors.get = t("errors.maxAnimalsValue", {amount: row.animalState.piglets});
                }

                if (!isNil(row.add) && +row.add <= 0) {
                    rowErrors.add = t("errors.lessVal", {count: 1})
                } else if (!isNil(row.add) && maxPiglets < addingPiglets) {
                    rowErrors.add = t("errors.maxPigletsAdded", {amount: maxPiglets});
                }

                return rowErrors;
            }, ["animalState"]);
        }
        if (errors.data === undefined) delete errors.data;

        return errors;
    }

    submit = (values, {resetForm}) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, get, add, weight} = row;
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    Comment: comment,
                    Piglets: get,
                    Weight: convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM}),
                    PigletsAdded: add
                })
            }
        }
        return createSeparationToMommy(data).then(res => {
            createSeparationToMommyNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createSeparationToMommyNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    render() {
        const {sows, initialData, separatedToMommy} = this.state;
        const {t} = this.props;
        const columns = [
            animalField(sows, this.onAnimalChange),
            {
                name: t("status"),
                key: "animalState",
                editable: false,
                headerRenderer: (props) => <AnimalStateHeader {...props}/>,
                formatter: this.animalStateValueFormatter
            },
            {
                name: t("taken"),
                key: "get",
                filterRenderer: this.getFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>)
            },
            {
                name: t("added"),
                key: "add",
                filterRenderer: this.addFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>),
                headerRenderer: (props) => <PigletsAddedHeader {...props} separatedToMommy={separatedToMommy}/>,
            },
            {
                name: t("averagePigletWeightShort"),
                key: "weight",
                filterRenderer: this.weightFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}/>)
            },
            {
                name: t("date"),
                key: "date",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer,
                onChange: this.onAnimalChange
            },
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange}
                                       onDataChange={this.onDataChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} getApi={this.getApi}
                                       isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(SeparationToMommy);