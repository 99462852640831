import React from "react";
import Header from "../../../components/basics/header/Header";
import Card from "../../../components/basics/card/Card";
import ViewContainer from "../../../components/basics/view-container/ViewContainer";
import "./_billing.scss";
import moment from "moment";
import {connect} from "react-redux";
import {gatewayCustomerFind, terminateAccount} from "../../../actions/braintreeActions";
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {get, isEqual} from "lodash";
import Button from "../../../components/basics/button/Button";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import {sortAsNumber} from "../../../utils/SortUtils";
import PaymentMethods from "../../../components/payment-methods/PaymentMethods";
import {isMobile} from "../../../utils/MobileUtils";
import {bindActionCreators, compose} from "redux";
import {hide, show} from "redux-modal";
import {getUser} from "../../../actions/userActions";
import {ModalName as ConfirmModalName} from "../../../components/modals-new/confirm-modal/ConfirmModal";
import DelayedActionModal from "../../../components/modals-new/delayed-action/DelayedActionModal";
import {logout} from "../../../utils/UserUtils";
import BillingExpandInfo from "./BillingExpandInfo";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import AddPaymentMethodsModal, {ModalName as AddBillingModal} from "../../../components/modals-new/payment-methods-modal/AddPaymentMethodsModal";
import {withTranslation} from "react-i18next";
import withRoles from "../../../components/withRoles";
import NotFound from "../../NotFound";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";
import Tooltip from "../../../components/basics/tooltip/Tooltip";

function mapStateToProps(state) {
    return {
        attributes: state.user.attributes,
        subscriptions: state.braintree.subscriptionList,
        paymentMethods: state.braintree.paymentMethodList,
        user: state.user.user,
        fetching: state.braintree.fetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

export class BillingView extends React.Component {
    constructor(props) {
        super(props);
        const {attributes: {sub, email, name, phone}, location: {state}, dispatch} = this.props;
        this.state = {
            subs: BillingView.getData(this.props),
            loading: true,
            edit: false,
            forceFetch: get(state, "forceFetch", false)
        };
        BillingView.getData(this.props);
        dispatch(getUser(sub, false, () => gatewayCustomerFind(email, name, phone, sub, this.state.forceFetch)));
    }

    static getData(props) {
        const {user: {FarmData}, subscriptions} = props;
        return FarmData.map((item) => {
            const sub = subscriptions.find((s) => s.id === item.SubID);
            return {
                farm: item,
                sub
            }
        }).filter(item => item.farm && item.sub);
    }

    /**
     * Metoda zwracajaca tablice stringów w formacie "{price} {currency}"
     * @param subs
     * @return {string[]}
     */
    static getPrice(subs) {
        let map = subs.reduce((a, b) => {
            let priceSplit = b.price.split(" ");
            let price = +priceSplit[0];
            let currency = priceSplit[1];
            let priceFromMap = a.get(currency) || 0;
            priceFromMap += price;
            a.set(currency, priceFromMap);
            return a;
        }, new Map());
        return [...map.entries()].map(([currency, price]) => `${price} ${currency}`);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {subscriptions, fetching} = this.props;
        if (!isEqual(prevProps.subscriptions, subscriptions)) {
            this.setState({
                subs: BillingView.getData(this.props)
            });
        }
        if (prevProps.fetching !== fetching) {
            this.setState({
                loading: fetching
            })
        }
    }

    /**
     * Znajdowanie nastepnej daty platnosci dla LicPackages
     * @return {moment}
     */
    getNextPaymentDay = () => {
        const {subs} = this.state;
        if (subs.length > 0) {
            const dates = subs.map((s) => moment(get(s, "sub.nextBillingDate")).toDate().getTime());
            return moment(Math.min.apply(null, dates));
        }
    };

    /**
     * Metoda zwraca kwotę następnej płatności podzieloną na waluty
     * @param nextPayment {moment} data kolejnej płatności
     * @return {string[]}
     */
    getNextBill(nextPayment) {
        const {subs} = this.state;
        let filtered = [];
        if (nextPayment) {
            filtered = subs.map((s) => s.sub).filter((s) => s.nextBillingDate === nextPayment.format("YYYY-MM-DD"));
        }
        return BillingView.getPrice(filtered);
    }

    /**
     * Metoda zwraca kwotę zaległych płatności podzieloną na waluty
     * @return {string[]}
     */
    getDuePayment() {
        const {subs} = this.state;
        let filtered = subs.map((s) => s.sub).filter((s) => moment(s.nextBillingDate).toDate().getTime() < moment().startOf("day").toDate().getTime());
        return BillingView.getPrice(filtered);
    }

    onHistoryButtonClick = () => {
        const {forceFetch} = this.state;
        const {history} = this.props;
        history.replace({
            pathname: "/farmSettings/billing/history",
            state: {
                forceFetch
            }
        })
    };

    sortPrice = (a, b, header, sortType, value1, value2) => {
        let val1 = value1.split(" ")[0], val2 = value2.split(" ")[0];
        return sortAsNumber(a, b, header, sortType, val1, val2);
    };

    onAddNewFarmClick = () => {
        const {history} = this.props;
        history.replace("/farmSettings/subscription/add");
    };

    statusValueFormatter = value => {
        const {t} = this.props;
        return t(`subscriptions.grid.${value}`);
    };

    terminateAccount = () => {
        const {t, user, subscriptions, paymentMethods, show, dispatch} = this.props;
        let subs = subscriptions.filter(s => s.status !== "Expired" && s.status !== "Canceled").map(s => s.id);
        let pays = paymentMethods.map(p => p.token);
        show(ConfirmModalName, {
            title: t("farmSettings.billingView.terminateAccountTitle"),
            text: t("farmSettings.billingView.terminateAccountText"),
            onConfirmed: props => {
                dispatch(terminateAccount({
                    clientID: user.ClientID,
                    localUserID: user.LocalUserID,
                    subs: subs,
                    pays: pays
                }));
                props.handleHide();
            }
        });
    };

    showAddBillingModal = () => {
        const {show, t} = this.props;
        show(AddBillingModal, {title: t("paymentMethods")});
    };

    getAdminSubscription = () => {
        const {subscriptions, user: {ClientID}} = this.props;
        return subscriptions.find((s) => s.id.slice(0, -20) === ClientID);
    };

    getContent = () => {
        const isService = checkIfUserIsService();
        const {t} = this.props;
        if (isService) {
            return (
                <span>
                    <i className={'fas fa-ban mr-1'}/>
                    {t('noServiceAccess')}
                </span>
            )
        }
    };

    render() {
        if (process.env.REACT_APP_STAGE === "production") return <NotFound/>;
        const {t} = this.props;
        const isService = checkIfUserIsService();
        const {subs, loading} = this.state;
        const headers = [
            {
                name: t("farmName"),
                field: "farm.FarmName"
            },
            {
                name: t("sum"),
                field: "sub.price",
                customSort: this.sortPrice
            },
            {
                name: t("status"),
                field: "sub.status",
                valueFormatter: this.statusValueFormatter
            },
            {
                name: "",
                component: () => <i className="fas fa-angle-down pointer"/>,
                headerClassName: "index",
                itemClassName: "index"
            }
        ];
        const adminSubscription = this.getAdminSubscription();
        let nextPaymentDay = this.getNextPaymentDay();
        let nextBill = this.getNextBill(nextPaymentDay);
        let duePayment = this.getDuePayment();
        return (
            <div className="billing">
                <Header text={t("changeSubscription")}/>
                <ViewContainer addPaddingForButtons>
                    <LoadingComponent isLoading={loading} style={isMobile() ? {position: "fixed"} : null}/>
                    <Card>
                        <div className="billing-info">
                            <div className="billing-info-item">
                                {nextBill.length > 0 ? nextBill.join(", ") : "-"}
                                <small>{t("nextPayment")}</small>
                            </div>
                            <div className="billing-info-item">
                                {nextPaymentDay ? nextPaymentDay.format("DD.MM.YYYY") : "-"}
                                <small>{t("farmSettings.billingView.nextPaymentDate")}</small>
                            </div>
                            <div className="billing-info-item">
                                {duePayment.length > 0 ? duePayment.join(", ") : "-"}
                                <small>{t("farmSettings.billingView.duePayments")}</small>
                            </div>
                            <div className="billing-info-item">
                                <Tooltip tooltipContent={this.getContent() || t("farmSettings.billingView.terminateAccount")}>
                                    <div className="billing-button">
                                        <Button buttonStyle={"rectangle"} icon={<i className="fas fa-trash"/>}
                                                buttonColor={"error"} disabled={isService}
                                                onClick={this.terminateAccount}>
                                            {t("farmSettings.billingView.terminateAccount")}
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </Card>
                    {
                        adminSubscription &&
                        <Card className="billing-admin">
                            <h4 className="d-flex justify-content-between">
                                {
                                    t("farmSettings.billingView.admin")
                                }
                            </h4>
                            <div className="billing-info">
                                <div className="billing-info-item">
                                    {adminSubscription ? adminSubscription.price : "-"}
                                    <small>{t("sum")}</small>
                                </div>
                                <div className="billing-info-item">
                                    {adminSubscription ? moment(adminSubscription.nextBillingDate).format("DD.MM.YYYY") : "-"}
                                    <small>{t("nextPayment")}</small>
                                </div>
                            </div>
                        </Card>
                    }
                    <Card className="billing-subscriptions">
                        <div className="billing-subscriptions-header">
                            <h4>{t("farmSettings.billingView.yourSubs")}</h4>
                            <div className="farms-buttons">
                                <Tooltip tooltipContent={t("farmSettings.billingView.paymentHistory")} placement={"auto"}>
                                    <div className="billing-button">
                                        <Button buttonStyle={isMobile() ? "round" : "rectangle"}
                                                icon={<i className="fas fa-history"/>}
                                                buttonColor={"info"}
                                                onClick={this.onHistoryButtonClick}>
                                            {!isMobile() ? t("farmSettings.billingView.paymentHistory") : ""}
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip tooltipContent={this.getContent() || t("addNew")}>
                                    <div className="billing-button">
                                        <Button buttonStyle={isMobile() ? "round" : "rectangle"}
                                                icon={<i className="fas fa-plus"/>}
                                                buttonColor={"success"}
                                                disabled={isService}
                                                onClick={this.onAddNewFarmClick}>
                                            {!isMobile() ? t("addNew") : ""}
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <TableGrid data={subs} headers={headers} shouldIndex isExpanded
                                   component={<BillingExpandInfo/>}/>
                    </Card>
                    <Card className="payment-methods">
                        <div className="payment-header">
                            <h4>{t("paymentMethods")}</h4>
                            <Tooltip tooltipContent={this.getContent() || t("addNew")}>
                                <div className="billing-button">
                                    <Button buttonStyle={isMobile() ? "round" : "rectangle"}
                                            icon={<i className="fas fa-plus"/>}
                                            buttonColor={"success"}
                                            disabled={isService}
                                            onClick={this.showAddBillingModal}>
                                        {!isMobile() ? t("addNew") : ""}
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                        <PaymentMethods/>
                    </Card>
                </ViewContainer>
                <DelayedActionModal handleHide={hide} counter={10} action={logout} title={t("modals.logoutModal.title")}
                                    text={"modals.logoutModal.text"}
                                    confirmButtonText={t("logout")}/>
                <AddPaymentMethodsModal title={t("paymentMethods")}/>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    withRoles({roles: [RoleTypes.BILLING]}),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(BillingView);
