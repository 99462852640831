import React from "react";
import {getEmployees} from "../../../actions/userActions";
import ChooseUserItem from "./ChooseUserItem";
import {Row} from "react-bootstrap";
import List from "../../../components/basics/list/List";
import Card from "../../../components/basics/card/Card";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import {withTranslation} from "react-i18next";
import SearchInput from "../../../components/basics/search-input/SearchInput";
import {get, memoize} from "lodash";
import {compareTwoStrings} from "string-similarity"
import withRoles from "../../../components/withRoles";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import * as UserTypes from "validators-schema/Api/constants/userTypes";
import {compose} from "redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        employees: state.user.employees,
        user: state.user.user,
        fetchingEmployees: state.user.fetchingEmployees,
        farms: state.farms.farms
    }
}

class ChooseUsers extends React.Component {

    filteredEmployees = memoize((employees, search) => {
        if (search.trim() === "") return employees;
        const ratio = [1, 0.8, 0.6, 0.4];
        let arr = (e) => [e.userName || "", e.phoneNumber || "", e.email || "", e.address || ""];
        return (employees || []).filter((e) => {
            return arr(e).join(" ").toLowerCase().includes(search.toLowerCase());
        }).sort((o1, o2) => {
            let r1 = 0;
            let r2 = 0;
            let arr1 = arr(o1);
            let arr2 = arr(o2);
            for (let idx = 0; idx < ratio.length; idx++) {
                r1 += compareTwoStrings(search, arr1[idx]) * ratio[idx];
                r2 += compareTwoStrings(search, arr2[idx]) * ratio[idx];
            }
            return r1 - r2;
        });
    }, (...args) => JSON.stringify(args));

    constructor(props) {
        super(props);
        this.props.dispatch(getEmployees());
        this.state = {
            search: "",
        };
    }

    render() {
        const {search} = this.state;
        const {addtionalHeaders, buttons, fetchingEmployees, t, user, employees: allEmployees, farms} = this.props;
        const _allEmployees = this.filteredEmployees(allEmployees, search);
        let employees = _allEmployees.filter(e => e.LocalUserID !== user.LocalUserID);
        const userType = get(user, "userType", UserTypes.MANAGER);
        if (userType !== UserTypes.OWNER) {
            employees = employees.filter((e) => e.userType !== UserTypes.OWNER);
        }
        return (
            <Card>
                <SearchInput placeholder={t("newSettings.users.chooseUsers.findUser")} className={"mb-2"} value={search} onChange={value => this.setState({search: value})}/>
                <Row style={{minHeight: '5rem'}}>
                    <LoadingComponent isLoading={fetchingEmployees}/>
                    <List property={"farmName"}>
                        {
                            !fetchingEmployees && employees.length > 0 && employees.map(user => {
                                const farmName = get(farms.find((farm) => farm.FarmID === user.FarmID), "FarmName", t("grid.rest"));
                                return (
                                    <ChooseUserItem farmName={farmName} searchText={search} user={user}
                                                    headers={addtionalHeaders}
                                                    buttons={buttons}/>
                                )
                            })
                        }
                        {
                            !fetchingEmployees && !employees.length &&
                            <p className="empty-list"><i>- {t("newSettings.users.chooseUsers.noEmployeesFound")} -</i>
                            </p>
                        }
                    </List>
                </Row>
            </Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRoles({roles: [RoleTypes.USER_MANAGE]})
)(ChooseUsers)
