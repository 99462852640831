import React from "react";
import withRoles from "../../components/withRoles";
import {Roles} from "../../constans/roles";
import Header from "../../components/basics/header/Header";
import {Row} from "react-bootstrap";
import HelpSettingsCard from "../../components/help-settings-card/HelpSettingsCard";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import HelpColorsModal, {ModalName as ColorModalName} from "../../components/modals-new/help-colors-modal/HelpColorsModal";
import HelpContactModal, {ModalName as ContactModalName} from "../../components/modals-new/help-contact-modal/HelpContactModal";
import ColorIcon from "../../resources/images/help/palette.svg";
import ContactIcon from "../../resources/images/help/comments.svg";
import {withTranslation} from "react-i18next";

export class HelpView extends React.Component {
    render() {
        return (
            <div>
                <Header text={this.props.t("help")}/>
                <ViewContainer>
                    <Row className={"card-container"}>
                        <HelpSettingsCard title={this.props.t("color")} icon={ColorIcon}
                                          modal={ColorModalName}/>
                        <HelpSettingsCard title={this.props.t("contact")} icon={ContactIcon}
                                          modal={ContactModalName}/>
                    </Row>
                    <HelpColorsModal/>
                    <HelpContactModal/>
                </ViewContainer>
            </div>
        )
    }
}

HelpView = withRoles({roles: [Roles._GENERAL_READ], showComponent: true})(HelpView);

export default withTranslation()(HelpView);