import React from "react";
import PropTypes from "prop-types"
import "./_events-picker.scss"
import {EventCategory} from "../../constans/eventTypes";
import {connect} from "react-redux";
import ItemPicker from "../../components/basics/item-picker/ItemPicker";
import {isMobile} from "../../utils/MobileUtils";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import moment from "moment";
import buildingsDB from "../../database/buildingsDB";
import {getLocationName} from "../../utils/BuildingUtils";
import {eventChooserFormNames} from "../../constans/formNames";

//import {isMobile} from "../../utils/MobileUtils";

class EventsPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            overall: [],
            grafting: [],
            procreation: [],
            parturition: [],
            fall: [],
            sell: []
        }
    }

    sortByCategory = () => {
        const {options} = this.props;
        let overall = [];
        let grafting = [];
        let procreation = [];
        let parturition = [];
        let fall = [];
        let sell = [];
        options.map(option => {
            switch (option.category) {
                case EventCategory.GRAFTING:
                    grafting = [...grafting, option];
                    break;
                case EventCategory.PROCREATION:
                    procreation = [...procreation, option];
                    break;
                case EventCategory.PARTURITION:
                    parturition = [...parturition, option];
                    break;
                case EventCategory.FALL:
                    fall = [...fall, option];
                    break;
                case EventCategory.SELL:
                    sell = [...sell, option];
                    break;
                default:
                    overall = [...overall, option];
            }
        });
        this.setState({
            overall,
            sell,
            fall,
            parturition,
            procreation,
            grafting
        });
    };

    componentDidMount() {
        this.sortByCategory();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {options} = this.props;
        if (options !== prevProps.options) {
            this.sortByCategory();
        }
    }

    styleToMobile() {
        return (isMobile() ? "fetura-event-picker-mobile" : "fetura-event-picker");
    }

    onClick = value => {
        const {farm} = this.props;
        this.props.history.push(`/${farm}/eventChooser/${value}`);
    }

    getDraftName(draft) {
        const {t} = this.props;
        if (draft.formName.includes("Insert")) {
            let split = draft.formName.split("_");
            let location = buildingsDB.getLocationByID(split[1]);
            return moment(draft.time).format("DD.MM.YYYY") + " - " + getLocationName(location) + " - " + t(`animalKind.${split[2]}`);
        }
        if (draft.formName.includes("transfer") || draft.formName.includes(eventChooserFormNames.SEPARATION_TO_PORKHOUSE)) {
            let split = draft.formName.split("_");
            let location = buildingsDB.getLocationByID(split[1]);
            return moment(draft.time).format("DD.MM.YYYY") + " - " + getLocationName(location);
        }
        return moment(draft.time).format("DD.MM.YYYY");
    }

    getDraftTooltip = drafts => {
        const {t} = this.props;
        return (
            <>
                <div>
                    {t("foundDrafts")}
                </div>
                {
                    drafts.map((draft, index) => <div key={index}>{this.getDraftName(draft)}</div>)
                }
            </>
        );
    }

    render() {
        const {required, label, t} = this.props;
        const {sell, fall, parturition, procreation, grafting, overall} = this.state;
        return (
            <React.Fragment>
                {
                    label && <label>{label}{required && <sup>*</sup>}</label>
                }
                <div className={this.styleToMobile()}>

                    <>
                        <div className="top-column">
                            <div className="menuBox">
                                <h1>{t("general")}</h1>
                                {overall && overall.length > 0 &&
                                <div className="menuItemBox"> {
                                    <ItemPicker value={null} options={overall} onChange={this.onClick}
                                                showLabels
                                                itemsInRow={4} getDraftTooltip={this.getDraftTooltip}/>
                                }</div>
                                }
                            </div>
                            <span className="vl"/>
                            <div className="menuBox">
                                <h1>{t("treatment")}</h1>
                                {grafting && grafting.length > 0 &&
                                <div className="menuItemBox"> {
                                    <ItemPicker value={null} options={grafting} onChange={this.onClick}
                                                showLabels
                                                itemsInRow={4}/>
                                }</div>

                                }
                            </div>
                            <span className="vl"/>
                            <div className="menuBox">
                                <h1>{t("menu.procreation")}</h1>
                                {procreation && procreation.length > 0 &&
                                <div className="menuItemBox"> {
                                    <ItemPicker value={null} options={procreation} onChange={this.onClick}
                                                showLabels
                                                itemsInRow={4}/>
                                }</div>
                                }
                            </div>
                        </div>
                        <div className="bottom-column">

                            <div className="menuBox">
                                <h1>{t("menu.parturition")}</h1>
                                {parturition && parturition.length > 0 &&
                                <div className="menuItemBox">{
                                    <ItemPicker value={null} options={parturition} onChange={this.onClick}
                                                showLabels
                                                itemsInRow={4} getDraftTooltip={this.getDraftTooltip}/>
                                }</div>
                                }
                            </div>
                            <span className="vl"/>
                            <div className="menuBox">
                                <h1>{t("menu.fall")}</h1>
                                {fall && fall.length > 0 &&
                                <div className="menuItemBox"> {
                                    <ItemPicker value={null} options={fall} onChange={this.onClick}
                                                showLabels
                                                itemsInRow={4}/>
                                }</div>
                                }
                            </div>
                            <span className="vl"/>


                            <div className="menuBox">
                                <h1>{t("sale")}</h1>
                                {sell && sell.length > 0 &&
                                <div className="menuItemBox">
                                    {
                                        <ItemPicker value={null} options={sell} onChange={this.onClick}
                                                    showLabels
                                                    itemsInRow={4}/>
                                    }</div>
                                }
                            </div>
                        </div>
                    </>

                </div>
            </React.Fragment>

        );
    }

}

EventsPicker.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
        svgPath: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool
    })),
    onClick: PropTypes.func
};

EventsPicker.defaultProps = {
    optionsInSingleRow: 4,
    showLabels: false,
    onChange: () => {
    },
    itemsInRow: 5
};

export default compose(
    withTranslation(),
    connect(state => ({
        farm: state.location.farm
    })),
    withRouter
)(EventsPicker);