import React from "react";
import "./_transfer-event.scss";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import TransferStepper from "./TransferStepper";
import NotFound from "../../../../components/NotFound";
import buildingsDB from "../../../../database/buildingsDB";
import {getLocationName} from "../../../../utils/BuildingUtils";
import TransferStand from "./TransferStand";
import TransferChamber from "./TransferChamber";
import TransferFeeding from "./TransferFeeding";
import devicesDB from "../../../../database/devicesDB";
import {DevType} from "../../../../constans/devices";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

export class Transfer extends React.Component {

    state = {
        location: null,
        transferData: null,
        initialData: null,
        hasFeeding: false
    }

    constructor(props) {
        super(props);
        const {match: {params: {locationID}}} = this.props;
        let location = buildingsDB.getLocationByID(locationID);
        if (location && !location.CID) location = null;
        this.state.location = location;
        this.state.hasFeeding = this.locationHasFeeding();
    }

    locationHasFeeding() {
        const {match: {params: {locationID}}} = this.props;
        let devices = devicesDB.getDevicesInPlcmntID(locationID).filter(item => [DevType.DISPENSER, DevType.DISPENSER_NRF].includes(item.DevType));
        return devices.length > 0;
    }

    onSubmitTransferData = data => {
        this.setState({
            transferData: data
        })
    }

    onThirdStepClick = () => {
        this.setState({
            transferData: null,
            initialData: this.state.transferData
        })
    }

    onSubmitSuccess = () => {
        const {farm} = this.props;
        this.props.history.push({
            pathname: `/${farm}/eventChooser/locationChooser/transfer`,
            state: {ignoreModal: true}
        });
    }

    render() {
        const {location, transferData, initialData, hasFeeding} = this.state;
        if (!location) return <NotFound/>
        const {t} = this.props;
        return (
            <div className="transfer">
                <TransferStepper activeStep={transferData ? 4 : 3} onThirdStepClick={this.onThirdStepClick}
                                 hasFeeding={hasFeeding}/>
                <h5>{t("location")}: {getLocationName(location)}</h5>
                {
                    !transferData &&
                    <>
                        {
                            location.Boxes &&
                            <TransferStand placement={location}
                                           onSubmit={hasFeeding ? this.onSubmitTransferData : this.onSubmitSuccess}
                                           initialData={initialData} hasFeeding={hasFeeding}/>
                        }
                        {
                            !location.Boxes &&
                            <TransferChamber placement={location}
                                             onSubmit={hasFeeding ? this.onSubmitTransferData : this.onSubmitSuccess}
                                             initialData={initialData} hasFeeding={hasFeeding}/>
                        }
                    </>
                }
                {
                    transferData &&
                    <TransferFeeding transferData={transferData} showBoxes={!!location.Boxes} location={location}
                                     onSubmit={this.onSubmitSuccess}/>
                }
            </div>
        );
    }

}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Transfer);
