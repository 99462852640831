import React from "react";
import {withTranslation} from "react-i18next";
import {
    animalFormatter,
    calculateGridRowsByHeight,
    commentField,
    dateField,
    getAnimalOptions,
    getInitialValues,
    getPorkers,
    insertData,
    insertFilters,
    isEmptyRow,
    operatorField
} from "../../../../utils/DataGridUtils";
import {Formik} from "formik";
import FormForEvents from "../FormForEvents";
import {compose} from "redux";
import {connect} from "react-redux";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import Select from "../../../../components/basics/select/Select";
import LocationEditor from "../../../../components/basics/table-input/editors/LocationEditor";
import {getLocationID, getLocationName} from "../../../../utils/BuildingUtils";
import TreeSelect from "../../../../components/basics/tree-select/TreeSelect";
import buildingsDB from "../../../../database/buildingsDB";
import {isEmpty, isNil} from "lodash";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import i18n from "../../../../i18n";
import {convertWeightToBaseUnit, getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {
    createReclassify,
    createReclassifyNotificationFailure,
    createReclassifyNotificationSuccess
} from "../../../../api/events/Reclassify";
import {eventChooserFormNames} from "../../../../constans/formNames";
import animalsDB from "../../../../database/animalsDB";
import {addZerosToRfid} from "../../../../utils/AnimalsUtils";

const formName = eventChooserFormNames.RECLASSIFY;

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

export class Reclassify extends React.Component {

    state = {
        porkers: [],
        initialData: [],
        buildings: buildingsDB.getObjectForTreeSelect(this.props.farm, false)
    }

    componentDidMount() {
        const {farm} = this.props;
        let porkers = getPorkers(farm);
        this.setState({
            porkers: getAnimalOptions(porkers),
            initialData: getInitialValues(formName)
        });
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("AnmNo1")) {
            const {AnmNo1} = filters;
            value = insertData(AnmNo1, "AnmNo1", value, oldFilters.AnmNo1);
        }
        if (filters.hasOwnProperty("AnmNo2")) {
            const {AnmNo2} = filters;
            value = insertData(AnmNo2, "AnmNo2", value, oldFilters.AnmNo2);
        }
        if (filters.hasOwnProperty("RFID")) {
            const {RFID} = filters;
            value = insertData(RFID, "RFID", value, oldFilters.RFID);
        }
        if (filters.hasOwnProperty("group")) {
            const {group} = filters;
            value = insertData(group, "group", value, oldFilters.group);
        }
        if (filters.hasOwnProperty("location")) {
            const {location} = filters;
            value = insertData(location, "location", value, oldFilters.location);
        }
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight);
        }
        if (filters.hasOwnProperty("price")) {
            const {price} = filters;
            value = insertData(price, "price", value, oldFilters.price);
        }
        return value;
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t, farm} = this.props;

        if (data) {
            let hasAnyErrors = false;
            errors.data = data.map((row, index) => {
                let rowErrors = {};
                if (!isEmptyRow(row)) {
                    if (!row.operator) {
                        rowErrors.operator = i18n.t("required");
                    }

                    if (!row.AnmNo1) {
                        rowErrors.AnmNo1 = t("required");
                    } else if (animalsDB.checkIfAnimalExistOnFarm(row.AnmNo1, farm)) {
                        rowErrors.AnmNo1 = t("errors.exist")
                    } else if (data.find((d, idx) => d.AnmNo1 === row.AnmNo1 && index !== idx)) {
                        rowErrors.AnmNo1 = t("errors.duplicate");
                    }

                    if (row.RFID) {
                        const rfid = addZerosToRfid(row.RFID + "");
                        if (animalsDB.checkIfHaveRFID(rfid, farm)) {
                            rowErrors.RFID = t("errors.exist");
                        } else if (data.find((d, idx) => d.RFID === row.RFID && index !== idx)) {
                            rowErrors.RFID = t("errors.duplicate");
                        }
                    }

                    if (!row.group) {
                        rowErrors.group = t("required");
                    }

                    if (!row.location) {
                        rowErrors.location = t("required");
                    }

                    if (isNil(row.weight)) {
                        rowErrors.weight = t("required");
                    }
                    rowErrors = {
                        ...rowErrors,
                    }
                }
                if (!isEmpty(rowErrors)) {
                    hasAnyErrors = true;
                }
                return rowErrors;
            })
            if (hasAnyErrors) {
                return errors;
            } else {
                return {};
            }
        }
        return errors;
    }

    anmNo1Filter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={t("animalNumberShort")}/>
    }

    anmNo2Filter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"text"}
                            placeholder={t("alternativeAnimalNumberShort")}/>
    }

    RFIDFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={t("rfid")}/>
    }

    weightFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("weight")}/>
    }

    priceFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={t("price")}/>
    }

    groupFilter = ({value, onChange}) => {
        const {porkers} = this.state;
        const {t} = this.props;
        return <Select value={value} options={porkers} onChange={onChange} placeholder={t("groupNumber")}/>
    }

    locationFilter = ({value, onChange}) => {
        const {buildings} = this.state;
        const {t} = this.props;
        return <TreeSelect options={buildings} onChange={onChange} value={value} placeholder={t("location")}/>
    }

    locationFormatter = ({column: {key}, row}) => {
        if (row[key]) return getLocationName(row[key].object);
        return null;
    }

    submit = (values) => {
        let map = {};
        for (let row of values.data) {
            const {AnmNo1, group, date, comment, RFID, AnmNo2, weight, price, location} = row;
            console.log(row);
            if (AnmNo1) {
                let data = map[group.AnmID + "_" + +date] || {
                    AnmID: group.AnmID,
                    EvTime: +date,
                    GrID: getGrIDByAnimal(group, +date, false),
                    Comment: comment,
                    newAnimals: []
                }
                data.newAnimals.push({
                    RFID,
                    AnmNo1,
                    AnmNo2,
                    Weight: convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM}),
                    Price: price,
                    PlcmntID: getLocationID(location.object)
                })
                map[group.AnmID + "_" + +date] = data;
            }
        }
        let data = [];
        for (let key in map) {
            data.push(map[key]);
        }
        return createReclassify(data).then(res => {
            createReclassifyNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createReclassifyNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    weightFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    render() {
        const {initialData, porkers} = this.state;
        const {farm, t} = this.props;
        const columns = [
            {
                name: t("animalNumberShort"),
                key: "AnmNo1",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"text"}/>),
                filterRenderer: this.anmNo1Filter
            },
            {
                name: t("alternativeAnimalNumberShort"),
                key: "AnmNo2",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"text"}/>),
                filterRenderer: this.anmNo2Filter
            },
            {
                name: t("rfid"),
                key: "RFID",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}/>),
                filterRenderer: this.RFIDFilter
            },
            {
                name: t("takeFromGroupShort"),
                key: "group",
                editor: React.forwardRef((props, ref) => <SelectEditor {...props} options={porkers} ref={ref}/>),
                formatter: animalFormatter,
                filterRenderer: this.groupFilter
            },
            {
                name: t("location"),
                key: "location",
                editor: React.forwardRef((props, ref) => <LocationEditor {...props} ref={ref} farm={farm}
                                                                         showStands={false}/>),
                filterRenderer: this.locationFilter,
                formatter: this.locationFormatter
            },
            {
                name: t("weight"),
                key: "weight",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                filterRenderer: this.weightFilter,
                formatter: this.weightFormatter
            },
            {
                name: t("price"),
                key: "price",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}/>),
                filterRenderer: this.priceFilter
            },
            dateField,
            operatorField,
            commentField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange} formName={formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       isSubmitting={isSubmitting} formikReset={handleReset} getApi={this.getApi}/>
                    )
                }
            </Formik>
        );
    }

}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(Reclassify);
