import React, {Component} from 'react';
import Tooltip from "../../../../../components/basics/tooltip/Tooltip";
import "./transfer-stand.scss";
import {withTranslation} from "react-i18next";

class TransferStandComponent extends Component {
    render() {
        const {row: {stand, animalInStand}, t} = this.props;
        return (
            <div className="transfer-stand">
                <Tooltip tooltipContent={stand.BoxesName}>
                    <div>{stand.BoxesName}</div>
                </Tooltip>
                {
                    animalInStand &&
                    <Tooltip tooltipContent={t("standOccupied", {animalNumber: animalInStand.AnmNo1})}>
                        <i className="fas fa-exclamation-circle"/>
                    </Tooltip>
                }
            </div>
        );
    }
}

export default withTranslation()(TransferStandComponent);