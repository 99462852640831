import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
    calculateGridRowsByHeight,
    dateField,
    dateFormatter,
    dateRenderer,
    insertData,
    insertFilters,
    isEmptyRow
} from "../../../../utils/DataGridUtils";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import Select from "../../../../components/basics/select/Select";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import {Formik} from "formik";
import FormForEvents from "../../events/FormForEvents";
import {withRouter} from "react-router-dom";
import buildingsDB from "../../../../database/buildingsDB";
import {getLocationName} from "../../../../utils/BuildingUtils";
import animalsDB from "../../../../database/animalsDB";
import groupsDB from "../../../../database/groupsDB";
import {eventChooserFormNames} from "../../../../constans/formNames";
import {convertWeightToBaseUnit, getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import devicesDB from "../../../../database/devicesDB";
import {
    createInsertion,
    createInsertionNotificationFailure,
    createInsertionNotificationSuccess
} from "../../../../api/events/Insertion";
import {isEmpty, isNil} from "lodash";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        races: state.dictionary.race.WData[state.language.lang.lang],
        suppliers: state.dictionary.suppliers.WData[state.language.lang.lang]
    };
}

class PorkerAndPigletGroupGrid extends Component {

    state = {
        initialData: []
    }

    formName = eventChooserFormNames.PORKER_AND_PIGLET_GROUP_INSERTION + "_" + this.props.match.params.locationID + "_" + this.props.match.params.animalKind;

    componentDidMount() {
        const {match: {params: {locationID}}} = this.props;
        let location = buildingsDB.getLocationByID(locationID);
        let data = location.Chambers.map(chamber => {
            let animals = animalsDB.getAllAnimalsByPlcmntID(chamber.CID, {joinEvents: false});
            let group = null;
            for (let animal of animals) {
                let animalGroup = groupsDB.getGroupWithAnimal(animal.AnmID, animal.FarmID)[0];
                if (animalGroup) {
                    group = animalGroup;
                    break;
                }
            }
            return {
                chamber,
                group
            }
        });
        this.setState({
            initialData: data
        })
    }

    onFiltersChange = (filters, value, oldFilters) => {
        value = insertFilters(filters, value, oldFilters);
        if (filters.hasOwnProperty("AnmNo1")) {
            const {AnmNo1} = filters;
            value = insertData(AnmNo1, "AnmNo1", value, oldFilters.AnmNo1);
        }
        if (filters.hasOwnProperty("birthDate")) {
            const {birthDate} = filters;
            value = insertData(birthDate, "birthDate", value, oldFilters.birthDate);
        }
        if (filters.hasOwnProperty("amount")) {
            const {amount} = filters;
            value = insertData(amount, "amount", value, oldFilters.amount);
        }
        if (filters.hasOwnProperty("group")) {
            const {group} = filters;
            value = insertData(group, "group", value, oldFilters.group, this.isGroupEditable);
        }
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight);
        }
        if (filters.hasOwnProperty("provider")) {
            const {provider} = filters;
            value = insertData(provider, "provider", value, oldFilters.provider);
        }
        if (filters.hasOwnProperty("price")) {
            const {price} = filters;
            value = insertData(price, "price", value, oldFilters.price);
        }
        if (filters.hasOwnProperty("race")) {
            const {race} = filters;
            value = insertData(race, "race", value, oldFilters.race);
        }
        return value;
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t, farm} = this.props;

        let hasAnyErrors = false;
        if (data) {

            errors.data = data.map((row, index) => {
                const rowErrors = {};
                if (!isEmptyRow(row, ["chamber"])) {
                    if (!row.AnmNo1) {
                        rowErrors.AnmNo1 = t("required");
                    } else if (animalsDB.checkIfAnimalExistOnFarm(row.AnmNo1, farm)) {
                        rowErrors.AnmNo1 = t("errors.exist");
                    } else if (data.find((d, idx) => d.AnmNo1 === row.AnmNo1 && index !== idx)) {
                        rowErrors.AnmNo1 = t("errors.duplicate");
                    }
                    if (!row.group) {
                        rowErrors.group = t("required");
                    }
                    if (isNil(row.amount)) {
                        rowErrors.amount = t("required");
                    }
                    if (!row.date) {
                        rowErrors.date = t("required");
                    }
                }

                if (!isEmpty(rowErrors)) {
                    hasAnyErrors = true;
                }
                return rowErrors;
            })
        }
        if (!hasAnyErrors) delete errors.data;

        return errors;
    }

    anmNo1Filter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Nr zwierzęcia"}/>
    }

    amountFilter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={"Ilość"} allowFloat={false}/>
    }

    groupFilter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Numer grupy"}/>
    }

    weightFilter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Śr. waga szt."}/>
    }

    priceFilter = ({value, onChange}) => {
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={"Cena"}/>
    }

    supplierFilter = ({value, onChange}) => {
        const {suppliers} = this.props;
        return <Select value={value}
                       options={suppliers.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={"Dostawca"}/>
    }

    raceFilter = ({value, onChange}) => {
        const {races} = this.props;
        return <Select value={value}
                       options={races.map(reason => ({name: reason.Value, value: reason.ID}))}
                       onChange={onChange} placeholder={"Rasa"}/>
    }

    supplierFormatter = ({column: {key}, row}) => {
        const {suppliers} = this.props;
        let id = row[key];
        let supplier = suppliers.find(item => item.ID === id);
        if (supplier) return supplier.Value;
        return null;
    }

    raceFormatter = ({column: {key}, row}) => {
        const {races} = this.props;
        let id = row[key];
        let race = races.find(item => item.ID === id);
        if (race) return race.Value;
        return null;
    }

    groupFormatter = ({column: {key}, row}) => {
        let value = row[key];
        if (value) {
            if (typeof value === "object") {
                return value.GrNo1;
            }
            return value;
        }
        return null;
    }

    locationFormatter = ({column: {key}, row}) => {
        if (row[key]) return getLocationName(row[key]);
        return null;
    }

    isGroupEditable = ({group}) => {
        return !(group && typeof group === "object");
    }

    submit = (values, {resetForm}) => {
        const data = [];
        const {match: {params: {animalKind}}, farm} = this.props;
        for (let row of values.data) {
            const {chamber, AnmNo1, amount, weight, provider, price, race, birthDate, group} = row;
            if (AnmNo1) {
                let devices = devicesDB.getDevicesInPlcmntID(chamber.CID, {showDevicesInChildren: false}).map(device => ({
                    DevID: device.DevID,
                    GwID: device.GatewayID
                }));
                let additionalData = {
                    Weight: weight ? convertWeightToBaseUnit(+weight, {fromUnit: UnitTypes.MEDIUM}) : undefined,
                    Provider: provider,
                    Price: price ? +price : undefined,
                    Race: race,
                    DtaBrthTime: birthDate ? +birthDate : undefined
                }
                let d = {
                    AnmNo1,
                    AnmCnt: +amount,
                    PlcmntID: chamber.CID,
                    AnimalKind: +animalKind,
                    additionalData,
                    devices: devices,
                }
                if (typeof group === "object") {
                    d.addToGroup = group.AnmGrp;
                } else {
                    d.newGroupNumber = group;
                }
                data.push(d)
            }
        }
        return createInsertion(data).then(res => {
            createInsertionNotificationSuccess(res);
            this.props.history.push(`/${farm}/eventChooser/animalKind/insertion`);
        }).catch(e => {
            createInsertionNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            initialData: new Array(calculateGridRowsByHeight()).fill({})
        })
    }

    getApi = api => {
        this.api = api;
    }

    weightFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    render() {
        const {initialData} = this.state;
        const {suppliers, races, t} = this.props;
        const columns = [
            {
                name: t("pigPen"),
                key: "chamber",
                formatter: this.locationFormatter,
                editable: false
            },
            {
                name: t("groupNumber"),
                key: "group",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"text"}/>),
                formatter: this.groupFormatter,
                groupFilter: this.groupFilter,
                editable: this.isGroupEditable
            },
            {
                name: t("animalNumberShort"),
                key: "AnmNo1",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"text"}/>),
                filterRenderer: this.anmNo1Filter
            },
            {
                name: t("amount"),
                key: "amount",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}
                                                                      allowFloat={false}/>),
                filterRenderer: this.amountFilter
            },
            {
                name: t("birthDate"),
                key: "birthDate",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer
            },
            {
                name: t("averageWeightShort"),
                key: "weight",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                filterRenderer: this.weightFilter,
                formatter: this.weightFormatter
            },
            {
                name: t("supplier"),
                key: "provider",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={suppliers.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.supplierFormatter,
                filterRenderer: this.supplierFilter
            },
            {
                name: t("events.insertionEvent.invoice"),
                key: "price",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"number"}/>),
                filterRenderer: this.priceFilter
            },
            {
                name: t("race"),
                key: "race",
                editor: React.forwardRef((props, ref) =>
                    <SelectEditor {...props}
                                  options={races.map(reason => ({name: reason.Value, value: reason.ID}))}
                                  ref={ref}/>),
                formatter: this.raceFormatter,
                filterRenderer: this.raceFilter
            },
            dateField
        ]
        return (
            <Formik
                onSubmit={this.submit}
                validate={this.validate}
                initialValues={{
                    data: initialData
                }}
                enableReinitialize
            >
                {
                    ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                        <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                       onFiltersChange={this.onFiltersChange} formName={this.formName} values={values}
                                       onChange={setFieldValue} errors={errors} resetForm={this.resetForm}
                                       formikReset={handleReset} getApi={this.getApi}
                                       disableAddingNewRow isSubmitting={isSubmitting}/>
                    )
                }
            </Formik>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter
)(PorkerAndPigletGroupGrid);
