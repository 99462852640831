import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {
    animalFormatter,
    commentFilterRenderer,
    dateFormatter,
    dateRenderer,
    getOperators,
    insertComment,
    insertData,
    insertOperator,
    operatorFilterRenderer,
    operatorFormatter
} from "../../../../utils/DataGridUtils";
import {Formik} from "formik";
import FormForEvents from "../FormForEvents";
import SeparationToPorkhouseStepper from "./SeparationToPorkhouseStepper";
import buildingsDB from "../../../../database/buildingsDB";
import animalsDB from "../../../../database/animalsDB";
import InputEditor from "../../../../components/basics/table-input/editors/InputEditor";
import InputFilter from "../../../../components/basics/table-input/filters/InputFilter";
import eventsDB from "../../../../database/eventsDB";
import {AnimalTypes} from "../../../../constans/animalTypes";
import SelectEditor from "../../../../components/basics/table-input/editors/SelectEditor";
import moment from "moment";
import LocationEditor from "../../../../components/basics/table-input/editors/LocationEditor";
import {getLocationID, getLocationName} from "../../../../utils/BuildingUtils";
import groupsDB from "../../../../database/groupsDB";
import {isEmpty, isNil, values} from "lodash";
import TreeSelect from "../../../../components/basics/tree-select/TreeSelect";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {convertWeightToBaseUnit, getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {
    createSeparationByAnimal,
    createSeparationNotificationFailure,
    createSeparationNotificationSuccess
} from "../../../../api/events/Separation";
import {eventChooserFormNames} from "../../../../constans/formNames";
import formDataDB from "../../../../database/formDataDB";
import TooltipCell from "../../../../components/basics/table-input/cell/TooltipCell";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    }
}

export class SeparationToPiglethouse extends React.Component {

    state = {
        initialData: [],
        hasBoxes: false,
        buildings: buildingsDB.getObjectForTreeSelect(this.props.farm)
    }

    formName = eventChooserFormNames.SEPARATION_TO_PORKHOUSE + "_" + this.props.match.params.locationID;

    static getGroupByLocation(PlcmntID) {
        let animals = animalsDB.getAllAnimalsByPlcmntID(PlcmntID, {joinEvents: false});
        for (let animal of animals) {
            let group = groupsDB.getGroupWithAnimal(animal.AnmID, animal.FarmID)[0];
            if (group) return group;
        }
        return null;
    }

    componentDidMount() {
        this.setState({
            ...this.getInitialData()
        })
    }

    getInitialData() {
        const {match: {params: {locationID}}} = this.props;
        let savedData = formDataDB.getSavedData(this.formName);
        let location = buildingsDB.getLocationByID(locationID);
        if (location.Boxes) {
            let initialData = location.Boxes.map(box => {
                let animal = animalsDB.getAllAnimalsByPlcmntID(box.BID, {joinEvents: false})[0];
                if (animal) {
                    let balance = eventsDB.getPigBalanceForASow(animal, new Date().getTime());
                    let savedRow = savedData ? savedData.data.data.find(item => item.animal && item.animal.AnmID === animal.AnmID) : {};
                    return {
                        box,
                        animal,
                        piglets: balance,
                        weight: 8,
                        date: moment(),
                        ...(savedRow || {})
                    }
                }
                return {
                    box
                }
            });
            this.setState({
                initialData,
                hasBoxes: true
            })
        } else {
            let animals = animalsDB.getAllAnimalsByPlcmntID(locationID, {joinEvents: false}).filter(item => item.AnimalKind === AnimalTypes.SOW);
            let initialData = animals.map(animal => {
                let savedRow = savedData ? savedData.data.data.find(item => item.animal && item.animal.AnmID === animal.AnmID) : {};
                let balance = eventsDB.getPigBalanceForASow(animal, new Date().getTime());
                return {
                    animal,
                    piglets: balance,
                    date: moment(),
                    weight: 8,
                    ...(savedRow || {})
                }
            });
            this.setState({
                initialData,
                hasBoxes: false
            })
        }
    }

    insertGroup(filters, value) {
        if (filters.hasOwnProperty("group")) {
            const {group} = filters;
            if (group) {
                value = value.map(row => {
                    if (!values(row).every(isEmpty)) {
                        let gr = row.location ? SeparationToPiglethouse.getGroupByLocation(getLocationID(row.location.object)) || group : group;
                        return {
                            ...row,
                            group: gr
                        }
                    }
                    return {};
                })
            } else {
                value = value.map(row => {
                    return {
                        ...row,
                        group: this.isGroupEditable(row) ? null : row.group
                    };
                })
            }
        }
        return value;
    }

    insertLocation(filters, value) {
        if (filters.hasOwnProperty("location")) {
            const {location} = filters;
            if (location) {
                value = value.map(row => {
                    if (!values(row).every(isEmpty)) {
                        let group = SeparationToPiglethouse.getGroupByLocation(getLocationID(location.object));
                        let canInsert = this.blockInputWhenNoAnimal(row);
                        let animal = this.getAnimalByLocation(getLocationID(location.object));
                        return {
                            ...row,
                            location: canInsert ? location : null,
                            group: canInsert ? group : row.group && typeof row.group === "string" ? row.group : null,
                            AnmNo1: canInsert ? animal : null
                        }
                    }
                    return {};
                })
            } else {
                value = value.map(row => {
                    return {
                        ...row,
                        location: null,
                        group: null
                    };
                })
            }
        }
        return value;
    }

    onFiltersChange = (filters, value, oldFilters) => {
        if (filters.hasOwnProperty("date")) {
            const {date} = filters;
            value = insertData(date, "date", value, oldFilters.date, this.blockInputWhenNoAnimal);
        }
        value = insertOperator(filters, value, oldFilters.operator, this.blockInputWhenNoAnimal);
        value = insertComment(filters, value, oldFilters.comment, this.blockInputWhenNoAnimal);
        if (filters.hasOwnProperty("piglets")) {
            const {piglets} = filters;
            value = insertData(piglets, "piglets", value, oldFilters.piglets, this.blockInputWhenNoAnimal);
        }
        if (filters.hasOwnProperty("weight")) {
            const {weight} = filters;
            value = insertData(weight, "weight", value, oldFilters.weight, this.blockInputWhenNoAnimal);
        }
        if (filters.hasOwnProperty("AnmNo1")) {
            const {AnmNo1} = filters;
            value = insertData(AnmNo1, "AnmNo1", value, oldFilters.AnmNo1, this.blockInputWhenNoAnimal);
        }
        value = this.insertLocation(filters, value);
        value = this.insertGroup(filters, value);
        return value;
    }

    pigletsFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"} placeholder={t("pigletsAmount")}
                            allowFloat={false}/>
    }

    weightFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"number"}
                            placeholder={t("averagePigletWeightShort")}/>
    }

    groupFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={t("groupNumber")}/>
    }

    anmNo1Filter = ({value, onChange}) => {
        const {t} = this.props;
        return <InputFilter value={value} onChange={onChange} type={"text"} placeholder={t("animalNumberShort")}/>
    }

    locationFilter = ({value, onChange}) => {
        const {t} = this.props;
        return <TreeSelect value={value} onChange={onChange} options={this.state.buildings}
                           placeholder={t("location")}/>
    }

    locationFormatter = ({column: {key}, row}) => {
        if (row[key]) return <TooltipCell value={row[key].BoxesName}/>;
        return null;
    }

    allLocationFormatter = ({column: {key}, row}) => {
        if (row[key]) return getLocationName(row[key].object);
        return null;
    }

    groupFormatter = ({column: {key}, row}) => {
        let value = row[key];
        if (value) {
            if (typeof value === "object") {
                return value.GrNo1;
            }
            return value;
        }
        return null;
    }

    anmNo1Formatter = ({column: {key}, row}) => {
        let value = row[key];
        if (value) {
            if (typeof value === "object") {
                return value.AnmNo1;
            }
            return value;
        }
        return null;
    }

    validate = (values) => {
        const errors = {};
        const {data} = values;
        const {t} = this.props;
        let hasAnyErrors = false;

        if (data) {
            errors.data = data.map(row => {
                const rowErrors = {};
                if (row.animal) {
                    if (isNil(row.piglets)) {
                        rowErrors.piglets = t("required");
                    } else if (row.piglets < 1) {
                        rowErrors.piglets = t("errors.noLess", {number: 1});
                    } else {
                        if (row.date) {
                            let balance = eventsDB.getPigBalanceForASow(row.animal, row.date);
                            if (balance < row.piglets) {
                                rowErrors.piglets = t("errors.maxAnimalsValue", {amount: balance});
                            }
                        }
                    }
                    if (isNil(row.weight)) {
                        rowErrors.weight = t("required");
                    }
                    if (!row.location) {
                        rowErrors.location = t("required");
                    }
                    if (!row.group) {
                        rowErrors.group = t("required");
                    }
                    if (!row.date) {
                        rowErrors.date = t("required");
                    }
                    if (!row.date) {
                        rowErrors.date = t("required");
                    }
                    if (!row.date) {
                        rowErrors.date = t("required");
                    }
                    if (!row.operator) {
                        rowErrors.operator = t("required");
                    }
                    if (!row.AnmNo1) {
                        rowErrors.AnmNo1 = t("required");
                    }
                }
                if (!isEmpty(rowErrors)) {
                    hasAnyErrors = true;
                }
                return rowErrors;
            })
        }
        if (!hasAnyErrors) delete errors.data;

        return errors;
    }

    isGroupEditable = (data) => {
        const {group} = data;
        return this.blockInputWhenNoAnimal(data) && !(group && typeof group === "object");
    }

    blockInputWhenNoAnimal = ({animal}) => !!animal;

    getAnimalByLocation(PlcmntID) {
        return animalsDB.getAllAnimalsByPlcmntID(PlcmntID, {joinEvents: false}).filter(item => item.AnimalKind === AnimalTypes.PIGLET)[0];
    }

    onLocationChange = (data, current, filters) => {
        if (data.location) {
            data.group = SeparationToPiglethouse.getGroupByLocation(getLocationID(data.location.object)) || filters.group || null;
            data.AnmNo1 = this.getAnimalByLocation(getLocationID(data.location.object));
        } else {
            data.group = filters.group || null;
            data.AnmNo1 = null;
        }
        return data;
    }

    submit = (values) => {
        let data = [];
        for (let row of values.data) {
            const {animal, date, comment, piglets, weight, location, AnmNo1, group} = row;
            if (animal) {
                data.push({
                    AnmID: animal.AnmID,
                    piglets: +piglets,
                    weight: convertWeightToBaseUnit(weight, {fromUnit: UnitTypes.MEDIUM}),
                    separateTo: getLocationID(location.object),
                    addToAnimal: typeof AnmNo1 === "object" ? AnmNo1.AnmID : undefined,
                    newAnimalNumber: typeof AnmNo1 === "string" ? AnmNo1 : undefined,
                    addToGroup: typeof group === "object" ? group.AnmGrp : undefined,
                    newGroupNumber: typeof group === "string" ? group : undefined,
                    GrID: getGrIDByAnimal(animal, +date, false),
                    EvTime: +date,
                    Comment: comment
                })
            }
        }
        return createSeparationByAnimal(data).then(res => {
            createSeparationNotificationSuccess(res);
            this.api.resetForm();
        }).catch(e => {
            createSeparationNotificationFailure(e);
        })
    }

    resetForm = (resetForm) => {
        resetForm();
        this.setState({
            ...this.getInitialData()
        })
    };

    getApi = api => {
        this.api = api;
    }

    getDisabledRows = (values, start, stop) => {
        let disabledRows = [];
        for (let i = start; i <= stop; i++) {
            if (!this.blockInputWhenNoAnimal(values[i])) {
                disabledRows.push(i);
            }
        }
        return disabledRows;
    }

    weightFormatter = ({column: {key}, row}) => {
        if (row[key]) return row[key] + getUnit("weight", UnitTypes.MEDIUM);
        return null;
    }

    render() {
        const {initialData, hasBoxes} = this.state;
        const {farm, t} = this.props;
        let columns = [];
        if (hasBoxes) {
            columns.push(
                {
                    name: t("standing"),
                    key: "box",
                    formatter: this.locationFormatter,
                    editable: false
                }
            );
        }
        columns.push(...[
            {
                name: t("animalNumber"),
                key: "animal",
                formatter: animalFormatter,
                editable: false
            },
            {
                name: t("pigletsAmount"),
                key: "piglets",
                filterRenderer: this.pigletsFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      allowFloat={false}/>),
                editable: this.blockInputWhenNoAnimal
            },
            {
                name: t("averagePigletWeightShort"),
                key: "weight",
                filterRenderer: this.weightFilter,
                editor: React.forwardRef((props, ref) => <InputEditor type={"number"} ref={ref} {...props}
                                                                      unit={getUnit("weight", UnitTypes.MEDIUM)}/>),
                editable: this.blockInputWhenNoAnimal,
                formatter: this.weightFormatter
            },
            {
                name: t("location"),
                key: "location",
                filterRenderer: this.locationFilter,
                editor: React.forwardRef((props, ref) => <LocationEditor type={"number"} ref={ref} {...props}
                                                                         showStands={false} farm={farm}/>),
                formatter: this.allLocationFormatter,
                editable: this.blockInputWhenNoAnimal,
                onChange: this.onLocationChange
            },
            {
                name: t("groupNumber"),
                key: "group",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"object"}/>),
                formatter: this.groupFormatter,
                filterRenderer: this.groupFilter,
                editable: this.blockInputWhenNoAnimal
            },
            {
                name: t("animalNumberShort"),
                key: "AnmNo1",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"text"}/>),
                formatter: this.anmNo1Formatter,
                filterRenderer: this.anmNo1Filter,
                editable: this.isGroupEditable
            },
            {
                name: t("date"),
                key: "date",
                editor: React.forwardRef((props, ref) => <InputEditor {...props} ref={ref} type={"date"}/>),
                formatter: dateFormatter,
                filterRenderer: dateRenderer,
                editable: this.blockInputWhenNoAnimal
            },
            {
                name: t("operator"),
                key: "operator",
                editor: React.forwardRef((props, ref) => <SelectEditor {...props} options={getOperators()} ref={ref}/>),
                formatter: operatorFormatter,
                filterRenderer: operatorFilterRenderer,
                editable: this.blockInputWhenNoAnimal
            },
            {
                name: t("comment"),
                key: "comment",
                filterRenderer: commentFilterRenderer,
                editor: React.forwardRef((props, ref) => <InputEditor type={"text"} ref={ref} {...props}/>),
                editable: this.blockInputWhenNoAnimal
            }
        ])
        return (
            <div>
                <SeparationToPorkhouseStepper activeStep={3}/>
                <Formik
                    onSubmit={this.submit}
                    validate={this.validate}
                    initialValues={{
                        data: initialData
                    }}
                    enableReinitialize
                >
                    {
                        ({values, errors, setFieldValue, handleSubmit, handleReset, isSubmitting}) => (
                            <FormForEvents handleSubmit={handleSubmit} columns={columns}
                                           onFiltersChange={this.onFiltersChange} formName={this.formName}
                                           values={values}
                                           onChange={setFieldValue} errors={errors}
                                           ignoreFields={hasBoxes ? ["box"] : []} resetForm={this.resetForm}
                                           initialFilters={{date: moment(), weight: 8}} disableAddingNewRow
                                           isSubmitting={isSubmitting} formikReset={handleReset} getApi={this.getApi}
                                           getDisabledRows={this.getDisabledRows}/>
                        )
                    }
                </Formik>
            </div>
        );
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(SeparationToPiglethouse);
