import {prepareFeeding} from "../../../../utils/FeedingUtils";
import {DevType} from "../../../../constans/devices";
import {getGrIDByAnimal} from "../../../../utils/EventUtils";
import {
    createTransfer,
    createTransferNotificationFailure,
    createTransferNotificationSuccess
} from "../../../../api/events/Transfer";
import animalsDB from "../../../../database/animalsDB";
import buildingsDB from "../../../../database/buildingsDB";
import devicesDB from "../../../../database/devicesDB";

function getAnimalInStand(boxID, date) {
    let animalInStand = animalsDB.getAllAnimalsByPlcmntID(boxID, {joinEvents: false})[0];
    let tree = buildingsDB.getTreeByLocationID(boxID);
    if (animalInStand) {
        return {
            AnmID: animalInStand.AnmID,
            transferTo: tree.chamber.CID,
            DevIDsNewPlcmnt: getDevicesInPlcmnt(tree.chamber.CID),
            DevIDsOldPlcmnt: getDevicesInPlcmnt(boxID),
            GrID: getGrIDByAnimal(animalInStand, date, false)
        }
    }
    return null;
}

function getDevicesInPlcmnt(plcmntID) {
    let devices = devicesDB.getDevicesInPlcmntID(plcmntID, {showDevicesInChildren: false});
    return devices.map(device => ({
        DevID: device.DevID,
        GwID: device.GatewayID
    }))
}

export function transferSubmit(values, props, api) {
    const {showBoxes, match: {params: {locationID}}} = props;
    let data = [];
    for (let row of values.data) {
        const {animal, date, comment, stand, amount, newGrpNumber, curve, day, doseCorrection, plannedBirth, inseminationDay} = row;
        if (animal) {
            let d = {
                AnmID: animal.AnmID,
                EvTime: +date,
                GrID: getGrIDByAnimal(animal, +date, false),
                Comment: comment,
                transferTo: showBoxes ? stand.BID : locationID,
                amount: amount || 1,
                stand: showBoxes,
                animalInStand: showBoxes ? getAnimalInStand(stand.BID, +date) : null,
                DevIDsNewPlcmnt: getDevicesInPlcmnt(showBoxes ? stand.BID : locationID),
                DevIDsOldPlcmnt: getDevicesInPlcmnt(Array.isArray(animal.PlcmntID) ? animal.PlcmntID[0].PlcmntID : animal.PlcmntID),
                newAnmNo1: newGrpNumber,
                clearFeeding: true,
            }
            if (curve) {
                let feeding = prepareFeeding({
                    curve: curve,
                    curveDay: day,
                    repetitionDate: +plannedBirth || +inseminationDay,
                    doseCorrection
                }, DevType.DISPENSER_NRF);
                d.feeding = {
                    curveNumber: feeding.curveNr,
                    curveStart: feeding.startTime,
                    doseCorrection: feeding.percentCorrection
                };
            }
            data.push(d);
        }
    }
    return createTransfer(data).then(res => {
        createTransferNotificationSuccess(res);
        api.resetForm();
        props.onSubmit();
    }).catch(e => {
        createTransferNotificationFailure(e);
    })
}