import React from "react";
import PropTypes from "prop-types"
import Button from "../../../components/basics/button/Button";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import {withTranslation} from "react-i18next";
import TableInput from "../../../components/basics/table-input/TableInput";
import moment from "moment";
import "./_form-for-events.scss";
import {bindActionCreators, compose} from "redux";
import {Prompt, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {hide, show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../components/modals-new/confirm-modal/ConfirmModal";
import formDataDB from "../../../database/formDataDB";
import {isEmptyRow} from "../../../utils/DataGridUtils";

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show, hide}, dispatch)
    }
}

export class FormForEvents extends React.Component {

    state = {
        nextLocation: null
    }

    tableInput = React.createRef();

    componentDidMount() {
        if (this.props.getApi) {
            this.props.getApi({
                resetForm: this.resetForm,
                tableInput: this.tableInput.current
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.nextLocation && this.props.values.data.length === 0) {
            this.setState({
                nextLocation: null
            });
            this.props.history.push(this.state.nextLocation);
        }
    }

    leaveComponentAfterPrompt(nextLocation) {
        this.setState({
            nextLocation
        }, () => {
            this.props.onChange("data", []);
            this.props.hide(ConfirmModalName);
        })
    }

    onNotSaveClick(nextLocation) {
        this.leaveComponentAfterPrompt(nextLocation);
    }

    onSaveClick(nextLocation) {
        formDataDB.insertFormData(this.props.formName, this.props.values);
        this.leaveComponentAfterPrompt(nextLocation);
    }

    showPromptModal = nextLocation => {
        if (nextLocation.state && nextLocation.state.ignoreModal) return true; // jezeli nie da sie wyczyscic forma uzyj tego
        const {t} = this.props;
        this.props.show(ConfirmModalName, {
            title: t("newSettings.translate.edit.notSaved"),
            text: t("eventChooser.notSavedText"),
            confirmText: t("save"),
            closeText: t("cancel"),
            optionalButtons:
                <Button buttonColor={"error"}
                        onClick={() => this.onNotSaveClick(nextLocation)}>{t("doNotSave")}</Button>,
            onConfirmed: () => this.onSaveClick(nextLocation)
        });
        return false;
    }

    resetForm = () => {
        this.props.resetForm(this.props.formikReset);
        this.tableInput.current.setFilters(this.props.initialFilters || {date: moment()});
    }

    hasAnyValues() {
        const {values: {data}, ignoreFields, customIsEmpty} = this.props;
        if (data) {
            for (let row of data) {
                if (customIsEmpty) {
                    if (!customIsEmpty(row)) return true;
                } else {
                    if (!isEmptyRow(row, ignoreFields)) return true;
                }
            }
        }
        return false;
    }

    onSubmitClick = () => {
        const {handleSubmit, errors: {data}, t} = this.props;
        let values = this.props.values.data.slice(0);
        if (data) {
            let errors = data.reduce((a, b, index) => {
                if (Object.keys(b).length > 0) {
                    a.push(index);
                }
                return a;
            }, []);
            this.props.show(ConfirmModalName, {
                text: t("eventChooser.validationErrorsText", {amount: errors.length}),
                title: t("eventChooser.validationErrors"),
                confirmText: t("yes"),
                closeText: t("cancel"),
                onConfirmed: props => {
                    const {current: {props: {columns}}} = this.tableInput;
                    for (let index of errors) {
                        let nonEditableColumns = columns.filter((col) => values[index].hasOwnProperty(col.key) && (col.editable || !col.hasOwnProperty("editable")));
                        nonEditableColumns.forEach((col) => {
                            delete values[index][col.key];
                        });
                    }
                    this.props.onChange("data", values);
                    setTimeout(() => {
                        if (this.hasAnyValues()) {
                            handleSubmit();
                        } else {
                            this.tableInput.current.setFilters(this.props.initialFilters || {date: moment()});
                        }
                    }, 100);
                    props.handleHide();
                }
            })
        } else {
            handleSubmit();
        }
    };

    render() {
        const {
            columns, onFiltersChange, initialFilters, values, onChange, errors, hasNext, disableAddingNewRow,
            isSubmitting, onDataChange, getDisabledRows
        } = this.props;
        let hasAnyValues = this.hasAnyValues();
        return (
            <div className="form-for-events">
                <TableInput columns={columns} shouldIndex onFiltersChange={onFiltersChange}
                            initialFilters={initialFilters || {date: moment()}} onChange={onChange}
                            ref={this.tableInput}
                            value={values.data} errors={errors.data} disableAddingNewRow={disableAddingNewRow}
                            onDataChange={onDataChange} getDisabledRows={getDisabledRows}/>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"error"} buttonStyle={"round"} icon={<i className="fas fa-trash"/>}
                            type="button" onClick={this.resetForm}/>
                    <Button buttonColor={"success"} buttonStyle={"round"} onClick={this.onSubmitClick}
                            icon={hasNext ? <i className="fas fa-arrow-right"/> : isSubmitting ?
                                <i className="fas fa-circle-notch fa-spin"/> : <i className="fas fa-check"/>}
                            disabled={isSubmitting || !hasAnyValues}/>
                </ButtonGroup>
                <Prompt when={hasAnyValues} message={this.showPromptModal}/>
            </div>
        );
    }
}

FormForEvents.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    formName: PropTypes.string.isRequired,
    onFiltersChange: PropTypes.func,
    onDataChange: PropTypes.func,
    initialFilters: PropTypes.object,
    hasNext: PropTypes.bool,
    disableAddingNewRow: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    ignoreFields: PropTypes.arrayOf(PropTypes.string),
    getApi: PropTypes.func,
    customIsEmpty: PropTypes.func,
    getDisabledRows: PropTypes.func,
};

FormForEvents.defaultProps = {
    ignoreFields: []
}

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation(),
    withRouter
)(FormForEvents);
