import {
    editSubscription,
    editSubscriptionNotificationFailure,
    editSubscriptionNotificationSuccess
} from "../../../api/subscriptions/EditSubscription";
import {get} from "lodash";

export function submit(values, dispatch, props) {
    const {
        AdministrationModule, BreedingModule: {sows, porkers}, DevicesModule: {
            siloses, climates, cages, dispensers, dispensers5G,
            meters, smallCages, alarms, chains
        }
    } = values;
    const {user: {ClientID}, match: {params: {FarmID}}, subscriptionData: {subscription}, history} = props;
    const addOns = {
        BreedingModule: {
            sows,
            porkers
        },
        DevicesModule: {
            siloses,
            climates,
            cages,
            dispensers,
            dispensers5G,
            meters,
            smallCages,
            alarms,
            chains,
        },
        AdministrationModule
    };
    return editSubscription(addOns, FarmID, ClientID, subscription.id)
        .then(res => {
            editSubscriptionNotificationSuccess(res);
            history.replace({
                pathname: "/farmSettings/billing",
                state: {
                    forceFetch: true
                }
            });
        }).catch(e => {
            editSubscriptionNotificationFailure(e);
        });
}

export function validate(values, props) {
    const errors = {
        AdministrationModule: {},
        DevicesModule: {
            siloses: {},
            climates: {},
            cages: {},
            dispensers: {},
            dispensers5G: {},
            meters: {},
            smallCages: {},
            chains: {},
            alarms: {}
        }
    };
    const {t, subscriptionData: {addOns}} = props;
    const {
        AdministrationModule: {managersAmount, operatorsAmount, techAdviceAmount, techSupportAmount} = {},
        DevicesModule: {siloses, climates, cages, dispensers, dispensers5G, meters, smallCages, chains, alarms} = {}
    } = values;
    if (!managersAmount) errors.AdministrationModule.managersAmount = t("required");
    const usedManagersAcc = get(addOns, "AdministrationModule.managersAmount") - get(addOns, "AdministrationModule.managersLeft");
    if (usedManagersAcc > managersAmount) {
        errors.AdministrationModule.managersAmount = t("errors.tooMuchInUse", {used: usedManagersAcc});
    }
    if (!operatorsAmount) errors.AdministrationModule.operatorsAmount = t("required");
    const usedOperatorsAcc = get(addOns, "AdministrationModule.operatorsAmount") - get(addOns, "AdministrationModule.operatorsLeft");
    if (usedOperatorsAcc > operatorsAmount) {
        errors.AdministrationModule.operatorsAmount = t("errors.tooMuchInUse", {used: usedOperatorsAcc});
    }
    if (!techAdviceAmount) errors.AdministrationModule.techAdviceAmount = t("required");
    const usedTechAdvice = get(addOns, "AdministrationModule.techAdviceAmount") - get(addOns, "AdministrationModule.techAdviceLeft");
    if (usedTechAdvice > techAdviceAmount) {
        errors.AdministrationModule.techAdviceAmount = t("errors.tooMuchInUse", {used: usedTechAdvice});
    }
    if (!techSupportAmount) errors.AdministrationModule.techSupportAmount = t("required");
    const usedTechSupport = get(addOns, "AdministrationModule.techSupportAmount") - get(addOns, "AdministrationModule.techSupportLeft");
    if (usedTechSupport > techSupportAmount) {
        errors.AdministrationModule.techSupportAmount = t("errors.tooMuchInUse", {used: usedTechSupport});
    }
    if (!get(siloses, "amount")) errors.DevicesModule.siloses.amount = t("required");
    const usedSiloses = get(addOns, "DevicesModule.siloses.amount") - get(addOns, "DevicesModule.siloses.left");
    if (usedSiloses > get(siloses, "amount")) {
        errors.DevicesModule.siloses.amount = t("errors.tooMuchInUse", {used: usedSiloses});
    }
    if (!get(climates, "amount")) errors.DevicesModule.climates.amount = t("required");
    const usedClimates = get(addOns, "DevicesModule.climates.amount") - get(addOns, "DevicesModule.climates.left");
    if (usedClimates > get(climates, "amount")) {
        errors.DevicesModule.climates.amount = t("errors.tooMuchInUse", {used: usedClimates});
    }
    if (!get(cages, "amount")) errors.DevicesModule.cages.amount = t("required");
    const usedCages = get(addOns, "DevicesModule.cages.amount") - get(addOns, "DevicesModule.cages.left");
    if (usedCages > get(cages, "amount")) {
        errors.DevicesModule.cages.amount = t("errors.tooMuchInUse", {used: usedCages});
    }
    if (!get(dispensers, "amount")) errors.DevicesModule.dispensers.amount = t("required");
    const usedDispensers = get(addOns, "DevicesModule.dispensers.amount") - get(addOns, "DevicesModule.dispensers.left");
    if (usedDispensers > get(dispensers, "amount")) {
        errors.DevicesModule.dispensers.amount = t("errors.tooMuchInUse", {used: usedDispensers});
    }
    if (!get(dispensers5G, "amount")) errors.DevicesModule.dispensers5G.amount = t("required");
    const usedDispensers5G = get(addOns, "DevicesModule.dispensers5G.amount") - get(addOns, "DevicesModule.dispensers5G.left");
    if (usedDispensers5G > get(dispensers5G, "amount")) {
        errors.DevicesModule.dispensers5G.amount = t("errors.tooMuchInUse", {used: usedDispensers5G});
    }
    if (!get(meters, "amount")) errors.DevicesModule.meters.amount = t("required");
    const usedMeters = get(addOns, "DevicesModule.meters.amount") - get(addOns, "DevicesModule.meters.left");
    if (usedMeters > get(meters, "amount")) {
        errors.DevicesModule.meters.amount = t("errors.tooMuchInUse", {used: usedMeters});
    }
    if (!get(smallCages, "amount")) errors.DevicesModule.smallCages.amount = t("required");
    const usedSmallCages = get(addOns, "DevicesModule.smallCages.amount") - get(addOns, "DevicesModule.smallCages.left");
    if (usedSmallCages > get(smallCages, "amount")) {
        errors.DevicesModule.smallCages.amount = t("errors.tooMuchInUse", {used: usedSmallCages});
    }
    if (!get(chains, "amount")) errors.DevicesModule.chains.amount = t("required");
    const usedChains = get(addOns, "DevicesModule.chains.amount") - get(addOns, "DevicesModule.chains.left");
    if (usedChains > get(chains, "amount")) {
        errors.DevicesModule.chains.amount = t("errors.tooMuchInUse", {used: usedChains});
    }
    if (!get(alarms, "amount")) errors.DevicesModule.alarms.amount = t("required");
    const usedAlarms = get(addOns, "DevicesModule.alarms.amount") - get(addOns, "DevicesModule.alarms.left");
    if (usedAlarms > get(alarms, "amount")) {
        errors.DevicesModule.alarms.amount = t("errors.tooMuchInUse", {used: usedAlarms});
    }
    return errors;
}
