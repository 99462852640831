import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Stepper from "../../../components/basics/stepper/Stepper";
import Step from "../../../components/basics/stepper/Step";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import buildingsDB from "../../../database/buildingsDB";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class InsertionStepper extends Component {

    onClickStep = step => {
        const {farm} = this.props;
        switch (step) {
            case 1:
                this.props.history.push(`/${farm}/eventChooser`);
                break;
            case 2:
                this.props.history.push(`/${farm}/eventChooser/animalKind/insertion`);
                break;
            case 3:
                const {match: {params: {locationID, animalKind}}} = this.props;
                let location = buildingsDB.getLocationByID(locationID);
                this.props.history.push(`/${farm}/eventChooser/locationChooser/insertion?sectors=${!location.Boxes}&redirect=/${animalKind}`)
                break;
            default:
                return;
        }
    }

    render() {
        const {activeStep, t} = this.props;
        return (
            <Stepper activeStep={activeStep} onClickStep={this.onClickStep}>
                <Step label={t("events.stepper.selectEventType")}/>
                <Step label={t("events.stepper.selectAnimalType")}/>
                <Step label={t("events.stepper.chooseLocation")}/>
                <Step label={t("events.stepper.insertAnimalData")}/>
            </Stepper>
        );
    }
}

InsertionStepper.propTypes = {
    activeStep: PropTypes.number.isRequired
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter
)(InsertionStepper);