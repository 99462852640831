import React from "react";
import Button from "../../../components/basics/button/Button";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../components/modals-new/confirm-modal/ConfirmModal";
import {gatewayCustomerFind, gatewayUpdatePaymentMethod} from "../../../actions/braintreeActions";
import {
    deletePaymentMethod,
    deletePaymentMethodNotificationSuccess,
    deletePaymentMethodNotificationFailure
} from "../../../api/subscriptions/DeletePaymentMethod";
import PropTypes from "prop-types";
import Menu from "../../../components/basics/menu/Menu";
import MenuItem from "../../../components/basics/menu/MenuItem";
import Tooltip from "../../../components/basics/tooltip/Tooltip";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";

function mapStateToProps(state) {
    return {
        attributes: state.user.attributes,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class BillingPaymentButtons extends React.Component {

    onMakeDefaultClick = () => {
        const {value, attributes, t} = this.props;
        this.props.show(ConfirmModalName, {
            title: t("farmSettings.billingPaymentButtons.defaultTitle"),
            text: i18next.t("farmSettings.billingPaymentButtons.defaultText", {type: value.maskedNumber}),
            onConfirmed: props => {
                this.props.dispatch(gatewayUpdatePaymentMethod(value.token, {
                    options: {
                        makeDefault: true
                    }
                }, attributes.sub));
                props.handleHide();
            }
        });
    };

    onRemoveClick = () => {
        const {value, t, dispatch, attributes} = this.props;
        this.props.show(ConfirmModalName, {
            title: t("farmSettings.billingPaymentButtons.deleteTitle"),
            text: i18next.t("farmSettings.billingPaymentButtons.deleteText", {type: value.maskedNumber}),
            onConfirmed: props => {
                deletePaymentMethod(value.token).then((res) => {
                    deletePaymentMethodNotificationSuccess(res);
                    dispatch(gatewayCustomerFind("", "", "", attributes.sub, true));
                    props.handleHide();
                }).catch((err) => {
                    deletePaymentMethodNotificationFailure(err);
                });
            }
        })
    };

    getContent = () => {
        const isService = checkIfUserIsService();
        const {t} = this.props;
        if (isService) {
            return (
                <span>
                    <i className={'fas fa-ban mr-1'}/>
                    {t('noServiceAccess')}
                </span>
            )
        }
    };

    render() {
        const {showAsMenu, event, t} = this.props;
        const isService = checkIfUserIsService();
        if (showAsMenu) return (
            <Menu event={event}>
                <MenuItem icon={<i className="fas fa-pencil-alt fa-fw"/>}
                          onClick={this.onEditClick}>{t("edit")}</MenuItem>
                <MenuItem icon={<i className="fas fa-credit-card fa-fw"/>} onClick={this.onMakeDefaultClick}
                          disabled={this.props.value.data.default}>{t("farmSettings.billingPaymentButtons.makeDefault")}</MenuItem>
                <MenuItem icon={<i className="fas fa-trash fa-fw"/>} onClick={this.onRemoveClick}
                          disabled={this.props.value.data.default}>{t("delete")}</MenuItem>
            </Menu>
        );
        return (
            <div className="billing-payment-buttons">
                <Tooltip tooltipContent={this.getContent() || t("farmSettings.billingPaymentButtons.makeDefault")}>
                    <div>
                        <Button buttonStyle={"round"} icon={<i className="fas fa-credit-card"/>}
                                onClick={this.onMakeDefaultClick} disabled={this.props.value.data.default || isService}
                                buttonColor={"info"}/>
                    </div>
                </Tooltip>
                <Tooltip tooltipContent={this.getContent() || t("delete")}>
                    <div>
                        <Button buttonStyle={"round"} icon={<i className="fas fa-trash"/>} buttonColor={"error"}
                                onClick={this.onRemoveClick} disabled={this.props.value.data.default || isService}/>
                    </div>
                </Tooltip>
            </div>
        );
    }

}

BillingPaymentButtons.propTypes = {
    showAsMenu: PropTypes.bool,
    event: PropTypes.object
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(BillingPaymentButtons);
