import React from "react";

export default class BillingInvoiceComponent extends React.Component {
    // TODO - obslużyć w momencie kiedy będą generowane fakturki!!!
    render() {
        return (
            <div>
                <span><i className="fas fa-download"/> {"pobierz"}</span>
            </div>
        );
    }
}
